<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="agreementForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="item.state"
                      :items="states"
                      label="Status"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredSelectRule"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row v-if="item.state === 'ACCEPTED'">
                  <v-col cols="12">
                    <v-file-input
                      v-model="agreedFile"
                      label="Select Agreed File"
                      prepend-inner-icon="mdi-file-outline"
                      :prepend-icon="null"
                      :rules="requiredRule"
                      outlined
                      dense
                      hide-details="auto"
                    >
                      <!-- <template v-slot:selection="{ text }">
                            <v-chip color="info accent-4" dark small>
                              {{ text }}
                            </v-chip>
                          </template> -->
                    </v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AgreementSignEditDialog',
  props: {
    agreement: {
      type: Object,
      required: true,
      default: () => ({})
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    states: ['ACCEPTED', 'DECLINED'],
    agreedFile: null,
    item: null
  }),
  computed: {
    title() {
      return 'Sign Agreement'
    },

    formattedExpirationDate() {
      return this.item.expiration
        ? this.$moment(this.item.expiration).format('LL')
        : ''
    }
  },
  watch: {
    agreedFile(val) {
      if (val) this.item.agreedFile = val
    },
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.agreement)
    }
  },
  methods: {
    close() {
      this.$refs.agreementForm.reset()
      this.$emit('close-agreement-edit-dialog', false)

      // this.$nextTick(() => {
      // this.$refs.agreementForm.resetValidation()
      // })
    },
    save() {
      // var that = this
      if (this.$refs.agreementForm.validate()) {
        var data = {
          id: this.item.id,
          file: this.item.agreedFile,
          state: this.item.state
        }

        if (typeof data.file === 'string') {
          data.file = null
        }

        this.$emit('save-agreement-sign-dialog', data)
        // this.$nextTick(() => {
        //   that.$refs.agreementForm.reset()
        // })
      }
    }
  }
}
</script>

<style></style>
