var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"mb-3 b-radius",attrs:{"color":"transparent","flat":""}},[_c('customer-edit-dialog',{attrs:{"dialog":_vm.dialog,"customer":_vm.editedItem,"agents":_vm.agents,"warehouses":_vm.warehouses,"currencies":_vm.currencies,"edit-mode":_vm.editMode,"subitem":_vm.subitem,"processing":_vm.processing,"error-msg":_vm.errorMsg},on:{"close-customer-edit-dialog":_vm.close,"save-customer-edit-dialog":_vm.save,"agent-search":_vm.agentSearch}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.addCustomer}},[_vm._v(" add customer ")])],1)],1)],1)],1),_c('v-card',{staticClass:"mb-3",attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":"","clearable":"","autocomplete":"off","disabled":!_vm.$apollo.loading &&
            (!_vm.agentCustomers || _vm.agentCustomers.ItemCount === 0)},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"plain-table",attrs:{"headers":_vm.headers,"loading":_vm.$apollo.loading,"items":_vm.agentCustomers.items,"options":_vm.options,"footer-props":{'items-per-page-options':[15, 30, 50, 100]},"server-items-length":_vm.itemCount,"mobile-breakpoint":null},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,'from'))+" ")]}},{key:"item.state",fn:function(ref){
            var item = ref.item;
return [_c('agent-state-label',{attrs:{"state":item.state}})]}},{key:"item.storeName",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"text-truncate text-subtitle-1 font-weight-regular primary--text"},[_vm._v(" "+_vm._s(item.storeName)+" ")]),(
              item &&
                item.companyName &&
                item.storeName &&
                item.storeName != item.companyName
            )?_c('div',{staticClass:"text-truncate text-caption font-weight-light"},[_vm._v(" "+_vm._s(item.companyName)+" ")]):_vm._e()])]}},{key:"item.agent",fn:function(ref){
            var item = ref.item;
return [(item.agent)?_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.agent.name)+" ")]):_vm._e()]}},{key:"item.visitingAddress",fn:function(ref){
            var item = ref.item;
return [(item.visitingAddress && item.visitingAddress.id != '')?_c('div',{staticClass:"text-truncate"},[(item.visitingAddress.line1)?_c('span',[_vm._v(_vm._s(item.visitingAddress.line1 + ', '))]):_vm._e(),(item.visitingAddress.city)?_c('span',[_vm._v(_vm._s(item.visitingAddress.city + ', '))]):_vm._e(),(item.visitingAddress.country)?_c('span',[_vm._v(_vm._s(_vm.getFullCountryName(item.visitingAddress.country)))]):_vm._e()]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v("Nothing here yet...")]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }