var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"plain-table",attrs:{"loading":_vm.$apollo.loading,"headers":_vm.headers,"items":_vm.deliveryNotesBySalesOrder,"mobile-breakpoint":null,"items-per-page":-1,"fixed-header":"","hide-default-header":!_vm.$apollo.loading &&
        !_vm.search &&
        (!_vm.deliveryNotesBySalesOrder ||
          !_vm.deliveryNotesBySalesOrder ||
          _vm.deliveryNotesBySalesOrder.length === 0),"hide-default-footer":!_vm.deliveryNotesBySalesOrder || _vm.itemCount < 16},on:{"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [(item.createdAt)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,'LLL')))]):_vm._e()]}},{key:"item.warehouse",fn:function(ref){
          var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.warehouse.name)+" ("+_vm._s(item.warehouse.provider.name)+") ")])]}},{key:"item.trackAndTraceValue",fn:function(ref){
          var item = ref.item;
return [_c('div',[_c('a',{attrs:{"target":"_blank","href":item.trackAndTraceValue}},[_vm._v(_vm._s(item.trackAndTraceValue))])])]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Delivery Notes","caption":'Try adjusting your search to get some results'}})]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }