<template>
  <v-chip
    pill
    :x-small="xSmall"
    :small="small"
    :large="large"
    :x-large="xLarge"
    :color="orderState(state)"
    :text-color="orderText(state)"
    class="upperCaseSpacing"
  >
    {{ state }}
  </v-chip>
</template>

<script>
export default {
  name: 'SalesOrderStateLabel',
  props: {
    state: {
      type: String,
      required: true,
      default: ''
    },
    xSmall: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    },
    xLarge: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    orderState: state => {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'error lighten-3 font-weight-medium'
          break
        case 'REVIEW':
          color = 'warning lighten-1 font-weight-medium'
          break
          case 'SUBMITTED':
          color = 'warning lighten-1 font-weight-medium'
          break
        case 'APPROVED':
          color = 'success lighten-3 font-weight-medium'
          break
        case 'PROCESSED':
          color = 'cyan lighten-3 font-weight-medium'
          break
          case 'PROCESSEDPARTIAL':
          color = 'cyan lighten-3 font-weight-medium'
          break
        case 'SHIPPED':
          color = 'info lighten-2 font-weight-medium'
          break
        case 'SHIPPEDPARTIAL':
          color = 'info lighten-2 font-weight-medium'
          break
        default:
          color = 'grey lighten-2 font-weight-medium'
          break
      }
      return color
    },
    orderText(state) {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'error darken-3'
          break
        case 'REVIEW':
          color = 'warning darken-3'
          break
        case 'SUBMITTED':
          color = 'warning darken-3'
          break
        case 'APPROVED':
          color = 'success'
          break
        case 'PROCESSED':
          color = 'cyan darken-2'
          break
          case 'PROCESSEDPARTIAL':
          color = 'cyan darken-2'
          break
        case 'SHIPPED':
          color = 'info darken-1'
          break
          case 'SHIPPEDPARTIAL':
          color = 'info darken-1'
          break
        default:
          color = 'grey darken-1'
          break
      }
      return color
    }
  }
}
</script>

<style></style>
