<template>
  <v-card flat>
    <v-data-table
      v-model="selected"
      :loading="loading"
      :headers="headers"
      :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
      :items="pickTickets.items"
      :options.sync="tableOptions"
      :mobile-breakpoint="null"
      fixed-header
      class="plain-table"
      :hide-default-header="
        !loading &&
          !search &&
          (!pickTickets || !pickTickets.items || pickTickets.items.length === 0)
      "
      :hide-default-footer="!pickTickets || itemCount < 16"
      @click:row="navToDetail"
    >
      <template v-slot:item.createdAt="{ item }">
        <span v-if="item.createdAt" class="text-no-wrap">{{
          item.createdAt | moment('LLL')
        }}</span>
      </template>

      <template v-slot:item.state="{ item }">
        <div>
          <v-chip
            v-if="item.state === 'SCHEDULED'"
            x-small
            color="success lighten-3 font-weight-medium"
            text-color="success"
            >{{ item.state }}</v-chip
          >
          <v-chip
            v-if="item.state === 'SENT'"
            x-small
            color="info lighten-2 font-weight-medium"
            text-color="info darken-1"
            >{{ item.state }}</v-chip
          >
          <v-chip
            v-if="item.state === 'CLOSED'"
            x-small
            color="warning lighten-1 font-weight-medium"
            text-color="warning darken-3"
            >{{ item.state }}</v-chip
          >
        </div>
      </template>
      <template v-slot:item.deliveryStartDate="{ item }">
        <span>{{ item.deliveryStartDate | moment('DD MMM YY') }} </span>
        <span v-if="item.deliveryStartDate && item.deliveryEndDate"
          >&nbsp;-&nbsp;</span
        >
        <span>{{ item.deliveryEndDate | moment('DD MMM YY') }} </span>
      </template>

      <template v-slot:no-data>
        <message-box
          icon="mdi-emoticon-neutral-outline"
          title="No Pick Tickets"
          :caption="'Try adjusting your search to get some results'"
        >
        </message-box>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader
          v-for="item in 5"
          :key="item"
          type="list-item"
          class="mx-auto"
        ></v-skeleton-loader>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'

export default {
  name: 'PickTicketListTable',
  components: { MessageBox },
  props: {
    pickTickets: {
      type: Object,
      default: () => ({ items: [] })
    },
    loading: { type: Boolean, default: false },
    itemCount: { type: Number, default: 0 },
    options: { type: Object, default: () => ({ page: 1, itemsPerPage: 15 }) }
  },
  data() {
    return {
      search: null,
      selected: [],
      tableOptions: { page: 1, itemsPerPage: 15 },
      headers: [
        {
          text: 'Ticket No.',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'pickTicketSerial'
        },
        {
          text: '',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'state'
        },
        {
          text: 'Order No.',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'salesOrder.orderNumber'
        },
        {
          text: 'Customer',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'salesOrder.customer.storeName'
        },
        {
          text: 'Order Type',
          sortable: false,
          filterable: true,
          value: 'salesOrder.type'
        },
        {
          text: 'Expected Delivery',
          sortable: false,
          filterable: true,
          value: 'deliveryStartDate'
        },
        {
          text: 'Created On',
          sortable: false,
          filterable: true,
          value: 'createdAt'
        }
        // {
        //   text: '',
        //   value: 'controls',
        //   sortable: false,
        //   filterable: true
        // }
      ]
    }
  },
  watch: {
    options(val) {
      if (val) {
        this.tableOptions = val
      }
    }
  },
  methods: {
    navToDetail(item) {
      this.$router.push({
        name: 'SalesPickTicketDetails',
        params: { pickTicketID: item.id }
      })
    }
  }
}
</script>

<style></style>
