<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container">
          <v-form
            ref="agreementForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row v-if="!editMode">
                  <v-col cols="12">
                    <v-select
                      v-model="item.type"
                      :items="agreementTypes"
                      label="Agreement Type"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredSelectRule"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="item.type === 'COMMISSION'" cols="12">
                    <!-- <v-text-field
                      v-model="item.commissionPercentage"
                      label="Commission Percentage"
                      outlined
                      dense
                      hide-details="auto"
                      suffix="%"
                      type="number"
                    >
                    </v-text-field> -->
                    <v-currency-field
                      v-model="item.commissionPercentage"
                      label="Commission Percentage"
                      autocomplete="off"
                      outlined
                      :rules="requiredRule"
                      hide-details="auto"
                      dense
                      suffix="%"
                    ></v-currency-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      v-model="proposalFile"
                      :rules="requiredRule"
                      label="Select Propoals"
                      prepend-inner-icon="mdi-file-outline"
                      :prepend-icon="null"
                      outlined
                      dense
                      hide-details="auto"
                    >
                      <!-- <template v-slot:selection="{ text }">
                            <v-chip color="info accent-4" dark small>
                              {{ text }}
                            </v-chip>
                          </template> -->
                    </v-file-input>
                  </v-col>
                </v-row>

                <v-row
                  v-if="item.type === 'COMMISSION' || item.type === 'SEASONAL'"
                >
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="item.recipient"
                          :items="receipients"
                          label="Invoice To"
                          outlined
                          dense
                          hide-details="auto"
                          :rules="requiredSelectRule"
                        >
                          <!-- <template v-slot:selection="{ text }">
                            <v-chip color="info accent-4" dark small>
                              {{ text }}
                            </v-chip>
                          </template> -->
                        </v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <v-menu
                          ref="menu1"
                          v-model="expirationDate"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formattedExpirationDate"
                              outlined
                              prepend-inner-icon="mdi-calendar-month-outline"
                              dense
                              label="Valid Till"
                              hide-details="auto"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.expiration"
                            no-title
                            @input="expirationDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import country from 'country-list'

export default {
  name: 'AgreementEditDialog',
  props: {
    agreement: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    expirationDate: false,
    startDate: false,
    proposalFile: null,
    agreedFile: null,
    receipients: [
      'Daily Paper Wholesale BV',
      'Webhelp Financial Representatives'
    ],
    item: null
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Agreement' : 'New Agreement'
    },
    // item() {
    //   return this._.cloneDeep(this.agreement)
    // },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    },
    formattedExpirationDate() {
      return this.item.expiration
        ? this.$moment(this.item.expiration).format('LL')
        : ''
    },
    agreementTypes() {
      if (this.item.agentID) {
        return ['AGENT', 'COMMISSION']
      } else if (this.item.customerID) {
        return ['CUSTOMER', 'SEASONAL']
      } else {
        return ['AGENT', 'COMMISSION', 'CUSTOMER', 'SEASONAL']
      }
    }
  },
  watch: {
    proposalFile(val) {
      if (val) this.item.proposalFile = val
    },
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.agreement)
    }
  },
  methods: {
    close() {
      this.$refs.agreementForm.reset()
      this.$emit('close-agreement-edit-dialog', false)

      // this.$nextTick(() => {
      // this.$refs.agreementForm.resetValidation()
      // })
    },
    save() {
      var that = this
      if (this.$refs.agreementForm.validate()) {
        var data = {
          id: this.item.id,
          agentID: this.item.agentID,
          customerID: this.item.customerID,
          recipient: this.item.recipient,
          proposalFile: this.item.proposalFile,
          expiration: this.$moment(this.item.expiration).utc(),
          type: this.item.type,
          commissionPercentage: this.item.commissionPercentage
            ? this.item.commissionPercentage
            : 0
        }

        if (!this.editMode) {
          delete data.id
        }

        if (typeof data.proposalFile === 'string') {
          data.proposalFile = null
        }

        if (typeof data.agreedFile === 'string') {
          data.agreedFile = null
        }

        if (this.editMode) {
          delete data.agentID
          delete data.customerID
          delete data.isUser
        }

        this.$emit('save-agreement-edit-dialog', data)
        this.$nextTick(() => {
          that.$refs.agreementForm.reset()
        })
      }
    }
  }
}
</script>

<style></style>
