<template>
  <div class="page-wrap">
    <div class="page-header">
      <v-dialog
        v-model="reviewDialog"
        persistent
        max-width="450"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <v-card>
          <v-card-title class="headline primary--text mb-3"
            >Approve Order</v-card-title
          >
          <div v-if="errorMsg !== ''" class="px-3">
            <v-alert type="error" dense>
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-card-text>
            <p>
              By submitting you will approve this sales order.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="grey darken-1"
              :disabled="processing"
              text
              @click="close"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              small
              rounded
              :loading="processing"
              @click="approveOrder"
              >Approve Order</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="cancelOrderDialog"
        persistent
        max-width="450"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Cancel Order</v-card-title
          >
          <div v-if="errorMsg !== ''" class="px-3">
            <v-alert type="error" dense>
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-card-text>
            <p>
              This will cancel the sales order and is final.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="error" text :loading="processing" @click="cancelOrder"
              >Cancel Order</v-btn
            >
            <v-btn
              color="grey darken-1"
              :disabled="processing"
              text
              @click="close"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="closeOrderDialog"
        persistent
        max-width="450"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Close Order</v-card-title
          >
          <div v-if="errorMsg !== ''" class="px-3">
            <v-alert type="error" dense>
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-card-text>
            <p>
              This will close the sales order and is final. You won't be able to record any further shipments against this order.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="error" text :loading="processing" @click="closeOrder"
              >Close order</v-btn
            >
            <v-btn
              color="grey darken-1"
              :disabled="processing"
              text
              @click="close"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="draftOrderDialog"
        persistent
        max-width="450"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <v-card>
          <v-card-title class="headline info--text mb-3"
            >Draft Order</v-card-title
          >
          <div v-if="errorMsg !== ''" class="px-3">
            <v-alert type="error" dense>
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-card-text>
            <p>
              This will set the order back to draft mode for further editing.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="info" text :loading="processing" @click="draftOrder"
              >Set Draft</v-btn
            >
            <v-btn
              color="grey darken-1"
              :disabled="processing"
              text
              @click="close"
              >Close</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="removeDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Order</v-card-title
          >
          <div v-if="errorMsg !== ''" class="px-3">
            <v-alert type="error" dense>
              {{ errorMsg }}
            </v-alert>
          </div>
          <v-card-text>
            <p>
              This will remove the sales order and can't be undone.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn
              color="grey darken-1"
              :disabled="processing"
              text
              @click="close"
              >Cancel</v-btn
            >
            <v-btn color="error" text :loading="processing" @click="removeOrder"
              >Remove</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="uploadOrderDialog" persistent max-width="500">
        <v-card>
          <v-card-title class="headline primary--text mb-3"
            >Upload Order Sheet</v-card-title
          >
          <v-card-text class="pb-0">
            <v-form ref="uploadOrderForm" v-model="valid" @submit.prevent>
              <v-row v-if="errorMsg && errorMsg != ''">
                <v-col>
                  <v-alert type="error" text border="left">
                    {{ errorMsg }}
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    v-model="uploadFile"
                    prepend-inner-icon="mdi-paperclip"
                    :prepend-icon="null"
                    outlined
                    dense
                    hide-details="auto"
                    clearable
                    placeholder="select file"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pa-5">
            <div class="flex-grow-1"></div>
            <v-btn
              color="grey lighten-1"
              text
              small
              rounded
              :disabled="processing"
              @click="closeUploadOrderDialog"
              >Cancel</v-btn
            >
            <v-btn
              color="primary darken-1"
              small
              rounded
              :disabled="!valid"
              :loading="processing"
              @click="saveOrderForm"
              >Submit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <sales-order-clone-dialog
        :dialog="cloneDialog"
        :edited-item="cloneEditedItem"
        :customers="customers"
        :processing="processing"
        :error-msg="errorMsg"
        @close-sales-order-clone-dialog="cancelClone"
        @save-sales-order-clone-dialog="saveClone"
      ></sales-order-clone-dialog>
      <!-- Toolbar -->
      <v-toolbar v-if="salesOrder" class="mb-0 b-radius" flat>
        <v-tabs
          style="width: 80%;"
          next-icon="mdi-chevron-right"
          prev-icon="mdi-chevron-left"
          background-color="transparent"
          align-with-title
          center-active
          show-arrows
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            :to="{
              name: 'SalesOrderDetails',
              params: { salesOrderID: $route.params.salesOrderID }
            }"
            class="text-capitalize"
            exact
            >Details</v-tab
          >

          <v-tab
            v-if="salesOrder.state === 'DRAFT'"
            :to="{
              name: 'SalesOrderProducts',
              params: { salesOrderID: $route.params.salesOrderID }
            }"
            class="text-capitalize"
            >Shop</v-tab
          >

          <v-tab
            :to="{
              name: 'SalesOrderCart',
              params: { salesOrderID: $route.params.salesOrderID }
            }"
            class="text-capitalize"
          >
            <v-badge
              v-if="salesOrder.state === 'DRAFT'"
              color="info"
              :content="unitCount"
              bordered
              inline
              >Cart</v-badge
            >
            <span v-else>Products</span>
          </v-tab>

          <v-tab
            :to="{
              name: 'SalesOrderSummary',
              params: { salesOrderID: $route.params.salesOrderID }
            }"
            class="text-capitalize"
            ><span
              v-if="
                salesOrder.state === 'DRAFT' &&
                  salesOrder.lines &&
                  salesOrder.lines.length > 0
              "
              >Checkout</span
            ><span v-else>Summary</span></v-tab
          >
          <v-tab
            v-if="
              salesOrder.type !== 'RETURN' &&
                (salesOrder.state === 'APPROVED' ||
                  salesOrder.state === 'PROCESSED' ||
                  salesOrder.state === 'SHIPPEDPARTIAL' ||
                  salesOrder.state === 'SHIPPED') &&
                salesOrder.lines &&
                salesOrder.lines.length > 0
            "
            :to="{
              name: 'SalesOrderAllocation',
              params: { salesOrderID: $route.params.salesOrderID }
            }"
            class="text-capitalize"
            ><span>Allocation</span></v-tab
          >
          <v-tab
            v-if="
              salesOrder.type !== 'RETURN' &&
                (salesOrder.state === 'APPROVED' ||
                  salesOrder.state === 'PROCESSED' ||
                  salesOrder.state === 'SHIPPEDPARTIAL' ||
                  salesOrder.state === 'SHIPPED') &&
                salesOrder.lines &&
                salesOrder.lines.length > 0
            "
            :to="{
              name: 'SalesOrderPickTickets',
              params: { salesOrderID: $route.params.salesOrderID }
            }"
            class="text-capitalize"
            ><span>Pick Tickets</span></v-tab
          >
          <v-tab
            v-if="
              salesOrder.type !== 'RETURN' &&
                (salesOrder.state === 'APPROVED' ||
                  salesOrder.state === 'PROCESSED' ||
                  salesOrder.state === 'SHIPPEDPARTIAL' ||
                  salesOrder.state === 'SHIPPED') &&
                salesOrder.lines &&
                salesOrder.lines.length > 0
            "
            :to="{
              name: 'SalesOrderDeliveryNotes',
              params: { salesOrderID: $route.params.salesOrderID }
            }"
            class="text-capitalize"
            ><span>Delivery Notes</span></v-tab
          >
        </v-tabs>
        <v-menu
          v-if="
            (authLevel && authLevel === 2 && currentUser) ||
              (currentUser &&
                (currentUser.isAdmin ||
                  currentUser.isSuperAdmin ||
                  (currentUser.userRole === 'manager' &&
                    currentUser.department.name.toLowerCase() === 'sales') ||
                  currentUser.userRole === 'seniormanager' ||
                  currentUser.userRole === 'director'))
          "
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="info" rounded small dark v-bind="attrs" v-on="on">
              actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-if="salesOrder.state === 'REVIEW'"
              @click="reviewDialog = true"
            >
              <v-list-item-title>Approve Order</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="salesOrder.state === 'APPROVED'"
              @click="cancelOrderDialog = true"
            >
              <v-list-item-title>Cancel Order</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="salesOrder.state === 'PROCESSED' || salesOrder.state === 'SHIPPEDPARTIAL'"
              @click="closeOrderDialog = true"
            >
              <v-list-item-title>Close order</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="salesOrder.state === 'REVIEW'"
              @click="draftOrderDialog = true"
            >
              <v-list-item-title>Back to draft</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="
                salesOrder.type !== 'RETURN' && salesOrder.state !== 'REVIEW'
              "
              @click="cloneOrder"
            >
              <v-list-item-title>Clone Order</v-list-item-title>
            </v-list-item>
            <v-list-item class="pa-0">
              <xlsx-workbook style="width: 100%;">
                <xlsx-sheet :collection="orderLines" sheet-name="Sheet1" />
                <xlsx-download :filename="fileName" class="px-0 mx-0">
                  <v-btn
                    block
                    text
                    class="text-capitalize font-weight-regular text-body-1"
                    >Download Order Sheet</v-btn
                  >
                </xlsx-download>
              </xlsx-workbook>
            </v-list-item>
            <v-list-item @click="uploadOrderDialog = true">
              Upload Order Sheet
            </v-list-item>
            <v-list-item
              v-if="
                salesOrder.state === 'DRAFT' && salesOrder.state !== 'REVIEW'
              "
              @click="removeDialog = true"
            >
              <v-list-item-title>Delete Order</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </div>

    <div class="main-page-column">
      <div class="scroll-container pt-0">
        <router-view
          :sales-order="salesOrder"
          :seasons="seasons"
          :warehouses="warehouses"
          :article-groups="articleGroups"
          :current-user="currentUser"
          :auth-level="authLevel"
          :loading="$apollo.loading"
          @refresh-sales-order="refreshSalesOrder"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'
import SALES_ORDER from '@/graphql/SalesOrder.gql'
import ARTICLE_GROUPS from '@/graphql/ArticleGroups.gql'
import SEASON_LIST from '@/graphql/Seasons.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'
import SALES_ORDER_APPROVE from '@/graphql/SalesOrderApprove.gql'
import SALES_ORDER_CANCEL from '@/graphql/SalesOrderCancel.gql'
import SALES_ORDER_CLOSE from '@/graphql/SalesOrderClose.gql'
import SALES_ORDER_DRAFT from '@/graphql/SalesOrderDraft.gql'
import SALES_ORDER_REMOVE from '@/graphql/SalesOrderRemove.gql'
import SalesOrderCloneDialog from '@/modules/sales/components/core/SalesOrderCloneDialog.vue'
import SALES_ORDER_CLONE from '@/graphql/SalesOrderClone.gql'
import CUSTOMER_LIST from '@/graphql/SalesCustomers.gql'
import UPLOAD_ORDER_FILE from '@/graphql/SalesOrderLineUpdateByCSV.gql'

export default {
  name: 'SalesOrderDetailLayout',
  components: {
    SalesOrderCloneDialog,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data: () => ({
    reviewDialog: false,
    cancelOrderDialog: false,
    closeOrderDialog: false,
    draftOrderDialog: false,
    loading: false,
    processing: false,
    errorMsg: '',
    salesOrder: {},

    removeDialog: false,

    cloneDialog: false,
    searchCustomers: '',
    cloneEditedItem: {
      orderID: null,
      customerID: null,
      notes: null,
      customerPoNumber: null,
      deliveryStartDate: new Date(),
      deliveryEndDate: new Date()
    },

    uploadFile: null,
    uploadOrderDialog: false,
    valid: true,

    orderLines: []
  }),
  computed: {
    unitCount() {
      return this.salesOrder?.lines?.length || '0'
    },
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('sales_orders')
    },
    fileName() {
      return `order_${this.salesOrder?.orderNumber}_${this.$moment().format(
        'DDMMYYYY'
      )}.xlsx`
    }
  },
  apollo: {
    salesOrder: {
      query: SALES_ORDER,
      variables() {
        return {
          id: this.$route.params.salesOrderID
        }
      },
      fetchPolicy: 'no-cache',
      result(result) {
        if (result && result.data && result.data.salesOrder) {
          this.generateExcelData()
        } else {
          this.orderLines = []
        }
        this.loading = false
      }
    },
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            search: null,
            page: 1,
            pageSize: 1000
          }
        }
      }
    },
    customers: {
      query: CUSTOMER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchCustomers,
            page: 1,
            pageSize: 25
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200
    },
    articleGroups: ARTICLE_GROUPS,
    seasons: SEASON_LIST
  },
  watch: {},
  created() {},
  methods: {
    generateExcelData() {
      var list = []

      this.salesOrder.lines.forEach(line => {
        line.items.forEach(item => {
          var data = {
            Season: line.product?.season?.shortName,
            'Style Number': line.product?.styleNumber,
            Category: line.product?.articleGroup?.name,
            Name: line.product?.name,
            Color: line.product?.seasonColor, // season color
            'Wholesale Price (EUR)': Math.floor(
              line.wholesalePrice / 100
            ).toFixed(2), // wholesale price to double in Eur
            'Retail Price (EUR)': Math.floor(line.retailPrice / 100).toFixed(2), // Retail price to double in Eur
            Material: line.product?.textileContent,
            Weight: line.product?.weight,
            'Country of Origin': line.product?.countryOfOrigin,
            'HTS Code': line.product?.htsCode,
            Size: item.name,
            Barcode: item.barcode,
            Qty: item.quantity
          }

          list.push(data)
        })
      })

      this.orderLines = list
    },
    cloneOrder() {
      this.cloneEditedItem = {
        orderID: this.salesOrder.id,
        customer: this.salesOrder.customer,
        notes: null,
        customerPoNumber: null,
        deliveryStartDate: this.salesOrder.deliveryStartDate,
        deliveryEndDate: this.salesOrder.deliveryEndDate
      }

      if (this.$moment().isAfter(this.salesOrder.deliveryStartDate)) {
        this.cloneEditedItem.deliveryStartDate = this.$moment()
          .add(1, 'days')
          .utc()

        this.cloneEditedItem.deliveryEndDate = this.$moment()
          .add(30, 'days')
          .utc()
      }

      this.cloneDialog = true
    },
    cancelClone() {
      this.cloneEditedItem = {
        orderID: null,
        customerID: null,
        notes: null,
        customerPoNumber: null,
        deliveryStartDate: new Date(),
        deliveryEndDate: new Date()
      }
      this.cloneDialog = false
      this.errorMsg = ''
      this.processing = false
    },
    saveClone(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_CLONE,
          variables: {
            input: item
          }
        })
        .then(result => {
          this.cancelClone()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Cloned'
          })
          this.$router.push({
            name: 'SalesOrderDetails',
            params: { salesOrderID: result.data.cloneSalesOrder }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    removeOrder() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_REMOVE,
          variables: {
            id: this.salesOrder.id
          }
        })
        .then(() => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Removed'
          })
          this.$router.push({
            name: 'SalesOrderList'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    refreshSalesOrder() {
      this.$apollo.queries.salesOrder.refetch()
    },
    close() {
      this.reviewDialog = false
      this.cancelOrderDialog = false
      this.closeOrderDialog = false
      this.draftOrderDialog = false
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    saveOrderForm() {
      this.errorMsg = ''
      var vm = this
      if (this.$refs.uploadOrderForm.validate()) {
        vm.processing = true
        let data = {
          orderID: vm.salesOrder.id,
          file: vm.uploadFile
        }

        vm.$apollo
          .mutate({
            mutation: UPLOAD_ORDER_FILE,
            variables: {
              input: data
            }
          })
          .then(() => {
            vm.$apollo.queries.salesOrder.refetch()
            vm.closeUploadOrderDialog()

            vm.$store.commit('showSnackbar', {
              active: true,
              message: 'File uploaded'
            })
          })
          .catch(() => {
            vm.errorMsg = 'Something went wrong try again later'
            vm.processing = false
          })
      }
    },
    closeUploadOrderDialog() {
      this.uploadOrderDialog = false
      this.processing = false

      this.uploadFile = null

      if (this.$refs.uploadOrderForm) {
        this.$refs.uploadOrderForm.resetValidation()
      }
    },
    approveOrder() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_APPROVE,
          variables: {
            id: this.salesOrder.id
          }
        })
        .then(() => {
          this.refreshSalesOrder()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Approved'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    cancelOrder() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_CANCEL,
          variables: {
            id: this.salesOrder.id
          }
        })
        .then(() => {
          this.refreshSalesOrder()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Canceled'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    closeOrder() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_CLOSE,
          variables: {
            id: this.salesOrder.id
          }
        })
        .then(() => {
          this.refreshSalesOrder()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Closed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    draftOrder() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_DRAFT,
          variables: {
            id: this.salesOrder.id
          }
        })
        .then(() => {
          this.refreshSalesOrder()
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Set To Draft'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 50px;
  /* --page-wrap-offset: -20px; */
}

.custom-list-btn:hover {
  background: 0.04;
}
</style>
