<template>
  <div>
    <v-toolbar class="mb-0 b-radius" color="transparent" flat>
      <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
      <v-row>
        <v-col
          v-if="
            authLevel &&
              authLevel === 2 &&
              currentRoute.path.startsWith('/production')
          "
          cols="6"
          lg="2"
          xl="2"
          class="pl-0"
        >
          <v-btn color="primary" rounded @click="editDialog = true">
            add product
          </v-btn>
        </v-col>

        <v-col
          cols="7"
          lg="3"
          xl="2"
          :class="[
            authLevel &&
            authLevel === 2 &&
            currentRoute.path.startsWith('/production')
              ? 'd-none d-sm-none d-md-none d-lg-flex'
              : 'd-none d-sm-none d-md-none d-lg-flex pl-0'
          ]"
        >
          <season-switcher
            :background-color="'#fff'"
            :items="seasons"
            :prepend-inner-icon="'mdi-filter-variant'"
            :disabled="!seasons || seasons.length === 0"
          ></season-switcher>
        </v-col>

        <v-col
          cols="3"
          lg="3"
          xl="2"
          class="d-none d-sm-none d-md-none d-lg-flex"
        >
          <v-select
            v-model="selectedDesigner"
            :items="designers"
            hide-details
            outlined
            flat
            dense
            clearable
            background-color="#fff"
            label="designer"
            placeholder="all"
            prepend-inner-icon="mdi-filter-variant"
            autocomplete="off"
            item-value="id"
            :disabled="
              !products ||
                (products.items.length === 0 && !users) ||
                users.length === 0
            "
          >
            <template v-slot:selection="subData"
              ><div class="text-truncate" style="width: 80%">
                {{ subData.item.firstName + ' ' + subData.item.lastName }}
              </div></template
            >
            <template v-slot:item="{ item }">
              <span>{{ item.firstName + ' ' + item.lastName }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="3"
          lg="3"
          xl="2"
          class="d-none d-sm-none d-md-none d-lg-flex"
        >
          <v-select
            v-model="selectedProducer"
            hide-details
            outlined
            flat
            dense
            clearable
            background-color="#fff"
            label="developer"
            placeholder="all"
            prepend-inner-icon="mdi-filter-variant"
            autocomplete="off"
            item-value="id"
            :items="productDevelopers"
            :disabled="
              !products ||
                (products.items.length === 0 && !users) ||
                users.length === 0
            "
          >
            <template v-slot:selection="subData"
              ><div class="text-truncate" style="width: 80%">
                {{ subData.item.firstName + ' ' + subData.item.lastName }}
              </div></template
            >
            <template v-slot:item="{ item }">
              <span>{{ item.firstName + ' ' + item.lastName }}</span>
            </template>
          </v-select>
        </v-col>
      </v-row>

      <v-spacer></v-spacer>
      <v-row justify="end">
        <span class="pt-3 text-truncate">{{ itemCount }} Products</span>

        <v-btn
          icon
          class="d-sm-flex d-md-flex d-lg-none d-xl-none ml-2"
          @click="filters = !filters"
        >
          <v-icon :color="filters ? 'info' : ''">mdi-filter-variant</v-icon>
        </v-btn>
      </v-row>
    </v-toolbar>
  </div>
</template>

<script>
// import MessageBox from '@/components/core/MessageBox.vue'
import SeasonSwitcher from '@/components/core/SeasonSwitcher.vue'

export default {
  name: 'ProductListToolbar',
  components: {
    // MessageBox,
    SeasonSwitcher
  },
  props: {
    authLevel: {
      type: Number,
      default: 0
    },
    seasons: {
      type: Array,
      default: () => []
    },
    apollo: {
      type: Boolean,
      default: false
    },
    designers: {
      type: Array,
      default: () => []
    },
    productDevelopers: {
      type: Array,
      default: () => []
    },
    products: {
      type: Object,
      default: () => ({})
    },
    users: {
      type: Array,
      default: () => []
    },
    itemCount: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    filters: false,
    selectedDesigner: null,
    selectedProducer: null
  }),
  computed: {
    currentRoute() {
      return this.$router.currentRoute
    }
  },
  watch: {
    filters(val) {
      this.$emit('set-filters', val)
    },
    selectedDesigner(val) {
      this.$emit('set-selected-designer', val)
    },
    selectedProducer(val) {
      this.$emit('set-selected-producer', val)
    }
  }
}
</script>

<style></style>
