<template>
  <span>{{ discountFormat(discount) }}</span>
</template>

<script>
export default {
  name: 'DiscountFormatter',
  props: {
    discount: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  methods: {
    discountFormat(item) {
      var discountType

      switch (item.type) {
        case 'ONEOFF':
          discountType = 'oneoff'
          break
        case 'DAYS':
          discountType = `${item.description}`
          break
        default:
          break
      }

      return `${item.percentage}% ${discountType}`
    }
  }
}
</script>

<style></style>
