<template>
  <v-row>
    <lineSheet-edit-dialog
      :dialog="dialog"
      :line-sheet="editedItem"
      :seasons="seasons.seasons"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-line-sheet-edit-dialog="close"
      @save-line-sheet-edit-dialog="save"
    ></lineSheet-edit-dialog>

    <v-col v-if="lineSheet" cols="12" class="py-0">
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-row v-if="lineSheet.image && lineSheet.image !== ''">
            <v-col class="pt-0">
              <v-img max-height="120" :src="lineSheet.image"></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title>
                  <!-- <span>Details</span> -->

                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((lineSheet && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editLineSheet"
                  >
                    edit
                  </v-btn></v-card-title
                >
                <v-card-text>
                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">Name</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ lineSheet.name }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Season</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            v-if="lineSheet.season"
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ lineSheet.season.shortName }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col> -->
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Description</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ lineSheet.description }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Owner</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <div
                            v-if="lineSheet.owner && lineSheet.owner.id"
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{
                              lineSheet.owner.firstName +
                                ' ' +
                                lineSheet.owner.lastName
                            }}
                          </div>
                          <div
                            v-if="lineSheet.owner && lineSheet.owner.id"
                            class="caption"
                          >
                            {{ lineSheet.owner.email }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Prebook Start</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{
                              lineSheet.preBookStart | moment('DD MMM YYYY')
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Prebook End</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{
                              lineSheet.preBookEnd | moment('DD MMM YYYY')
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import LineSheetEditDialog from '@/modules/sales/components/core/LineSheetEditDialog.vue'
import LINE_SHEET_UPDATE from '@/graphql/LineSheetUpdate.gql'

export default {
  name: 'LineSheetDetail',
  components: { LineSheetEditDialog },
  props: {
    lineSheet: { type: Object, default: () => {} },
    seasons: {
      type: Object,
      default: () => {
        return { seasons: [] }
      }
    },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    dialog: false,
    addressDialog: false,
    contactDialog: false,
    removeContactDialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    removeContactID: null,
    defaultItem: {
      id: null,
      name: '',
      commerceReg: '',
      shippingNumber: '',
      vatId: '',
      status: '',
      website: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: ''
    },
    editedItem: {
      id: null,
      name: '',
      commerceReg: '',
      shippingNumber: '',
      vatId: '',
      status: '',
      website: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: ''
    }
  }),
  computed: {
    contactHeaders() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'email'
        },
        {
          text: 'Phone',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'phone'
        },
        {
          text: 'Status',
          value: 'status',
          visible: false,
          sortable: false
        },
        {
          text: '',
          value: 'controls',
          align: 'right',
          visible: false,
          sortable: false
        }
      ]
    }
  },
  methods: {
    editLineSheet() {
      this.editedItem = this._.cloneDeep(this.lineSheet)
      this.editMode = true
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: LINE_SHEET_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('refetch-line-sheet')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'LineSheet Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
