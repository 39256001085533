<template>
  <div>
    <v-row no-gutters justify="center">
      <v-col v-if="frontImage" cols="3" md="2">
        <v-row justify="center">
          <v-col cols="12">
            <v-img
              :src="frontImage"
              rounded
              max-height="75px"
              class="thumb-hover"
              contain
              @click="switchImage('front')"
            ></v-img>
          </v-col>
          <v-col v-if="rearImage !== frontImage" cols="12">
            <v-img
              :src="rearImage"
              max-height="75px"
              class="thumb-hover"
              contain
              @click="switchImage('rear')"
            ></v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="9">
        <div class="pa-3">
          <v-img
            v-if="activeImage === 'front' && frontImage"
            :src="frontImage"
            max-height="300px"
            contain
          ></v-img>

          <v-img
            v-if="activeImage === 'rear' && rearImage"
            :src="rearImage"
            max-height="300px"
            contain
          ></v-img>
          <v-img
            v-if="!frontImage"
            :src="'/DAILY_PAPER_LOGO.png'"
            max-height="70px"
            contain
          ></v-img>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ProductImageCarousel',
  props: {
    frontImage: {
      type: String,
      default: '/DAILY_PAPER_LOGO.png'
    },
    rearImage: {
      type: String,
      default: '/DAILY_PAPER_LOGO.png'
    }
  },
  data: () => ({
    activeImage: 'front'
  }),
  created() {
    // if (this.frontImage && this.frontImage !== this.activeImage) {
    this.activeImage = 'front'
    // } else {
    //   this.activeImage = '/DAILY_PAPER_LOGO.png'
    // }
  },
  methods: {
    switchImage(direction) {
      if (
        direction === 'front' &&
        this.activeImage === 'rear' &&
        this.rearImage &&
        this.rearImage !== '/DAILY_PAPER_LOGO.png'
      ) {
        this.activeImage = 'front'
      } else if (direction === 'rear' && this.activeImage === 'front') {
        this.activeImage = 'rear'
      }
    }
  }
}
</script>

<style>
.thumb-hover {
  cursor: pointer;
}
</style>
