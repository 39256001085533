<template>
  <v-row>
    <agreement-edit-dialog
      :dialog="dialog"
      :agreement="editedItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-agreement-edit-dialog="close"
      @save-agreement-edit-dialog="save"
    ></agreement-edit-dialog>
    <agreement-sign-edit-dialog
      :dialog="signDialog"
      :agreement="editSignItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-agreement-edit-dialog="close"
      @save-agreement-sign-dialog="signAgreement"
    ></agreement-sign-edit-dialog>
    <payment-term-edit-dialog
      :dialog="paymentTermDialog"
      :payment-term="editedPaymentTermItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-payment-term-edit-dialog="close"
      @save-payment-term-edit-dialog="savePaymentTerm"
    ></payment-term-edit-dialog>

    <v-dialog v-model="removeAgreementDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Agreement</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the agreement and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="error" text @click="removeAgreement">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="removePaymentTermDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Payment Term</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the payment term and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            @click="removePaymentTermDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="removePaymentTerm">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Discount Dialogs -->
    <discount-edit-dialog
      :dialog="discountDialog"
      :discount="editedDiscountItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-discount-edit-dialog="close"
      @save-discount-edit-dialog="saveDiscount"
    ></discount-edit-dialog>
    <v-dialog v-model="removeDiscountDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Discount</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the discount and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            @click="removeDiscountDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="removeDiscount">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DeliveryTerm Dialogs -->
    <delivery-term-edit-dialog
      :dialog="deliveryTermDialog"
      :delivery-term="editedDeliveryTermItem"
      :currencies="currencies"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-delivery-term-edit-dialog="close"
      @save-delivery-term-edit-dialog="saveDeliveryTerm"
    ></delivery-term-edit-dialog>
    <v-dialog v-model="removeDeliveryTermDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Delivery Term</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the delivery term and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            @click="removeDeliveryTermDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="removeDeliveryTerm">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- InvoiceTerm Dialogs -->
    <invoice-term-edit-dialog
      :dialog="invoiceTermDialog"
      :invoice-term="editedInvoiceTermItem"
      :currencies="currencies"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-invoice-term-edit-dialog="close"
      @save-invoice-term-edit-dialog="saveInvoiceTerm"
    ></invoice-term-edit-dialog>
    <v-dialog v-model="removeInvoiceTermDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Invoice Term</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the invoice term and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            @click="removeInvoiceTermDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="removeInvoiceTerm">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- ReturnPolicy Dialogs -->
    <returnPolicy-edit-dialog
      :dialog="returnPolicyDialog"
      :return-policy="editedReturnPolicyItem"
      :currencies="currencies"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-return-policy-edit-dialog="close"
      @save-return-policy-edit-dialog="saveReturnPolicy"
    ></returnPolicy-edit-dialog>
    <v-dialog v-model="removeReturnPolicyDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Return Policy</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the return Policy and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            text
            @click="removeReturnPolicyDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="removeReturnPolicy">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col v-if="customer" cols="12" class="py-0">
      <v-row no-gutters>
        <v-col class="pl-0 mb-6">
          <v-row no-gutters>
            <v-btn color="primary" rounded @click="editAgreement">
              add agreement
            </v-btn>
          </v-row>
          <v-row v-if="addressError" no-gutters>
            <v-col cols="12">
              <v-alert type="info" class="mb-0 mt-6" dismissible border="left">
                Please add all customer addresses before continuing
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="10" xl="8">
          <v-row no-gutters>
            <v-col
              v-if="
                !loading && generalAgreements && generalAgreements.length > 0
              "
              cols="12"
              class="mb-10"
              ><h3 class="pl-1 mb-3">General</h3>
              <agreement-list-table
                :agreements="generalAgreements || []"
                :default-currency="defaultCurrency"
                :loading="loading"
                @edit-agreement="editAgreement"
                @open-sign-agreement="openSignAgreement"
                @remove-agreement="openRemoveAgreementDialog"
                @edit-payment-term="editPaymentTerm"
                @remove-payment-term="openRemovePaymentTermDialog"
                @edit-discount="editDiscount"
                @remove-discount="openRemoveDiscountDialog"
                @edit-delivery-term="editDeliveryTerm"
                @remove-delivery-term="openRemoveDeliveryTermDialog"
                @edit-return-policy="editReturnPolicy"
                @remove-return-policy="openRemoveReturnPolicyDialog"
              ></agreement-list-table>
            </v-col>

            <v-col
              v-if="
                !loading && seasonalAgreements && seasonalAgreements.length > 0
              "
              cols="12"
            >
              <h3 class="pl-1 mb-3">Seasonal</h3>

              <agreement-list-table
                :agreements="seasonalAgreements || []"
                :default-currency="defaultCurrency"
                :loading="loading"
                @edit-agreement="editAgreement"
                @open-sign-agreement="openSignAgreement"
                @remove-agreement="openRemoveAgreementDialog"
                @edit-payment-term="editPaymentTerm"
                @remove-payment-term="openRemovePaymentTermDialog"
                @edit-discount="editDiscount"
                @remove-discount="openRemoveDiscountDialog"
                @edit-delivery-term="editDeliveryTerm"
                @remove-delivery-term="openRemoveDeliveryTermDialog"
                @edit-invoice-term="editInvoiceTerm"
                @remove-invoice-term="openRemoveInvoiceTermDialog"
                @edit-return-policy="editReturnPolicy"
                @remove-return-policy="openRemoveReturnPolicyDialog"
              ></agreement-list-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { getName } from 'country-list'
import AgreementEditDialog from '@/modules/sales/components/core/AgreementEditDialog.vue'
import AgreementSignEditDialog from '@/modules/sales/components/core/AgreementSignEditDialog.vue'
import PaymentTermEditDialog from '@/modules/sales/components/core/PaymentTermEditDialog.vue'
import AgreementListTable from '../components/core/AgreementListTable.vue'
import AGREEMENT_CREATE from '@/graphql/AgreementProposalCreate.gql'
import AGREEMENT_UPDATE from '@/graphql/AgreementProposalUpdate.gql'
import AGREEMENT_SIGN from '@/graphql/AgreementSign.gql'
import AGREEMENT_REMOVE from '@/graphql/AgreementRemove.gql'
import PAYMENT_TERM_CREATE from '@/graphql/SalesPaymentTermCreate.gql'
import PAYMENT_TERM_UPDATE from '@/graphql/SalesPaymentTermUpdate.gql'
import PAYMENT_TERM_REMOVE from '@/graphql/SalesPaymentTermRemove.gql'

import DiscountEditDialog from '@/modules/sales/components/core/DiscountEditDialog.vue'
import DISCOUNT_CREATE from '@/graphql/SalesDiscountCreate.gql'
import DISCOUNT_UPDATE from '@/graphql/SalesDiscountUpdate.gql'
import DISCOUNT_REMOVE from '@/graphql/SalesDiscountRemove.gql'

import DeliveryTermEditDialog from '@/modules/sales/components/core/DeliveryTermEditDialog.vue'
import DELIVERY_TERM_CREATE from '@/graphql/SalesDeliveryTermCreate.gql'
import DELIVERY_TERM_UPDATE from '@/graphql/SalesDeliveryTermUpdate.gql'
import DELIVERY_TERM_REMOVE from '@/graphql/SalesDeliveryTermRemove.gql'

import InvoiceTermEditDialog from '@/modules/sales/components/core/InvoiceTermEditDialog.vue'
import INVOICE_TERM_CREATE from '@/graphql/SalesInvoiceTermCreate.gql'
import INVOICE_TERM_UPDATE from '@/graphql/SalesInvoiceTermUpdate.gql'
import INVOICE_TERM_REMOVE from '@/graphql/SalesInvoiceTermRemove.gql'

import ReturnPolicyEditDialog from '@/modules/sales/components/core/ReturnPolicyEditDialog.vue'
import RETURN_POLICY_CREATE from '@/graphql/SalesReturnPolicyCreate.gql'
import RETURN_POLICY_UPDATE from '@/graphql/SalesReturnPolicyUpdate.gql'
import RETURN_POLICY_REMOVE from '@/graphql/SalesReturnPolicyRemove.gql'

export default {
  name: 'CustomerAgreementList',
  components: {
    AgreementEditDialog,
    AgreementSignEditDialog,
    PaymentTermEditDialog,
    AgreementListTable,
    DiscountEditDialog,
    DeliveryTermEditDialog,
    InvoiceTermEditDialog,
    ReturnPolicyEditDialog
  },
  props: {
    customer: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    dialog: false,
    removeAgreementDialog: false,
    removePaymentTermDialog: false,
    signDialog: false,
    paymentTermDialog: false,
    addressError: false,

    discountDialog: false,
    removeDiscountDialog: false,
    removeDiscountID: null,
    defaultDiscountItem: {
      id: null,
      agreementID: null,
      description: '',
      type: 'ONEOFF',
      percentage: 1
    },
    editedDiscountItem: {},

    deliveryTermDialog: false,
    removeDeliveryTermDialog: false,
    removeDeliveryTermID: null,
    defaultDeliveryTermItem: {
      id: null,
      agreementID: null,
      description: '',
      type: 'NONE',
      percentage: 0,
      amount: 0,
      currencyID: null
    },
    editedDeliveryTermItem: {},

    invoiceTermDialog: false,
    removeInvoiceTermDialog: false,
    removeInvoiceTermID: null,
    defaultInvoiceTermItem: {
      id: null,
      agreementID: null,
      title: '',
      description: '',
      display: ''
    },
    editedInvoiceTermItem: {},

    returnPolicyDialog: false,
    removeReturnPolicyDialog: false,
    removeReturnPolicyID: null,
    defaultReturnPolicyItem: {
      id: null,
      agreementID: null,
      allowed: true,
      amount: 0
    },
    editedReturnPolicyItem: {},

    editMode: false,
    processing: false,
    errorMsg: '',
    removeAgreementID: null,
    removePaymentTermID: null,
    defaultItem: {
      customerID: null,
      proposalFile: null,
      type: null,
      expiration: null,
      commissionPercentage: 0
    },
    editSignItem: {},
    editedItem: {
      id: null,
      customerID: null,
      proposalFile: null,
      type: null,
      expiration: null,
      commissionPercentage: 0
    },
    defaultPaymentTermItem: {
      id: null,
      agreementID: null,
      paymentMethod: 'WIRE',
      type: 'DAYS',
      percentage: 1
    },
    editedPaymentTermItem: {}
  }),
  computed: {
    generalAgreements() {
      return this._.filter(this.customer.agreements, item => {
        return item.type === 'CUSTOMER'
      })
    },
    seasonalAgreements() {
      return this._.filter(this.customer.agreements, item => {
        return item.type === 'SEASONAL'
      })
    },
    defaultCurrency() {
      return this._.find(this.currencies, item => {
        return item.defaultCurrency
      })
    }
  },
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    editAgreement(item) {
      if (
        !this.customer.visitingAddress ||
        !this.customer.billingAddress ||
        !this.customer.shippingAddress
      ) {
        this.addressError = true
      } else {
        if (item?.id) {
          this.editMode = true
          this.editedItem = this._.cloneDeep(item)
        } else {
          this.editedItem = this._.cloneDeep(this.defaultItem)
          this.editedItem.customerID = this.$route.params.customerID
          this.editedItem.type = 'CUSTOMER'

          let date = new Date()
          date.setDate(date.getDate() + 365)

          this.editedItem.expiration = date.toISOString()
        }

        this.dialog = true
      }
    },
    openSignAgreement(item) {
      if (
        !this.customer.visitingAddress ||
        !this.customer.billingAddress ||
        !this.customer.shippingAddress
      ) {
        this.addressError = true
      } else {
        this.editSignItem = this._.cloneDeep(item)

        this.signDialog = true
      }
    },
    openRemoveAgreementDialog(id) {
      this.removeAgreementID = id
      this.removeAgreementDialog = true
    },
    openRemovePaymentTermDialog(id) {
      this.removePaymentTermID = id
      this.removePaymentTermDialog = true
    },
    close() {
      this.dialog = false
      this.signDialog = false
      this.removeAgreementDialog = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.editSignItem = {}

      this.paymentTermDialog = false
      this.removePaymentTermDialog = false
      this.editedPaymentTermItem = this._.cloneDeep(this.defaultPaymentTermItem)

      this.discountDialog = false
      this.removeDiscountDialog = false
      this.editedDiscountItem = this._.cloneDeep(this.defaultDiscountItem)

      this.deliveryTermDialog = false
      this.removeDeliveryTermDialog = false
      this.editedDeliveryTermItem = this._.cloneDeep(
        this.defaultDeliveryTermItem
      )

      this.returnPolicyDialog = false
      this.removeReturnPolicyDialog = false
      this.editedReturnPolicyItem = this._.cloneDeep(
        this.defaultReturnPolicyItem
      )

      this.invoiceTermDialog = false
      this.removeInvoiceTermDialog = false
      this.editedInvoiceTermItem = this._.cloneDeep(this.defaultInvoiceTermItem)

      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    signAgreement(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: AGREEMENT_SIGN,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('refetch-customer')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Agreement Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true
      delete item.agentID

      if (!this.editMode) {
        this.$apollo
          .mutate({
            mutation: AGREEMENT_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Agreement Saved'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: AGREEMENT_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Agreement Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    removeAgreement() {
      this.$apollo
        .mutate({
          mutation: AGREEMENT_REMOVE,
          variables: {
            id: this.removeAgreementID
          }
        })
        .then(() => {
          this.$emit('refetch-customer')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Agreement Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    editPaymentTerm(agreementID, item) {
      if (item?.id) {
        this.editMode = true
        this.editedPaymentTermItem = this._.cloneDeep(item)
      } else {
        this.editedPaymentTermItem = this._.cloneDeep(
          this.defaultPaymentTermItem
        )
        this.editedPaymentTermItem.agreementID = agreementID
      }

      this.paymentTermDialog = true
    },
    savePaymentTerm(item) {
      this.errorMsg = ''
      this.processing = true

      if (!this.editMode) {
        this.$apollo
          .mutate({
            mutation: PAYMENT_TERM_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Payment Term Saved'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: PAYMENT_TERM_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Payment Term Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    removePaymentTerm() {
      this.$apollo
        .mutate({
          mutation: PAYMENT_TERM_REMOVE,
          variables: {
            id: this.removePaymentTermID
          }
        })
        .then(() => {
          this.$emit('refetch-customer')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Payment Term Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    editDiscount(agreementID, item) {
      if (item?.id) {
        this.editMode = true
        this.editedDiscountItem = this._.cloneDeep(item)
      } else {
        this.editedDiscountItem = this._.cloneDeep(this.defaultDiscountItem)
        this.editedDiscountItem.agreementID = agreementID
      }

      this.discountDialog = true
    },
    openRemoveDiscountDialog(id) {
      this.removeDiscountID = id
      this.removeDiscountDialog = true
    },
    saveDiscount(item) {
      this.errorMsg = ''
      this.processing = true

      if (!this.editMode) {
        this.$apollo
          .mutate({
            mutation: DISCOUNT_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Discount Saved'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: DISCOUNT_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Discount Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    removeDiscount() {
      this.$apollo
        .mutate({
          mutation: DISCOUNT_REMOVE,
          variables: {
            id: this.removeDiscountID
          }
        })
        .then(() => {
          this.$emit('refetch-customer')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Discount Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    editDeliveryTerm(agreementID, item) {
      if (item?.id) {
        this.editMode = true
        this.editedDeliveryTermItem = this._.cloneDeep(item)
      } else {
        this.editedDeliveryTermItem = this._.cloneDeep(
          this.defaultDeliveryTermItem
        )
        this.editedDeliveryTermItem.agreementID = agreementID
        this.editedDeliveryTermItem.currencyID = this.defaultCurrency.id
      }

      this.deliveryTermDialog = true
    },
    openRemoveDeliveryTermDialog(id) {
      this.removeDeliveryTermID = id
      this.removeDeliveryTermDialog = true
    },
    saveDeliveryTerm(item) {
      this.errorMsg = ''
      this.processing = true

      if (!this.editMode) {
        this.$apollo
          .mutate({
            mutation: DELIVERY_TERM_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Delivery Term Saved'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: DELIVERY_TERM_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Delivery Term Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    removeDeliveryTerm() {
      this.$apollo
        .mutate({
          mutation: DELIVERY_TERM_REMOVE,
          variables: {
            id: this.removeDeliveryTermID
          }
        })
        .then(() => {
          this.$emit('refetch-customer')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Delivery Term Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    editInvoiceTerm(agreementID, item) {
      if (item?.id) {
        this.editMode = true
        this.editedInvoiceTermItem = this._.cloneDeep(item)
      } else {
        this.editedInvoiceTermItem = this._.cloneDeep(
          this.defaultInvoiceTermItem
        )
        this.editedInvoiceTermItem.agreementID = agreementID
        this.editedInvoiceTermItem.currencyID = this.defaultCurrency.id
      }

      this.invoiceTermDialog = true
    },
    openRemoveInvoiceTermDialog(id) {
      this.removeInvoiceTermID = id
      this.removeInvoiceTermDialog = true
    },
    saveInvoiceTerm(item) {
      this.errorMsg = ''
      this.processing = true

      if (!this.editMode) {
        this.$apollo
          .mutate({
            mutation: INVOICE_TERM_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Invoice Term Saved'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: INVOICE_TERM_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Invoice Term Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    removeInvoiceTerm() {
      this.$apollo
        .mutate({
          mutation: INVOICE_TERM_REMOVE,
          variables: {
            id: this.removeInvoiceTermID
          }
        })
        .then(() => {
          this.$emit('refetch-customer')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Invoice Term Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    editReturnPolicy(agreementID, item) {
      if (item?.id) {
        this.editMode = true
        this.editedReturnPolicyItem = this._.cloneDeep(item)
      } else {
        this.editedReturnPolicyItem = this._.cloneDeep(
          this.defaultReturnPolicyItem
        )
        this.editedDeliveryTermItem.agreementID = agreementID
        this.editedReturnPolicyItem.agreementID = agreementID
      }

      this.returnPolicyDialog = true
    },
    openRemoveReturnPolicyDialog(id) {
      this.removeReturnPolicyID = id
      this.removeReturnPolicyDialog = true
    },
    saveReturnPolicy(item) {
      this.errorMsg = ''
      this.processing = true

      if (!this.editMode) {
        this.$apollo
          .mutate({
            mutation: RETURN_POLICY_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Return Policy Saved'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: RETURN_POLICY_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Return Policy Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    removeReturnPolicy() {
      this.$apollo
        .mutate({
          mutation: RETURN_POLICY_REMOVE,
          variables: {
            id: this.removeReturnPolicyID
          }
        })
        .then(() => {
          this.$emit('refetch-customer')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Return Policy Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
