<template>
  <v-card flat>

    <v-data-table
      v-model="selected"
      :loading="$apollo.loading"
      :headers="headers"
      :items="deliveryNotesBySalesOrder"
      :mobile-breakpoint="null"
      :items-per-page="-1"
      fixed-header
      class="plain-table"
      :hide-default-header="
        !$apollo.loading &&
          !search &&
          (!deliveryNotesBySalesOrder ||
            !deliveryNotesBySalesOrder ||
            deliveryNotesBySalesOrder.length === 0)
      "
      :hide-default-footer="!deliveryNotesBySalesOrder || itemCount < 16"
      @click:row="navToDetail"
    >
      <template v-slot:item.createdAt="{ item }">
        <span v-if="item.createdAt" class="text-no-wrap">{{
          item.createdAt | moment('LLL')
        }}</span>
      </template>

      <template v-slot:item.warehouse="{ item }">
        <div>
          {{ item.warehouse.name }} ({{ item.warehouse.provider.name }})
        </div>
      </template>

      <template v-slot:item.trackAndTraceValue="{ item }">
        <div>
          <a target="_blank" :href="item.trackAndTraceValue">{{
            item.trackAndTraceValue
          }}</a>
        </div>
      </template>

      <template v-slot:no-data>
        <message-box
          icon="mdi-emoticon-neutral-outline"
          title="No Delivery Notes"
          :caption="'Try adjusting your search to get some results'"
        >
        </message-box>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader
          v-for="item in 5"
          :key="item"
          type="list-item"
          class="mx-auto"
        ></v-skeleton-loader>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import DELIVERY_NOTES_BY_SALES_ORDER from '@/graphql/DeliveryNotesBySalesOrder.gql'

export default {
  name: 'DeliveryNoteList',
  components: { MessageBox },
  props: {
    salesOrder: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    saerch: null,
    itemCount: 0,
    selected: [],
    deliveryNotesBySalesOrder: [],
    warehouses: { items: [] },
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    warehouseID: null,
    headers: [
      {
        text: 'Warehouse',
        sortable: false,
        filterable: true,
        value: 'warehouse'
      },
      {
        text: 'Tracking',
        sortable: false,
        filterable: true,
        value: 'trackAndTraceValue'
      },
      {
        text: 'Created On',
        sortable: false,
        filterable: true,
        value: 'createdAt'
      }
    ],
    defaultItem: {
      id: null,
      stockChange: 0
    },
    editedItem: {
      id: null,
      stockChange: 0
    }
  }),
  apollo: {
    deliveryNotesBySalesOrder: {
      query: DELIVERY_NOTES_BY_SALES_ORDER,
      variables() {
        return {
          salesOrderID: this.salesOrder.id
        }
      },
      // fetchPolicy: 'no-cache',
      debounce: 200,
      skip() {
        return !this.salesOrder.id
      }
    }
  },
  computed: {
    currentRoute() {
      return this.$route
    }
  },
  methods: {
    navToDetail(item) {
      var appName = this.currentRoute?.meta?.appName

      this.$router.push({
        name: appName + 'DeliveryNoteDetails',
        params: { deliveryNoteID: item.id }
      })
    }
  }
}
</script>

<style></style>
