<template>
  <span>{{ paymentTermFormat(term) }}</span>
</template>

<script>
export default {
  name: 'PaymentTermFormatter',
  props: {
    term: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  methods: {
    paymentTermFormat(item) {
      var paymentType

      switch (item.type) {
        case 'PREPAYMENT':
          paymentType = 'prepayment'
          break
        case 'DAYS':
          paymentType = item.numberOfDays + ' days after shipment'
          break
        case 'DATE':
          paymentType =
            'on ' + this.$moment(item.paymentDate).format('dd mmm yyyy')
          break
        case 'ENDOFMONTH':
          paymentType = 'end of month'
          break
        default:
          break
      }

      var result =
        item.percentage +
        '% ' +
        paymentType +
        ' by ' +
        item.paymentMethod.toLowerCase()

      return result
    }
  }
}
</script>

<style></style>
