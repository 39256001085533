<template>
  <span>{{ deliveryTermFormat(deliveryTerm) }}</span>
</template>

<script>
export default {
  name: 'DeliveryTermFormatter',
  props: {
    deliveryTerm: {
      type: Object,
      default: () => ({}),
      required: true
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  methods: {
    deliveryTermFormat(item) {
      var deliveryTermType

      switch (item.type) {
        case 'LUMPSUM':
          deliveryTermType = `${this.currencySymbol} ${item.amount.toFixed(2)}`
          break
        case 'PERCENTAGE':
          deliveryTermType = `${item.percentage}%`
          break
        case 'PERBOX':
          deliveryTermType = `${this.currencySymbol} ${item.amount.toFixed(
            2
          )} per box`
          break
        default:
          deliveryTermType = 'none'
          break
      }

      return deliveryTermType
    }
  }
}
</script>

<style></style>
