var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-layout"},[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header"},[_c('v-toolbar',{staticClass:"mb-3",attrs:{"color":"white","rounded":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"info--text text-truncate"},[_vm._v(" Sales Orders By Customer Report ")])])],1),_c('v-spacer'),_c('v-row',{staticStyle:{"height":"40px"},attrs:{"justify":"end","align":"center"}},[_c('span',{staticClass:"pr-3 text-truncate"},[_vm._v(_vm._s(_vm.itemCount)+" Rows")])])],1),_c('v-toolbar',{staticClass:"mb-0",attrs:{"color":"white","rounded":"","flat":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedFromDate,"label":"From","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","hide-details":"auto","autocomplete":"off"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.menu1 = false}},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formattedTillDate,"label":"Till","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","hide-details":"auto","autocomplete":"off"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"min":_vm.$moment(_vm.from)
                    .add(1, 'days')
                    .format('YYYY-MM-DD'),"no-title":"","scrollable":""},on:{"change":function($event){_vm.menu2 = false}},model:{value:(_vm.till),callback:function ($$v) {_vm.till=$$v},expression:"till"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.orderStates,"outlined":"","dense":"","label":"Status","color":"info","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","multiple":"","disabled":_vm.loading},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                    var index = ref.index;
return [(index === 0)?_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[(_vm.selectedOrderStates.length === _vm.orderStates.length)?_c('span',{staticClass:"grey--text text--darken1 text-truncate"},[_vm._v("All")]):_c('span',{staticClass:"grey--text text--darken1 text-truncate"},[_vm._v(_vm._s(_vm.selectedOrderStates.length)+" of "+_vm._s(_vm.orderStates.length))])]):_vm._e()]}}]),model:{value:(_vm.selectedOrderStates),callback:function ($$v) {_vm.selectedOrderStates=$$v},expression:"selectedOrderStates"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.orderTypes,"clearable":"","outlined":"","dense":"","label":"Order Type","color":"info","background-color":"white","prepend-inner-icon":"mdi-filter-variant","hide-details":"","autocomplete":"off","disabled":_vm.loading},model:{value:(_vm.selectedOrderType),callback:function ($$v) {_vm.selectedOrderType=$$v},expression:"selectedOrderType"}})],1),_c('v-col',{staticClass:"pl-sm-0 pl-xs-0 pr-sm-1 pr-xs-1",attrs:{"cols":"4"}},[_c('v-row',{staticStyle:{"height":"40px"},attrs:{"align":"center","justify":"end","no-gutters":""}},[(_vm.exportData.length > 0)?_c('div',[_c('xlsx-workbook',{staticStyle:{"width":"100%"}},[_c('xlsx-sheet',{attrs:{"collection":_vm.exportData,"sheet-name":"Sheet1"}}),_c('xlsx-download',{staticClass:"px-0 mx-0",attrs:{"filename":_vm.fileName}},[_c('v-btn',{staticClass:"text-capitalize font-weight-regular text-body-1",attrs:{"color":"info","small":"","rounded":""}},[_vm._v("Download Excel")])],1)],1)],1):_vm._e()])],1)],1)],1)],1),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-row',{attrs:{"justify":"center"}},[(_vm.reportData.length === 0)?_c('v-col',[_c('v-card',{attrs:{"flat":""}},[_c('message-box',{attrs:{"icon":_vm.loading ? '' : 'mdi-database-off-outline',"title":_vm.loading ? 'Loading...' : 'No Results',"caption":_vm.loading
                    ? 'Please sit back while we load your data'
                    : 'Try adjusting your filters to get some results'}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"info"}}):_vm._e()],1)],1)],1):_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.reportData,"items-per-page":-1,"hide-default-footer":"","mobile-breakpoint":null},scopedSlots:_vm._u([{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}])})],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }