<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col cols="12" sm="10" xl="8">
              <v-expansion-panels v-model="panel" flat multiple>
                <v-expansion-panel class="mb-1">
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Sales Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none"
                          :to="{ name: 'BarcodeSalesOrderReport' }"
                          >Sales Orders by Barcode</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="10"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of sales orders by barcode.
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none"
                          :to="{ name: 'CustomerSalesOrderReport' }"
                          >Sales Orders by Customer</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="10"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of sales orders by customer.
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none"
                          :to="{ name: 'OrderNumberSalesOrderReport' }"
                          >Sales Orders by Order Number</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="10"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of sales orders by order number.
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="mb-1">
                  <v-expansion-panel-header
                    ><h3 class="primary--text">
                      Stock Reports
                    </h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col>
                        <router-link
                          class="info--text text-decoration-none"
                          :to="{ name: 'StockLevelReport' }"
                          >Stock Level by Warehouse</router-link
                        >
                      </v-col>
                      <v-col
                        cols="7"
                        md="10"
                        class="grey--text text--darken-1 text-caption"
                      >
                        List of stock levels per warehouse.
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportList',
  components: {},
  data: () => ({ panel: [0, 1] }),
  // computed: {
  //   salesReportAuthLevel() {
  //     return this.$store.getters.authLevel('sales_reports')
  //   }
  // },
  apollo: {},
  methods: {}
}
</script>

<style></style>
