var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"color":"transparent","flat":""}},[_c('v-row',[(
          _vm.authLevel &&
            _vm.authLevel === 2 &&
            _vm.currentRoute.path.startsWith('/production')
        )?_c('v-col',{staticClass:"pl-0",attrs:{"cols":"6","lg":"2","xl":"2"}},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.editDialog = true}}},[_vm._v(" add product ")])],1):_vm._e(),_c('v-col',{class:[
          _vm.authLevel &&
          _vm.authLevel === 2 &&
          _vm.currentRoute.path.startsWith('/production')
            ? 'd-none d-sm-none d-md-none d-lg-flex'
            : 'd-none d-sm-none d-md-none d-lg-flex pl-0'
        ],attrs:{"cols":"7","lg":"3","xl":"2"}},[_c('season-switcher',{attrs:{"background-color":'#fff',"items":_vm.seasons,"prepend-inner-icon":'mdi-filter-variant',"disabled":!_vm.seasons || _vm.seasons.length === 0}})],1),_c('v-col',{staticClass:"d-none d-sm-none d-md-none d-lg-flex",attrs:{"cols":"3","lg":"3","xl":"2"}},[_c('v-select',{attrs:{"items":_vm.designers,"hide-details":"","outlined":"","flat":"","dense":"","clearable":"","background-color":"#fff","label":"designer","placeholder":"all","prepend-inner-icon":"mdi-filter-variant","autocomplete":"off","item-value":"id","disabled":!_vm.products ||
              (_vm.products.items.length === 0 && !_vm.users) ||
              _vm.users.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(subData){return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(subData.item.firstName + ' ' + subData.item.lastName)+" ")])]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}}]),model:{value:(_vm.selectedDesigner),callback:function ($$v) {_vm.selectedDesigner=$$v},expression:"selectedDesigner"}})],1),_c('v-col',{staticClass:"d-none d-sm-none d-md-none d-lg-flex",attrs:{"cols":"3","lg":"3","xl":"2"}},[_c('v-select',{attrs:{"hide-details":"","outlined":"","flat":"","dense":"","clearable":"","background-color":"#fff","label":"developer","placeholder":"all","prepend-inner-icon":"mdi-filter-variant","autocomplete":"off","item-value":"id","items":_vm.productDevelopers,"disabled":!_vm.products ||
              (_vm.products.items.length === 0 && !_vm.users) ||
              _vm.users.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(subData){return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(subData.item.firstName + ' ' + subData.item.lastName)+" ")])]}},{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}}]),model:{value:(_vm.selectedProducer),callback:function ($$v) {_vm.selectedProducer=$$v},expression:"selectedProducer"}})],1)],1),_c('v-spacer'),_c('v-row',{attrs:{"justify":"end"}},[_c('span',{staticClass:"pt-3 text-truncate"},[_vm._v(_vm._s(_vm.itemCount)+" Products")]),_c('v-btn',{staticClass:"d-sm-flex d-md-flex d-lg-none d-xl-none ml-2",attrs:{"icon":""},on:{"click":function($event){_vm.filters = !_vm.filters}}},[_c('v-icon',{attrs:{"color":_vm.filters ? 'info' : ''}},[_vm._v("mdi-filter-variant")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }