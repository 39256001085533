var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header"},[_c('product-list-toolbar',{attrs:{"auth-level":_vm.authLevel,"seasons":_vm.seasons.seasons,"loading":_vm.$apollo,"designers":_vm.designers,"product-developers":_vm.productDevelopers,"products":_vm.products,"users":_vm.users,"item-count":_vm.itemCount},on:{"set-filters":_vm.setFilters,"set-selected-designer":_vm.setSelectedDesigner,"set-selected-producer":_vm.setSelectedProducer}})],1),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container pt-0"},[(_vm.filters)?_c('v-card',{staticClass:"mb-3"},[_c('div',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('span',[_vm._v("Advanced Filters")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.filters = !_vm.filters}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('season-switcher',{attrs:{"background-color":'#fff',"items":_vm.seasons.seasons,"prepend-inner-icon":'mdi-filter-variant',"disabled":_vm.$apollo.queries.seasons.loading ||
                  !_vm.seasons ||
                  _vm.seasons.seasons.length === 0}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.designers,"hide-details":"","outlined":"","flat":"","dense":"","clearable":"","background-color":"#fff","label":"designer","placeholder":"all","prepend-inner-icon":"mdi-filter-variant","autocomplete":"off","item-value":"id","disabled":!_vm.products ||
                  (_vm.products.items.length === 0 && !_vm.users) ||
                  _vm.users.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(subData){return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(subData.item.firstName + ' ' + subData.item.lastName)+" ")])]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}}],null,false,2196333882),model:{value:(_vm.selectedDesigner),callback:function ($$v) {_vm.selectedDesigner=$$v},expression:"selectedDesigner"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"hide-details":"","outlined":"","flat":"","dense":"","clearable":"","background-color":"#fff","label":"producer","placeholder":"all","prepend-inner-icon":"mdi-filter-variant","autocomplete":"off","item-value":"id","items":_vm.productDevelopers,"disabled":!_vm.products ||
                  (_vm.products.items.length === 0 && !_vm.users) ||
                  _vm.users.length === 0},scopedSlots:_vm._u([{key:"selection",fn:function(subData){return [_c('div',{staticClass:"text-truncate",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(subData.item.firstName + ' ' + subData.item.lastName)+" ")])]}},{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.firstName + ' ' + item.lastName))])]}}],null,false,2196333882),model:{value:(_vm.selectedProducer),callback:function ($$v) {_vm.selectedProducer=$$v},expression:"selectedProducer"}})],1)],1)]):_vm._e(),_c('product-list-table',{attrs:{"loading":_vm.$apollo.loading,"products":_vm.products,"options":_vm.options,"server-items-length":_vm.itemCount},on:{"update:options":function($event){_vm.options=$event},"search":_vm.searchItems,"page-change":_vm.changePage,"nav-to-detail":_vm.navToDetail}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }