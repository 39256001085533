<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <v-card flat>
        <v-card-title class="subtitle-1">
          <v-row>
            <v-col>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search by product name..."
                single-line
                hide-details
                clearable
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="seasonID"
                :background-color="'#fff'"
                :items="seasons.seasons"
                :prepend-inner-icon="'mdi-filter-variant'"
                placeholder="Season"
                hide-details
                clearable
                outlined
                dense
                autocomplete="off"
                item-value="id"
                item-text="longName"
                :disabled="
                  $apollo.queries.seasons.loading ||
                    !seasons ||
                    seasons.seasons.length === 0
                "
              ></v-select>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <v-btn
            v-if="
              !$apollo.loading &&
                authLevel &&
                authLevel === 2 &&
                (productCustomers || productCustomers.length > 0) &&
                (!selected || selected.length === 0)
            "
            rounded
            small
            color="info"
            class="mr-3"
            :disabled="processing"
            @click="updateAll(true)"
            >Show All</v-btn
          >
          <v-btn
            v-if="
              !$apollo.loading &&
                authLevel &&
                authLevel === 2 &&
                (productCustomers || productCustomers.length > 0) &&
                (!selected || selected.length === 0)
            "
            rounded
            small
            color="info"
            :disabled="processing"
            @click="updateAll(false)"
            >Hide All</v-btn
          >
          <v-btn
            v-if="
              !$apollo.loading &&
                authLevel &&
                authLevel === 2 &&
                selected &&
                selected.length > 0
            "
            rounded
            small
            color="info"
            class="mr-3"
            :disabled="processing"
            @click="updateBulk(true)"
            >Show</v-btn
          >
          <v-btn
            v-if="
              !$apollo.loading &&
                authLevel &&
                authLevel === 2 &&
                selected &&
                selected.length > 0
            "
            rounded
            small
            color="info"
            :disabled="processing"
            @click="updateBulk(false)"
            >Hide</v-btn
          >
          <v-btn
            v-if="
              !$apollo.loading &&
                authLevel &&
                authLevel === 2 &&
                productCustomers
            "
            rounded
            small
            color="primary"
            class="ml-2"
            :loading="processing"
            @click="populate"
            >Repopulate</v-btn
          >
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="productCustomers.items"
          :search="search"
          :options.sync="options"
          :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
          :server-items-length="itemCount"
          sort-by="customer.storeName"
          fixed-header
          show-select
          :hide-default-header="
            !productCustomers || productCustomers.length === 0
          "
        >
          <template v-slot:item.product="data">
            <div class="text-truncate font-weight-normal">
              <div style="float:left" class="pr-3 mr-3">
                <v-img
                  style="border-radius: 4px"
                  width="40"
                  max-height="40"
                  :src="
                    data.item.product.frontImage &&
                    data.item.product.frontImage !== ''
                      ? data.item.frontImage
                      : '/DAILY_PAPER_LOGO.png'
                  "
                  lazy-src="/DAILY_PAPER_LOGO.png"
                ></v-img>
              </div>
              <div>
                <span>{{ data.item.product.name }}</span>
              </div>
            </div>
          </template>
          <template v-slot:item.customer.companyName="{ item }">
            <div
              v-if="
                item &&
                  item.customer.companyName &&
                  item.customer.storeName &&
                  item.customer.storeName != item.customer.companyName
              "
            >
              {{ item.customer.companyName }}
            </div>
          </template>
          <template v-slot:item.active="{ item }">
            <v-switch
              v-model="item.active"
              color="info"
              @click="update(item)"
            ></v-switch>
          </template>
          <template v-slot:loading>
            <v-skeleton-loader
              v-for="item in 5"
              :key="item"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import SEASONS from '@/graphql/Seasons.gql'
import PRODUCT_SUBSCRIPTION_LIST from '@/graphql/CustomerSubscriptions.gql'
import UPDATE_PRODUCTS_SUBSCRIPTIONS from '@/graphql/ProductSubscriptionsUpdate.gql'
import UPDATE_ALL_PRODUCT_SUBSCRIPTIONS from '@/graphql/ProductSubscriptionsAllUpdate.gql'
import POPULATE_PRODUCTS_SUBSCRIPTIONS from '@/graphql/CustomerProductSubscriptionsPopulate.gql'

export default {
  name: 'CustomerProductSubscriptionList',
  components: {},
  props: {
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    selected: [],
    search: null,
    seasonID: null,
    processing: false,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    productCustomers: [],
    seasons: { seasons: [] }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Product',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'product'
        },
        {
          text: 'Color',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'product.seasonColor'
        },
        {
          text: 'Visible',
          value: 'active',
          sortable: true,
          filterable: true
        }
      ]
    }
    // authLevel() {
    //   return this.$store.getters.authLevel('product_customers')
    // }
  },
  apollo: {
    seasons: SEASONS,
    productCustomers: {
      query: PRODUCT_SUBSCRIPTION_LIST,
      variables() {
        return {
          customerID: this.$route.params.customerID,
          search: this.search,
          seasonID: this.seasonID,
          page: this.options.page,
          pageSize: this.options.itemsPerPage
        }
      },
      update: data => data.findProductSubscriptionsByCustomerID || [],
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result?.data?.findProductSubscriptionsByCustomerID.page
        this.itemsPerPage =
          result?.data?.findProductSubscriptionsByCustomerID.pageSize
        this.itemCount =
          result?.data?.findProductSubscriptionsByCustomerID.total
      }
    }
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    populate() {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: POPULATE_PRODUCTS_SUBSCRIPTIONS,
          variables: {
            customerID: this.$route.params.customerID
          }
        })
        .then(() => {
          this.$apollo.queries.productCustomers.refetch()
          this.processing = false
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    update(item) {
      this.updateItems([item.customer.id], item.product.id, item.active)
    },
    updateBulk(active) {
      this._.forEach(this.selected, item => {
        this.updateItems([item.customer.id], item.product.id, active)
      })

      this.selected = []
    },
    updateItems(customers, productID, visible) {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: UPDATE_PRODUCTS_SUBSCRIPTIONS,
          variables: {
            input: {
              productID: productID,
              customers: customers,
              active: visible
            }
          }
        })
        .then(() => {
          this.$apollo.queries.productCustomers.refetch()
          this.processing = false
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    updateAll(visible) {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: UPDATE_ALL_PRODUCT_SUBSCRIPTIONS,
          variables: {
            input: {
              customerID: this.$route.params.customerID,
              seasonID: this.seasonID,
              active: visible
            }
          }
        })
        .then(() => {
          this.$apollo.queries.productCustomers.refetch()
          this.processing = false
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
