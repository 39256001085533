<template>
  <v-row>
    <add-to-cart-dialog
      :sales-order="salesOrder"
      :product="product"
      :stock-items="stockItems.items"
      :edited-item="editedItem"
      :dialog="dialog"
      :edit-mode="editMode"
      :auth-level="authLevel"
      :current-user="currentUser"
      :error-msg="errorMsg"
      :processing="processing"
      @close-add-to-cart-dialog="closeProductDialog"
      @save-add-to-cart-dialog="saveOrderLine"
    ></add-to-cart-dialog>
    <v-col cols="12" class="pt-0">
      <v-toolbar flat rounded>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              v-model="search"
              clearable
              dense
              outlined
              hide-details
              autocomplete="off"
              label="Search"
              placeholder="search..."
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" lg="2">
            <v-autocomplete
              v-model="articleGroupID"
              :items="filteredArticles"
              clearable
              dense
              outlined
              hide-details
              autocomplete="off"
              item-value="id"
              item-text="name"
              label="Article Group"
              placeholder="--filter--"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" lg="2">
            <v-autocomplete
              v-model="selectedSeasonID"
              :items="seasons.seasons"
              clearable
              dense
              outlined
              hide-details
              autocomplete="off"
              item-value="id"
              item-text="shortName"
              label="Season"
              placeholder="--filter--"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3" lg="2">
            <v-autocomplete
              v-model="selectedGender"
              :items="genders"
              clearable
              dense
              outlined
              hide-details
              autocomplete="off"
              label="Gender"
              placeholder="--filter--"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-col>

    <v-col cols="12" class="py-0">
      <div
        v-infinite-scroll="infiniteHandler"
        infinite-scroll-disabled="endOfList"
      >
        <v-data-iterator
          :items="products"
          :loading="$apollo.loading || loading || infiniteLoading"
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                v-for="(item, idx) in items"
                :key="idx"
                cols="12"
                md="4"
                lg="3"
                xl="2"
              >
                <v-card
                  flat
                  class="card-hover"
                  @click="openProductDialog(item.product.id, item.season.id)"
                >
                  <div class="d-flex">
                    <div class="px-3 py-0" style="width: 100px; height: 160px;">
                      <v-row align="stretch">
                        <v-col>
                          <v-img
                            style="border-radius: 4px"
                            center
                            :src="
                              item.product &&
                              item.product.frontImage &&
                              item.product.frontImage !== ''
                                ? item.product.frontImage
                                : '/DAILY_PAPER_LOGO.png'
                            "
                            lazy-src="/DAILY_PAPER_LOGO.png"
                          ></v-img>
                        </v-col>

                        <v-col class="pa-0">
                          <v-row class="mx-auto">
                            <v-col class="pt-1">
                              <!-- <active-inactive-label
                              :state="item.state"
                            ></active-inactive-label> -->
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                    <div class="pa-3 text-truncate">
                      <div class="text-caption text-truncate">
                        {{ item.product.name }}
                      </div>
                      <div class="text-caption text-truncate grey--text mt-3">
                        {{ item.product.styleNumber }}
                      </div>
                      <div class="text-caption text-truncate grey--text">
                        {{ item.product.seasonColor }}
                      </div>
                      <div class="text-caption text-truncate mt-3">
                        <span class="grey--text">Wholesale: &nbsp;</span>
                        <span
                          v-if="
                            item.product &&
                              item.product.prices[0] &&
                              item.product.prices[0].wholesalePrice
                          "
                          >{{
                            (item.product.prices[0].wholesalePrice / 100)
                              | currency(
                                item.product.prices[0].currency.symbol,
                                2,
                                {
                                  spaceBetweenAmountAndSymbol: true
                                }
                              )
                          }}</span
                        >
                      </div>
                      <div class="text-caption text-truncate">
                        <span class="grey--text">RRP: &nbsp;</span>
                        <span
                          v-if="
                            item.product &&
                              item.product.prices[0] &&
                              item.product.prices[0].retailPrice
                          "
                          >{{
                            (item.product.prices[0].retailPrice / 100)
                              | currency(
                                item.product.prices[0].currency.symbol,
                                2,
                                {
                                  spaceBetweenAmountAndSymbol: true
                                }
                              )
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-slot:loading>
            <v-row>
              <v-col v-for="i in 10" :key="i" cols="12" md="2">
                <v-sheet color="grey lighten-4" class="pa-3">
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="300"
                    type="card"
                  ></v-skeleton-loader>
                </v-sheet>
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            <v-row v-if="infiniteLoading">
              <v-col v-for="i in 10" :key="i" cols="12" md="2">
                <v-sheet color="grey lighten-4" class="pa-3">
                  <v-skeleton-loader
                    class="mx-auto"
                    max-width="300"
                    type="card"
                  ></v-skeleton-loader>
                </v-sheet>
              </v-col>
            </v-row>
            <message-box
              v-else
              icon="mdi-emoticon-neutral-outline"
              title="No Products"
              :caption="
                'There are no products available to shop for this order'
              "
            >
            </message-box>
          </template>
        </v-data-iterator>
      </div>

    </v-col>
    <v-col v-if="infiniteLoading" cols="12">
      <div class="mt-10 p-10">
        <v-alert outlined class="text-center" color="primary">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <span class="pl-5">Loading more</span>
        </v-alert>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import MessageBox from '@/components/core/MessageBox.vue'
import AddToCartDialog from '../components/core/AddToCartDialog.vue'
import PRODUCT_SUBSCRIPTION_LIST from '@/graphql/CustomerShopProducts.gql'
import SEASON_PRODUCT from '@/graphql/SeasonProduct.gql'
import STOCK_ITEM_LIST from '@/graphql/StockItems.gql'
import SALES_ORDER_LINE_CREATE from '@/graphql/SalesOrderLineCreate.gql'
import SALES_ORDER_LINE_UPDATE from '@/graphql/SalesOrderLineUpdate.gql'

export default {
  name: 'CustomerProductShop',
  components: { AddToCartDialog, MessageBox },
  directives: { infiniteScroll },
  props: {
    seasons: {
      type: Object,
      default: () => {
        return { seasons: [] }
      }
    },
    salesOrder: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    articleGroups: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  apollo: {
    subscriberProducts: {
      query: PRODUCT_SUBSCRIPTION_LIST,
      variables() {
        return {
          customerID: this.salesOrder.customer.id,
          search: this.search,
          page: this.productPage,
          pageSize: this.pageSize,
          seasonID: this.selectedSeasonID,
          articleGroupID: this.articleGroupID,
          gender: this.selectedGender,
          visible: this.salesOrder.type === 'RETURN' ? false : true,
          prebook: this.salesOrder.type === 'PREBOOK' ? true : false,
          reorder: this.salesOrder.type === 'REORDER' ? true : false
        }
      },
      debounce: 200,
      update: data => data.findProductSubscriptionsByCustomerID || [],
      result(result) {
        this.products = this._.cloneDeep(
          result.data?.findProductSubscriptionsByCustomerID?.items
        )


        if (result.data?.findProductSubscriptionsByCustomerID?.items?.length === 0) {
          this.endOfList = true
        }

        this.initial = false
      },
      skip() {
        return !this.salesOrder.id || this.products?.length > 0 || !this.initial
      },
      fetchPolicy: 'no-cache'
    }
  },
  data() {
    return {
      initial: true,
      infiniteLoading: false,
      endOfList: false,
      dialog: false,
      errorMsg: '',
      processing: false,
      search: null,
      articleGroupID: null,
      selectedSeasonID: null,
      selectedGender: null,
      genders: ['MALE', 'FEMALE', 'KIDS', 'BABY'],
      editMode: false,
      subscriberProducts: [],
      products: [],
      editedItem: {},
      product: {},
      stockItems: {},
      productPage: 1,
      pageSize: 50
    }
  },
  computed: {
    filteredArticles() {
      return this._.orderBy(this.articleGroups, ['name'])
    }
  },
  watch: {
    search(val) {
      this.endOfList = false
      this.initial = true
      this.products = []
      this.$store.commit('sales/SET_CUSTOMER_PRODUCT_SEARCH', val)
      this.productPage = 1
      // this.infiniteHandler()
    },
    selectedSeasonID(val) {
      this.endOfList = false
      this.initial = true
      this.products = []
      this.$store.commit('sales/SET_CUSTOMER_PRODUCT_SEASON', val)
      this.productPage = 1
    },
    articleGroupID(val) {
      this.endOfList = false
      this.initial = true
      this.products = []
      this.$store.commit('sales/SET_CUSTOMER_PRODUCT_ARTICLE_GROUP', val)
      this.productPage = 1
    },
    selectedGender(val) {
      this.endOfList = false
      this.initial = true
      this.products = []
      this.$store.commit('sales/SET_CUSTOMER_PRODUCT_GENDER', val)
      this.productPage = 1
    }
  },
  created() {
    var that = this
    that.productPage = 1

    // Apply state filters
    var filters = this.$store.state.sales.customerShopPage
    that.selectedSeasonID = filters.season
    that.articleGroupID = filters.articleGroup
    that.selectedGender = filters.gender

    if (filters.search !== '') that.search = filters.search
  },
  methods: {
    infiniteHandler() {
      if (this.salesOrder?.customer?.id && !this.initial) {
        if (this.productPage >= 1) {
          this.productPage++
        }

        this.infiniteLoading = true

        this.$apollo
          .query({
            query: PRODUCT_SUBSCRIPTION_LIST,
            variables: {
              customerID: this.salesOrder.customer.id,
              search: this.search,
              page: this.productPage,
              pageSize: this.pageSize,
              seasonID: this.selectedSeasonID,
              articleGroupID: this.articleGroupID,
              gender: this.selectedGender,
              visible: this.salesOrder.type === 'RETURN' ? false : true,
              prebook: this.salesOrder.type === 'PREBOOK' ? true : false,
              reorder: this.salesOrder.type === 'REORDER' ? true : false
            }
          })
          .then(result => {
            if (
              result.data?.findProductSubscriptionsByCustomerID?.items?.length >
              0
            ) {
              this.products.push(
                ...result.data.findProductSubscriptionsByCustomerID.items
              )
            } else {
              this.endOfList = true
            }

            if (result.data?.findProductSubscriptionsByCustomerID?.total === 0) {
              this.endOfList = true
            }

            this.infiniteLoading = false

          })
          .catch(() => (this.infiniteLoading = false))
      }
    },
    openProductDialog(productID, season) {
      var existing = this._.find(
        this.salesOrder.lines,
        item => item.product?.id === productID
      )

      if (!existing) {
        this.$apollo
          .query({
            query: SEASON_PRODUCT,
            variables: {
              id: productID,
              seasonID: season
            }
          })
          .then(result => {
            this.product = this._.cloneDeep(result.data.seasonProduct)
            this.editedItem = this.createNewProductLineModel(
              result.data.seasonProduct
            )
            this.dialog = true
          })
      } else {
        this.editMode = true

        this.product = existing?.product

        this.editedItem = existing

        this.dialog = true
      }

      this.$apollo
        .query({
          query: STOCK_ITEM_LIST,
          variables: {
            filters: {
              nameSearch: null,
              warehouseID: this.salesOrder.warehouse?.id,
              productID: productID,
              page: 1,
              pageSize: 100
            }
          }
        })
        .then(result => {
          this.stockItems = result.data.stockItems
          this.page = result.data.stockItems.page
          this.itemsPerPage = result.data.stockItems.pageSize
          this.itemCount = result.data.stockItems.total
        })
    },
    createNewProductLineModel(input) {
      var lines = []

      input.sizes.forEach((item, i) => {
        lines.push({
          id: i,
          barcode: item.barcode.toString(),
          productSizeID: item.id,
          name: item.name,
          quantity: 0
        })
      })

      var defaultPrice = this._.find(input.prices, item => {
        return item.currency?.defaultCurrency
      })

      var data = {
        salesOrderID: this.$route.params.salesOrderID,
        seasonProductID: input.seasonProductID,
        styleNumber: input.styleNumber,
        quantity: 0,
        wholesalePrice: defaultPrice?.wholesalePrice,
        retailPrice: defaultPrice?.retailPrice,
        net: 0,
        notes: '',
        currencyID: defaultPrice?.currency?.id,
        currencySymbol: defaultPrice?.currency?.symbol,
        currencyISO: defaultPrice?.currency?.isoName,
        exchangeRate:
          defaultPrice?.currency?.exchangeRate ||
          defaultPrice?.currency?.productionExchangeRate,
        items: lines
      }

      return data
    },
    closeProductDialog() {
      this.editedItem = this._.cloneDeep({})
      this.editMode = false
      this.errorMsg = ''
      this.dialog = false
      this.processing = false
    },
    customFilter(items, search) {
      if (search) {
        items = this._.filter(items, function(o) {
          let name = o.product.name.toLowerCase()
          let styleNumber = o.product.styleNumber.toLowerCase()
          let seasonShort = o.season?.shortName.toLowerCase()
          let seasonLong = o.season?.longName.toLowerCase()
          return (
            name.includes(search) ||
            styleNumber.includes(search) ||
            seasonShort?.includes(search) ||
            seasonLong?.includes(search)
          )
        })
      }

      if (this.articleGroupID) {
        items = this._.filter(
          items,
          r => r.product.articleGroup.id === this.articleGroupID
        )
        // filtered.filter(r => r.product.articleGroup.id === this.articleGroupID)
      }

      if (this.selectedSeasonID) {
        items = this._.filter(items, r => r.season.id === this.selectedSeasonID)
        // filtered.filter(r => r.product.articleGroup.id === this.articleGroupID)
      }

      if (this.selectedGender) {
        items = this._.filter(
          items,
          r => r.product.gender === this.selectedGender
        )
      }

      return items
    },
    saveOrderLine(item) {
      this.errorMsg = ''
      this.processing = true

      if (!this.editMode) {
        this.$apollo
          .mutate({
            mutation: SALES_ORDER_LINE_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refresh-sales-order')
            this.closeProductDialog()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Product Added'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: SALES_ORDER_LINE_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refresh-sales-order')
            this.closeProductDialog()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Order Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card-hover {
  // -webkit-box-shadow: none !important;
  // box-shadow: none !important;
  border: 2px solid rgb(229, 237, 244);
  // &:hover {
  //   cursor: pointer;
  //   -webkit-box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%),
  //     0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
  //   box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%),
  //     0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
  // }
}
</style>
