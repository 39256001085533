<template>
  <v-card flat>
    <v-card-title class="pt-0">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        label="Search By Name..."
        single-line
        hide-details
        clearable
        autocomplete="off"
        :disabled="!products"
      ></v-text-field>
    </v-card-title>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :loading="loading"
      :items="products.items"
      :options.sync="options"
      :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
      :server-items-length="serverItemsLength"
      :mobile-breakpoint="null"
      :hide-default-footer="!products || serverItemsLength < options.itemsPerPage + 1"
      class="plain-table"
      @click:row="navToDetail"
    >
      <template v-slot:item.frontImage="{ item }">
        <div class="text-truncate font-weight-normal">
          <v-img
            style="border-radius: 4px"
            width="40"
            max-height="40"
            :src="
              item.frontImage && item.frontImage !== ''
                ? item.frontImage
                : '/DAILY_PAPER_LOGO.png'
            "
            lazy-src="/DAILY_PAPER_LOGO.png"
          ></v-img>
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="text-truncate font-weight-normal">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:item.styleState="{ item }">
        <div class="text-truncate">
          <v-chip
            pill
            x-small
            :color="styleState(item.styleState) + ' upperCaseSpacing'"
            :text-color="styleText(item.styleState)"
          >
            {{ item.styleState }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.productState="{ item }">
        <div class="text-truncate">
          <v-chip
            pill
            x-small
            :color="styleState(item.productState) + ' upperCaseSpacing'"
            :text-color="styleText(item.productState)"
          >
            {{ item.productState }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.category="{ item }">
        <div class="text-truncate">
          <!-- <v-chip
                  v-if="item.category && item.category.id"
                  color="info lighten-2"
                  text-color="info darken-2"
                  outlined
                  x-small
                  pill
                  >{{ item.category.name }}</v-chip
                > -->
          <span>{{ item.category.name }}</span>
        </div>
      </template>
      <!-- <template v-slot:item.color="{ item }">
              <div class="text-truncate primary--text">
                <span v-if="item.color.id"
                  >{{ item.color.name }} ({{ item.color.colorCode }})</span
                >
              </div>
            </template> -->
      <template v-slot:item.designers="{ item }">
        <div
          v-if="item.designers && item.designers.length > 0"
          class="text-truncate"
        >
          <template v-for="(designer, index) in item.designers">
            <!-- <v-chip
                    v-if="index < 1"
                    :key="designer.id"
                    pill
                    x-small
                    color="secondary lighten-2 font-weight-bold"
                    text-color="secondary darken-2"
                    class="mr-2"
                    >
                    <v-avatar left style="margin-left: -20px">
                      <v-icon small>mdi-account-circle</v-icon>
                    </v-avatar> -->
            <span v-if="index < 1" :key="designer.id" class="mr-2">{{
              designer.firstName + ' ' + designer.lastName
            }}</span>
            <!-- </v-chip
                  > -->
            <span v-if="index > 0" :key="designer.id" class="grey--text caption"
              >(+{{ item.designers.length - 1 }} others)</span
            >
          </template>
        </div>
      </template>
      <template v-slot:no-data>
        <message-box
          icon="mdi-emoticon-neutral-outline"
          title="No Results"
          caption="Try adjusting your filters to get some results"
        ></message-box>
      </template>
      <template v-slot:loading>
        <v-skeleton-loader
          v-for="item in 5"
          :key="item"
          type="list-item"
          class="mx-auto"
        ></v-skeleton-loader>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'

export default {
  name: 'ProductListTable',
  components: { MessageBox },
  props: {
    authLevel: {
      type: Number,
      default: 0
    },
    seasons: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    products: {
      type: Object,
      default: () => ({})
    },
    serverItemsLength: {
      type: Number,
      default: 0
    },
    selectedSeason: {
      type: String,
      default: null
    },
    itemsPerPage: {
      type: Number,
      default: 15
    },
    backNav: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    search: null,
    selected: [],
    options: { page: 1, itemsPerPage: 15 }
  }),
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'frontImage'
        },

        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Style Number',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'styleNumber'
        },
        {
          text: 'Color',
          value: 'seasonColor',
          sortable: false,
          filterable: true
        },
        {
          text: 'Composition',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'textileContent'
        },
        {
          text: 'Style Status',
          align: 'left',
          value: 'styleState',
          sortable: false,
          filter: this.stateFilterValue
        },
        // {
        //   text: 'Product Status',
        //   align: 'left',
        //   value: 'productState',
        //   sortable: false,
        //   filter: this.stateFilterValue
        // },
        {
          text: 'Gender',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'gender'
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
          filterable: true
        }
        // {
        //   text: 'Designer',
        //   value: 'designers',
        //   sortable: false,
        //   filterable: true
        // }
      ]
    }
  },
  watch: {
    search(val) {
      // if (!oldVal || oldVal === '') {
      this.options.page = 1
      // }
      this.$emit('search', val)
    },
    itemsPerPage(val) {
      this.options.itemsPerPage = val
    },
    options: {
      deep: true,
      handler(val) {
        this.$emit('page-change', val)
      }
    }
  },
  created() {
    var that = this
    var filters = this.$store.state.production.productsPage

    window.onpopstate = function() {
      that.search = filters.search
      that.options = {
        page: filters.page || 1,
        itemsPerPage: filters.pageSize || 15
      }
    }
  },
  methods: {
    styleState(state) {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'grey lighten-2 font-weight-bold'
          break
        case 'REVIEW':
          color = 'warning lighten-1 font-weight-bold'
          break
        case 'FINAL':
          color = 'info lighten-2 font-weight-bold'
          break
        default:
          color = 'success lighten-3 font-weight-bold'
          break
      }
      return color
    },
    styleText(state) {
      var color
      switch (state) {
        case 'CANCELED':
          color = 'grey darken-1'
          break
        case 'REVIEW':
          color = 'warning darken-3'
          break
        case 'FINAL':
          color = 'info darken-1'
          break
        default:
          color = 'success'
          break
      }
      return color
    },
    navToDetail(item) {
      this.$emit('nav-to-detail', item)
    }
  }
}
</script>

<style scoped>
.v-data-footer {
  min-height: 59px;
}
/* div.v-data-footer__select {
  display: none !important;
} */
</style>
