var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0"},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search By Name...","single-line":"","hide-details":"","clearable":"","autocomplete":"off","disabled":!_vm.products},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"plain-table",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.products.items,"options":_vm.options,"footer-props":{'items-per-page-options':[15, 30, 50, 100]},"server-items-length":_vm.serverItemsLength,"mobile-breakpoint":null,"hide-default-footer":!_vm.products || _vm.serverItemsLength < _vm.options.itemsPerPage + 1},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.frontImage",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-truncate font-weight-normal"},[_c('v-img',{staticStyle:{"border-radius":"4px"},attrs:{"width":"40","max-height":"40","src":item.frontImage && item.frontImage !== ''
              ? item.frontImage
              : '/DAILY_PAPER_LOGO.png',"lazy-src":"/DAILY_PAPER_LOGO.png"}})],1)]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate font-weight-normal"},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.styleState",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-chip',{attrs:{"pill":"","x-small":"","color":_vm.styleState(item.styleState) + ' upperCaseSpacing',"text-color":_vm.styleText(item.styleState)}},[_vm._v(" "+_vm._s(item.styleState)+" ")])],1)]}},{key:"item.productState",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('v-chip',{attrs:{"pill":"","x-small":"","color":_vm.styleState(item.productState) + ' upperCaseSpacing',"text-color":_vm.styleText(item.productState)}},[_vm._v(" "+_vm._s(item.productState)+" ")])],1)]}},{key:"item.category",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"text-truncate"},[_c('span',[_vm._v(_vm._s(item.category.name))])])]}},{key:"item.designers",fn:function(ref){
              var item = ref.item;
return [(item.designers && item.designers.length > 0)?_c('div',{staticClass:"text-truncate"},[_vm._l((item.designers),function(designer,index){return [(index < 1)?_c('span',{key:designer.id,staticClass:"mr-2"},[_vm._v(_vm._s(designer.firstName + ' ' + designer.lastName))]):_vm._e(),(index > 0)?_c('span',{key:designer.id,staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(item.designers.length - 1)+" others)")]):_vm._e()]})],2):_vm._e()]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Results","caption":"Try adjusting your filters to get some results"}})]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }