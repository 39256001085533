<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="main-page-column pr-4">
        <div class="scroll-container pt-0">
          <v-card flat>
            <v-card-title class="pt-0">
              <v-text-field
                v-model="search"
                hide-details
                flat
                clearable
                background-color="#fff"
                placeholder="Search..."
                prepend-inner-icon="mdi-magnify"
                autocomplete="off"
              ></v-text-field>
            </v-card-title>

            <v-data-table
              v-model="selected"
              :loading="$apollo.loading"
              :headers="headers"
              :items="pickTickets.items"
              :options.sync="options"
              :server-items-length="itemCount"
              :mobile-breakpoint="null"
              fixed-header
              class="plain-table"
              :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
              :hide-default-header="
                !$apollo.loading &&
                  !search &&
                  (!pickTickets ||
                    !pickTickets.items ||
                    pickTickets.items.length === 0)
              "
              :hide-default-footer="
                !pickTickets || itemCount < options.itemsPerPage + 1
              "
              @click:row="navToDetail"
            >
              <template v-slot:item.createdAt="{ item }">
                <span v-if="item.createdAt" class="text-no-wrap">{{
                  item.createdAt | moment('LLL')
                }}</span>
              </template>

              <template v-slot:item.state="{ item }">
                <div>
                  <v-chip
                    v-if="item.state === 'SCHEDULED'"
                    x-small
                    color="success lighten-3 font-weight-medium"
                    text-color="success"
                    >{{ item.state }}</v-chip
                  >
                  <v-chip
                    v-if="item.state === 'SENT'"
                    x-small
                    color="info lighten-2 font-weight-medium"
                    text-color="info darken-1"
                    >{{ item.state }}</v-chip
                  >
                  <v-chip
                    v-if="item.state === 'CLOSED'"
                    x-small
                    color="warning lighten-1 font-weight-medium"
                    text-color="warning darken-3"
                    >{{ item.state }}</v-chip
                  >
                </div>
              </template>

              <template v-slot:item.deliveryStartDate="{ item }">
                <span>{{ item.deliveryStartDate | moment('DD MMM YY') }} </span>
                <span v-if="item.deliveryStartDate && item.deliveryEndDate"
                  >&nbsp;-&nbsp;</span
                >
                <span>{{ item.deliveryEndDate | moment('DD MMM YY') }} </span>
              </template>

              <template v-slot:no-data>
                <message-box
                  icon="mdi-emoticon-neutral-outline"
                  title="No Pick Tickets"
                  :caption="'Try adjusting your search to get some results'"
                >
                </message-box>
              </template>
              <template v-slot:loading>
                <v-skeleton-loader
                  v-for="item in 5"
                  :key="item"
                  type="list-item"
                  class="mx-auto"
                ></v-skeleton-loader>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PICK_TICKET_LIST from '@/graphql/PickTickets.gql'

export default {
  name: 'SalesPickTicketList',
  components: {},
  props: {
    salesOrder: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    search: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    selected: [],
    pickTickets: { items: [] },
    warehouses: { items: [] },
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    warehouseID: null,
    defaultItem: {
      id: null,
      stockChange: 0
    },
    editedItem: {
      id: null,
      stockChange: 0
    },
    headers: [
      {
        text: 'Ticket No.',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'pickTicketSerial'
      },
      {
        text: '',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'state'
      },
      {
        text: 'Order No.',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'salesOrder.orderNumber'
      },
      {
        text: 'Customer',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'salesOrder.customer.storeName'
      },
      {
        text: 'Order Type',
        sortable: false,
        filterable: true,
        value: 'salesOrder.type'
      },
      {
        text: 'Expected Delivery',
        sortable: false,
        filterable: true,
        value: 'deliveryStartDate'
      },
      {
        text: 'Created On',
        sortable: false,
        filterable: true,
        value: 'createdAt'
      }
      // {
      //   text: '',
      //   value: 'controls',
      //   sortable: false,
      //   filterable: true
      // }
    ],
  }),
  apollo: {
    pickTickets: {
      query: PICK_TICKET_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      // fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.pickTickets.page
        this.itemsPerPage = result.data.pickTickets.pageSize
        this.itemCount = result.data.pickTickets.total
      }
    }
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  computed: {},
  methods: {
    navToDetail(item) {
      this.$router.push({
        name: 'SalesPickTicketDetails',
        params: { pickTicketID: item.id }
      })
    }
  }
}
</script>

<style></style>
