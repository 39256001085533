<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container">
          <v-form
            ref="deliveryTermForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="item.type"
                      :items="termTypes"
                      label="Type"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredSelectRule"
                    >
                    </v-select>
                  </v-col>

                  <v-col v-if="item.type === 'PERCENTAGE'" cols="12">
                    <v-text-field
                      v-model="item.percentage"
                      outlined
                      dense
                      hide-details="auto"
                      suffix="%"
                      :rules="requiredRule"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="
                      (item.type === 'LUMPSUM' || item.type === 'PERBOX') &&
                        item.type !== 'NONE'
                    "
                    cols="12"
                  >
                    <v-row>
                      <!-- <v-col cols="3" class="py-0">
                        <v-select
                          v-model="item.currencyID"
                          :items="currencies"
                          :rules="requiredSelectRule"
                          :disabled="editMode"
                          outlined
                          autocomplete
                          return-object
                          item-text="isoName"
                          item-value="id"
                          hide-details="auto"
                          dense
                        ></v-select>
                      </v-col> -->
                      <v-col cols="12" class="py-0">
                        <v-currency-field
                          v-model="item.amount"
                          :prefix="defaultCurrency.symbol"
                          label="Amount"
                          autocomplete="off"
                          outlined
                          :rules="requiredRule"
                          hide-details="auto"
                          dense
                        ></v-currency-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.description"
                      label="Description"
                      outlined
                      dense
                      rows="3"
                      hide-details="auto"
                    >
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeliveryTermEditDialog',
  props: {
    deliveryTerm: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currencies: {
      type: Array,
      required: true,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    termTypes: ['NONE', 'LUMPSUM', 'PERCENTAGE', 'PERBOX'],
    item: null
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Delivery Term' : 'New Delivery Term'
    },
    defaultCurrency() {
      return this._.find(this.currencies, item => item.defaultCurrency)
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.deliveryTerm)
    }
  },
  methods: {
    close() {
      this.$refs.deliveryTermForm.reset()
      this.$emit('close-delivery-term-edit-dialog', false)

      // this.$nextTick(() => {
      // this.$refs.deliveryTermForm.resetValidation()
      // })
    },
    save() {
      if (this.$refs.deliveryTermForm.validate()) {
        var data = {
          id: this.item.id,
          agreementID: this.item.agreementID,
          currencyID: this.item.currencyID,
          percentage: this.item.percentage,
          description: this.item.description,
          amount: this.item.amount ? this.item.amount : 0,
          type: this.item.type
        }

        if (!this.editMode) {
          delete data.id
        }

        if (data.type === 'PERCENTAGE') {
          data.amount = 0
        }

        if (data.type !== 'PERCENTAGE') {
          data.percentage = 0
        }

        if (data.type === 'NONE') {
          data.percentage = 0
          data.amount = 0
        }

        this.$emit('save-delivery-term-edit-dialog', data)
      }
    }
  }
}
</script>

<style></style>
