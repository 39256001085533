<template>
  <v-row
    v-if="salesOrder && salesOrder.id"
    no-gutters
    class="page-wrap
  d-flex flex-wrap flex-sm-wrap flex-md-nowrap flex-lg-nowrap flex-xl-nowrap"
  >
    <v-dialog v-model="submitDialog" persistent max-width="450">
      <v-card>
        <v-card-title class="headline primary--text mb-3"
          >Submit Order</v-card-title
        >
        <div v-if="errorMsg !== ''" class="px-3">
          <v-alert type="error" dense>
            {{ errorMsg }}
          </v-alert>
        </div>
        <v-card-text>
          <p v-if="salesOrder.customer.state === 'ACTIVE'">
            This will submit the sales order for approval to a manager.
          </p>
          <v-alert v-else dense border="left" type="info">
            This Customer doesn't have a valid Customer and/or Seasonal
            Agreement.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="grey darken-1"
            :disabled="processing"
            text
            @click="close"
            >Cancel</v-btn
          >
          <v-btn
            v-if="salesOrder.customer.state === 'ACTIVE'"
            color="primary"
            small
            rounded
            :loading="processing"
            @click="submitOrder"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col
      cols="12"
      md="8"
      lg="9"
      xl="9"
      class="pa-0 order-2 order-md-0 flex-grow-1 bkground"
    >
      <div class="scroll-container pa-0" style="overflow-x: hidden">
        <div class="pr-4">
          <v-row v-if="zeroQtyLines">
            <v-col cols="12">
              <v-alert type="info">
                You have items with zero quantity in your cart.
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="line in salesOrder.lines"
              :key="line.id"
              cols="12"
              md="4"
              xl="3"
            >
              <v-card flat class="card-bordered-alt">
                <div class="d-flex">
                  <div>
                    <v-img
                      v-if="line.product.frontImage"
                      height="75"
                      width="75"
                      contain
                      :src="line.product.frontImage"
                    ></v-img>

                    <span v-else>
                      <v-img
                        height="75"
                        max-width="50"
                        contain
                        class="mx-3"
                        src="/DAILY_PAPER_LOGO.png"
                        lazy-src="/DAILY_PAPER_LOGO.png"
                      ></v-img>
                    </span>
                  </div>

                  <div class="pt-2 px-4 text-caption text-truncate">
                    <div class=" text-upppercase text-truncate">
                      {{ line.product.name }}
                    </div>
                    <div class="text-truncate grey--text text--darken-2">
                      {{ line.product.styleNumber }}
                    </div>
                    <div class="text-truncate pt-1">
                      {{ unitCount(line.items) }} Units
                    </div>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>

    <v-col
      cols="12"
      md="4"
      lg="3"
      xl="3"
      class="pa-0 order-0 order-md-1 flex-grow-1 bkground"
    >
      <div class="scroll-container pa-0" style="overflow-x: hidden">
        <v-row>
          <div class="py-3">
            <v-alert v-if="(!salesOrder.billingAddress ||
                      salesOrder.billingAddress.line1 === '') ||
                      (!salesOrder.shippingAddress ||
                      salesOrder.shippingAddress.line1 === '')" type="warning" text outlined border="left" class="mb-0">
              Billing and/or Shipping address not supplied
            </v-alert>
          </div>
          <v-col cols="12">
            <v-card flat class="mb-1 pa-5">
              <v-row class="px-3">
                <span class="subtitle-1 grey--text">Order #</span
                ><span class="subtitle-1 ml-1">{{
                  salesOrder.orderNumber
                }}</span>
                <v-spacer></v-spacer>
                <sales-order-state-label
                  :state="salesOrder.state"
                  x-small
                ></sales-order-state-label>
              </v-row>
              <v-row v-if="salesOrder.orderDate">
                <v-col>
                  <div class="caption grey--text">Customer Ref / PO</div>
                  <div>
                    <span>{{ salesOrder.customerPoNumber }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="salesOrder.orderDate">
                <v-col>
                  <div class="caption grey--text">Order Date</div>
                  <div>
                    <span>{{
                      salesOrder.orderDate | moment('DD MMM YYYY')
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <div class="caption grey--text">Delivery Dates</div>
                  <div>
                    {{ salesOrder.deliveryStartDate | moment('DD MMM YYYY') }}
                    <span
                      v-if="
                        salesOrder.deliveryStartDate &&
                          salesOrder.deliveryStartDate
                      "
                    >
                      -
                    </span>
                    {{ salesOrder.deliveryEndDate | moment('DD MMM YYYY') }}
                  </div>
                </v-col>
              </v-row>
              <v-row justify="end">
                <v-btn
                  v-if="
                    (salesOrder.state === 'DRAFT' ||
                      salesOrder.state === 'SUBMITTED') &&
                      salesOrder.lines &&
                      salesOrder.lines.length > 0 &&
                      salesOrder.billingAddress &&
                      salesOrder.billingAddress.line1 !== '' &&
                      salesOrder.shippingAddress &&
                      salesOrder.shippingAddress.line1 !== '' &&
                      ((authLevel && authLevel === 2) ||
                        (currentUser &&
                          (currentUser.isAdmin ||
                            currentUser.isSuperAdmin ||
                            currentUser.userRole === 'manager' ||
                            currentUser.userRole === 'seniormanager' ||
                            currentUser.userRole === 'director')))
                  "
                  color="info"
                  small
                  rounded
                  :disabled="zeroQtyLines"
                  @click="submitDialog = true"
                  >Submit Order</v-btn
                >

                <v-btn
                  v-if="
                    salesOrder.state === 'DRAFT' && authLevel && authLevel === 2
                  "
                  color="grey"
                  class="mx-3"
                  text
                  small
                  rounded
                  @click="removeDialog = true"
                  >Remove</v-btn
                >
              </v-row>
            </v-card>

            <v-card v-if="salesOrder.lines" flat class="mb-1 px-5 py-3">
              <v-row>
                <v-col class="py-0">
                  <h3 class="pt-2">
                    <span>{{ salesOrder.lines.length }}</span>
                    <span class="text-caption grey--text"> Products</span>
                  </h3>
                </v-col>
                <v-col class="py-0">
                  <h3 class="pt-2">
                    <span>{{ totalUnitCount() }}</span>
                    <span class="caption grey--text"> Units</span>
                  </h3>
                </v-col>
              </v-row>
            </v-card>
            <v-card flat class="mb-1 px-5 py-3">
              <v-row>
                <v-col class="pb-0">
                  <div class="caption grey--text">Sub Total</div>
                </v-col>
                <v-col class="pb-0 text-right grey--text text--darken-1">
                  <h4>
                    <span>{{
                      (salesOrder.subTotal / 100)
                        | currency(salesOrder.currencySymbol, 2, {
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}</span>
                  </h4>
                </v-col>
              </v-row>

              <v-row class="pb-4">
                <v-col class="pb-0">
                  <div class="caption grey--text">
                    Discount {{ salesOrder.discountPercentage.toFixed(2) }}%
                  </div>
                </v-col>
                <v-col class="pb-0 text-right grey--text text--darken-1">
                  <h4>
                    <span>{{
                      (salesOrder.discount / 100)
                        | currency(salesOrder.currencySymbol, 2, {
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}</span>
                  </h4>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pb-0">
                  <div>Total</div>
                </v-col>
                <v-col class="pb-0 text-right info--text">
                  <h3>
                    <span>{{
                      (salesOrder.net / 100)
                        | currency(salesOrder.currencySymbol, 2, {
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}</span>
                  </h3>
                </v-col>
              </v-row>
            </v-card>
            <v-card flat class="mb-1 px-5 py-3">
              <v-card-subtitle class="px-0 font-weight-bold">
                Billing Address
              </v-card-subtitle>
              <v-card-text class="px-0">
                <v-row no-gutters>
                  <v-col class="py-0" cols="12">
                    <div>
                      {{ salesOrder.billingAddress.line1 }}
                    </div>
                    <div>
                      {{ salesOrder.billingAddress.line2 }}
                    </div>
                    <div>
                      {{ salesOrder.billingAddress.line3 }}
                    </div>
                    <div>
                      {{ salesOrder.billingAddress.city }}
                    </div>
                    <div>
                      {{ salesOrder.billingAddress.postCode }}
                    </div>
                    <div>
                      {{ salesOrder.billingAddress.state }}
                    </div>
                    <div
                      v-if="
                        salesOrder.billingAddress &&
                          salesOrder.billingAddress.country
                      "
                    >
                      {{
                        getFullCountryName(salesOrder.billingAddress.country)
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card flat class="mb-1 px-5 py-3">
              <v-card-subtitle class="px-0 font-weight-bold">
                Shipping Address
              </v-card-subtitle>
              <v-card-text class="px-0">
                <v-row no-gutters>
                  <v-col class="py-0" cols="12">
                    <div>
                      {{ salesOrder.shippingAddress.line1 }}
                    </div>
                    <div>
                      {{ salesOrder.shippingAddress.line2 }}
                    </div>
                    <div>
                      {{ salesOrder.shippingAddress.line3 }}
                    </div>
                    <div>
                      {{ salesOrder.shippingAddress.city }}
                    </div>
                    <div>
                      {{ salesOrder.shippingAddress.postCode }}
                    </div>
                    <div>
                      {{ salesOrder.shippingAddress.state }}
                    </div>
                    <div
                      v-if="
                        salesOrder.shippingAddress &&
                          salesOrder.shippingAddress.country
                      "
                    >
                      {{
                        getFullCountryName(salesOrder.shippingAddress.country)
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { getName } from 'country-list'
import SalesOrderStateLabel from '../components/core/SalesOrderStateLabel.vue'
import SALES_ORDER_UPDATE from '@/graphql/SalesOrderUpdate.gql'
import SALES_ORDER_SUBMIT from '@/graphql/SalesOrderSubmit.gql'

export default {
  name: 'SalesOrderSummary',
  components: { SalesOrderStateLabel },
  props: {
    salesOrder: {
      type: Object,
      default: () => ({})
    },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  apollo: {},
  data: () => ({
    dialog: false,
    removeDialog: false,
    submitDialog: false,
    editMode: false,
    processing: false,
    addressDialog: false,
    errorMsg: '',
    editedItem: {
      id: null,
      customerID: null,
      notes: null,
      customerPoNumber: null,
      rmaNumber: null,
      type: 'REORDER',
      deliveryStartDate: null,
      deliveryEndDate: null
    },
    addressDefaultItem: {
      id: null,
      customerID: null,
      agentID: null,
      line1: null,
      line2: null,
      line3: null,
      postCode: null,
      city: null,
      country: null,
      addressType: null,
      email: null,
      phone: null
    },
    addressEditedItem: {
      id: null,
      customerID: null,
      agentID: null,
      line1: null,
      line2: null,
      line3: null,
      postCode: null,
      city: null,
      country: null,
      addressType: null,
      email: null,
      phone: null
    }
  }),
  computed: {
    zeroQtyLines() {
      var idx = this._.findIndex(
        this.salesOrder.lines,
        line => line.quantity === 0
      )

      if (idx > -1) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    unitCount(lines) {
      var count = 0

      lines.forEach(unit => {
        count += unit.quantity
      })

      return count
    },
    totalUnitCount() {
      var count = 0

      this.salesOrder.lines.forEach(line => {
        line.items.forEach(unit => {
          count += unit.quantity
        })
      })

      return count
    },
    getFullCountryName(code) {
      return getName(code)
    },
    editAddress(addressType) {
      if (addressType === 'BILLING') {
        this.addressEditedItem = this._.cloneDeep(
          this.salesOrder.billingAddress
        )
        this.editMode = true
      }

      if (addressType === 'SHIPPING') {
        this.addressEditedItem = this._.cloneDeep(
          this.salesOrder.shippingAddress
        )
        this.editMode = true
      }

      this.addressDialog = true
    },
    editSalesOrder() {
      this.editedItem = this._.cloneDeep(this.salesOrder)
      this.editMode = true
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.reviewDialog = false
      this.cancelOrderDialog = false
      this.removeDialog = false
      this.submitDialog = false
      this.addressDialog = false
      this.editedItem = this._.cloneDeep({})
      this.addressEditedItem = this._.cloneDeep(this.addressDefaultItem)
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('refresh-sales-order')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    submitOrder() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_SUBMIT,
          variables: {
            id: this.salesOrder.id
          }
        })
        .then(() => {
          this.$emit('refresh-sales-order')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Submitted'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style scoped>
.card-bordered-alt {
  border: 2px solid rgb(229, 237, 244);
}
</style>
