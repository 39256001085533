<template>
  <v-expansion-panels hover multiple flat>
    <v-expansion-panel v-for="item in agreements" :key="item.id" class="mb-1">
      <v-expansion-panel-header disable-icon-rotate>
        <v-row align="center"
          ><v-col class="py-0"
            ><span
              class="text-h6 font-weight-light primary--text text-truncate"
              >{{ agreementTitle(item.type) }}</span
            ></v-col
          ><v-col>
            <agreement-state-label :state="item.status"></agreement-state-label>
          </v-col>

          <v-col class="pa-0">
            <div v-if="!expired(item.expiration)">
              <span class="grey--text text-body-2">Expiration:</span>&nbsp;<span
                >{{ item.expiration | moment('DD MMM yyyy') }}</span
              >
            </div>
            <div v-if="expired(item.expiration)">
              <span class="grey--text text-body-2">Expired</span>
            </div>

            <div v-if="item.status === 'DECLINED'">
              <span class="grey--text text-body-2">Proposal Declined</span>
              &nbsp;<span>{{ item.updatedAt | moment('DD MMM yyyy') }}</span>
            </div>
          </v-col>
        </v-row>
        <template v-slot:actions>
          <v-btn v-if="!item.signatureDate" icon x-small @click.stop="">
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" icon v-bind="attrs" v-on="on">
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="editItem(item)">
                  <v-list-item-title>Edit</v-list-item-title>
                </v-list-item>

                <v-list-item @click="signItem(item)">
                  <v-list-item-title>Accept / Decline</v-list-item-title>
                </v-list-item>

                <v-list-item
                  v-if="item.status === 'OPEN'"
                  @click="removeItem(item.id)"
                >
                  <v-list-item-title>Remove</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-btn>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-body-2">
        <v-row>
          <v-col>
            <label v-if="item.agentID" class="grey--text text-body-2"
              >Invoice To</label
            >
            <label
              v-if="item.customerID && item.type === 'SEASONAL'"
              class="grey--text text-body-2"
              >Payment To</label
            >
            <div>{{ item.recipient }}</div>
          </v-col>
          <!-- <v-col>
            <label class="grey--text text-body-2">Created By</label>
            <div>John Doe</div>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col v-if="item.status === 'ACCEPTED'">
            <label class="grey--text text-body-2">Proposal Accepted</label
            >&nbsp;<span>{{ item.signatureDate | moment('DD MMM yyyy') }}</span>
          </v-col>
        </v-row>
        <v-row class="pt-2">
          <v-col v-if="item.type === 'COMMISSION'" cols="12" md="6">
            <label class="grey--text text-body-2">Commission Percentage</label>
            <div>{{ item.commissionPercentage | percent(2, 1) }}</div>
          </v-col>
          <v-col v-if="item.type === 'SEASONAL'" cols="12" md="6">
            <v-row>
              <v-col cols="12">
                <label class="grey--text text-body-2">Payment Terms</label>
                <v-btn
                  v-if="item.status === 'OPEN'"
                  outlined
                  rounded
                  x-small
                  color="info"
                  class="ml-3"
                  @click="editPaymentTerm(item.id, null)"
                  >add</v-btn
                >
              </v-col>
            </v-row>

            <ul class="list pt-1">
              <li
                v-for="term in item.paymentTerms"
                :key="term.id"
                class="list-row"
              >
                <div class="text-body-2">
                  <v-row no-gutters>
                    <v-col cols="8" class="py-1 text-truncate">
                      <payment-term-formatter
                        :term="term"
                      ></payment-term-formatter>
                    </v-col>
                    <v-col cols="4" class="py-1">
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        color="info"
                        class="ml-5"
                        @click="editPaymentTerm(item.id, term)"
                        ><v-icon>mdi-pencil-outline</v-icon></v-btn
                      >
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        class="ml-3"
                        @click="removePaymentTerm(term.id)"
                        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </li>
            </ul>
          </v-col>
          <v-col v-if="item.type === 'SEASONAL'" cols="12" md="6">
            <label class="grey--text text-body-2">Discounts</label>
            <v-btn
              v-if="item.status === 'OPEN'"
              outlined
              rounded
              x-small
              color="info"
              class="ml-3"
              @click="editDiscount(item.id, null)"
              >add</v-btn
            >
            <ul class="list mt-2">
              <li
                v-for="discount in item.discounts"
                :key="discount.id"
                class="list-row"
              >
                <div class="text-body-2">
                  <v-row no-gutters>
                    <v-col cols="8" class="py-1 text-truncate">
                      <discount-formatter
                        :discount="discount"
                      ></discount-formatter>
                    </v-col>
                    <v-col cols="4" class="py-1">
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        color="info"
                        class="ml-5"
                        @click="editDiscount(item.id, discount)"
                        ><v-icon>mdi-pencil-outline</v-icon></v-btn
                      >
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        class="ml-3"
                        @click="removeDiscount(discount.id)"
                        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </li>
            </ul>
          </v-col>
          <v-col v-if="item.type === 'SEASONAL'" cols="12" md="6">
            <label class="grey--text text-body-2">Delivery Terms</label>
            <v-btn
              v-if="item.status === 'OPEN'"
              outlined
              rounded
              x-small
              color="info"
              class="ml-3"
              @click="editDeliveryTerm(item.id, null)"
              >add</v-btn
            >

            <ul class="list pt-1">
              <li
                v-for="term in item.deliveryTerms"
                :key="term.id"
                class="list-row"
              >
                <div class="text-body-2">
                  <v-row no-gutters>
                    <v-col cols="8" class="py-1 text-truncate">
                      <delivery-term-formatter
                        :delivery-term="term"
                        :currency-symbol="defaultCurrency.symbol"
                      ></delivery-term-formatter>
                    </v-col>
                    <v-col cols="4" class="py-1">
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        color="info"
                        class="ml-5"
                        @click="editDeliveryTerm(item.id, term)"
                        ><v-icon>mdi-pencil-outline</v-icon></v-btn
                      >
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        class="ml-3"
                        @click="removeDeliveryTerm(term.id)"
                        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </li>
            </ul>
          </v-col>
          <v-col v-if="item.type === 'SEASONAL'" cols="12" md="6">
            <label class="grey--text text-body-2">Invoice Terms</label>
            <v-btn
              v-if="
                item.status === 'OPEN' &&
                  (!item.invoiceTerms || item.invoiceTerms.length === 0)
              "
              outlined
              rounded
              x-small
              color="info"
              class="ml-3"
              @click="editInvoiceTerm(item.id, null)"
              >add</v-btn
            >

            <ul class="list pt-1">
              <li
                v-for="term in item.invoiceTerms"
                :key="term.id"
                class="list-row"
              >
                <div class="text-body-2">
                  <v-row no-gutters>
                    <v-col cols="8" class="py-1 text-truncate">
                      <h5 class="grey--text">{{ term.title }}</h5>
                      <div>{{ term.display }}</div>
                    </v-col>
                    <v-col cols="4" class="pt-3">
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        color="info"
                        class="ml-5"
                        @click="editInvoiceTerm(item.id, term)"
                        ><v-icon>mdi-pencil-outline</v-icon></v-btn
                      >
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        class="ml-3"
                        @click="removeInvoiceTerm(term.id)"
                        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </li>
            </ul>
          </v-col>
          <v-col v-if="item.type === 'SEASONAL'" cols="12" md="6">
            <label class="grey--text text-body-2">Return Policy</label>
            <v-btn
              v-if="
                item.status === 'OPEN' &&
                  (!item.returnPolicies || item.returnPolicies.length === 0)
              "
              outlined
              rounded
              x-small
              color="info"
              class="ml-3"
              @click="editReturnPolicy(item.id, null)"
              >add</v-btn
            >

            <ul class="list pt-1">
              <li
                v-for="policy in item.returnPolicies"
                :key="policy.id"
                class="list-row"
              >
                <div class="text-body-2">
                  <v-row no-gutters>
                    <v-col cols="8" class="py-1 text-truncate">
                      <return-policy-formatter
                        :return-policy="policy"
                        :currency-symbol="defaultCurrency.symbol"
                      ></return-policy-formatter>
                    </v-col>
                    <v-col cols="4" class="py-1">
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        color="info"
                        class="ml-5"
                        @click="editReturnPolicy(item.id, policy)"
                        ><v-icon>mdi-pencil-outline</v-icon></v-btn
                      >
                      <v-btn
                        v-if="item.status === 'OPEN'"
                        icon
                        x-small
                        class="ml-3"
                        @click="removeReturnPolicy(policy.id)"
                        ><v-icon>mdi-trash-can-outline</v-icon></v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </li>
            </ul>
          </v-col>
          <v-col cols="12">
            <v-row no-gutters>
              <v-col cols="12" md="6">
                <label class="grey--text text-body-2">Proposal</label>
                <div class="pt-2">
                  <v-btn
                    :href="item.proposalFile"
                    outlined
                    x-small
                    color="info"
                    target="_blank"
                    >view</v-btn
                  >
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <label class="grey--text text-body-2">Signed Agreement</label>
                <div class="pt-2">
                  <v-btn
                    v-if="item.agreedFile && item.agreedFile != ''"
                    :href="item.agreedFile"
                    outlined
                    x-small
                    color="info"
                    target="_blank"
                    >view</v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import AgreementStateLabel from '@/components/core/AgreementStateLabel.vue'
import PaymentTermFormatter from '@/components/core/PaymentTermFormatter.vue'
import DiscountFormatter from '@/components/core/DiscountFormatter.vue'
import DeliveryTermFormatter from '@/components/core/DeliveryTermFormatter.vue'
import ReturnPolicyFormatter from '@/components/core/ReturnPolicyFormatter.vue'

export default {
  name: 'AgreementListTable',
  components: {
    AgreementStateLabel,
    PaymentTermFormatter,
    DiscountFormatter,
    DeliveryTermFormatter,
    ReturnPolicyFormatter
  },
  props: {
    agreements: {
      type: Array,
      required: true,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    defaultCurrency: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    selected: [],
    search: ''
  }),
  computed: {
    isExpired() {
      return this.$moment(this.agreements[0].expiration).isBefore(
        this.$moment()
      )
    }
  },
  methods: {
    expired(time) {
      this.$moment(time).isBefore(this.$moment())
    },
    navToDetail(item) {
      this.$emit('nav-to-detail-page', item)
    },
    agreementTitle(name) {
      let displayName
      switch (name) {
        case 'CUSTOMER':
          displayName = 'Customer'
          break

        case 'SEASONAL':
          displayName = 'Seasonal'
          break
        case 'AGENT':
          displayName = 'Agent'
          break
        case 'COMMISSION':
          displayName = 'Commission'
          break
      }

      return displayName + ' Agreement'
    },
    editItem(item) {
      this.$emit('edit-agreement', item)
    },
    signItem(item) {
      this.$emit('open-sign-agreement', item)
    },
    removeItem(id) {
      this.$emit('remove-agreement', id)
    },

    editPaymentTerm(agreementId, item) {
      this.$emit('edit-payment-term', agreementId, item)
    },
    removePaymentTerm(id) {
      this.$emit('remove-payment-term', id)
    },

    editDiscount(agreementId, item) {
      this.$emit('edit-discount', agreementId, item)
    },
    removeDiscount(id) {
      this.$emit('remove-discount', id)
    },

    editDeliveryTerm(agreementId, item) {
      this.$emit('edit-delivery-term', agreementId, item)
    },
    removeDeliveryTerm(id) {
      this.$emit('remove-delivery-term', id)
    },

    editInvoiceTerm(agreementId, item) {
      this.$emit('edit-invoice-term', agreementId, item)
    },
    removeInvoiceTerm(id) {
      this.$emit('remove-invoice-term', id)
    },

    editReturnPolicy(agreementId, item) {
      this.$emit('edit-return-policy', agreementId, item)
    },
    removeReturnPolicy(id) {
      this.$emit('remove-return-policy', id)
    }
  }
}
</script>

<style scoped>
.list {
  padding: 0;
  list-style: none;
  margin-left: -5px;
}

.list-row {
  padding: 0 5px;
  border-radius: 4px;
}

.controls {
  visibility: hidden;
}

.list-row:hover {
  background: #eee;
  cursor: pointer;
}

.list-row:hover .controls {
  visibility: visible;
}
</style>
