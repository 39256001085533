<template>
  <span>
    <v-chip
      pill
      x-small
      :color="styleState(state)"
      :text-color="styleText(state)"
      class="upperCaseSpacing"
    >
      {{ state }}
    </v-chip>
  </span>
</template>

<script>
export default {
  name: 'ActiveInactiveLabel',
  props: {
    state: {
      type: String,
      required: true
    }
  },
  methods: {
    styleState(state) {
      var color
      switch (state) {
        case 'ACTIVE':
          color = 'info lighten-2 font-weight-medium'
          break
        default:
          color = 'grey lighten-2 font-weight-medium'
          break
      }
      return color
    },
    styleText(state) {
      var color
      switch (state) {
        case 'ACTIVE':
          color = 'info darken-1'
          break
        default:
          color = 'grey darken-1'
          break
      }
      return color
    }
  }
}
</script>

<style></style>
