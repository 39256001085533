<template>
  <v-row>
    <v-col v-if="product" cols="12" class="pt-0">
      <v-card flat>
        <v-card-title class="subtitle-1">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Search..."
            single-line
            hide-details
            clearable
            outlined
            dense
            autocomplete="off"
            :disabled="
              !productCustomers ||
                (productCustomers && productCustomers.length === 0)
            "
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              !$apollo.loading &&
                productCustomerAuthLevel &&
                productCustomerAuthLevel === 2 &&
                (productCustomers || productCustomers.length > 0) &&
                product &&
                product.productState === 'FINAL' &&
                selected &&
                selected.length > 0
            "
            rounded
            small
            color="info"
            class="mr-3"
            :disabled="processing"
            @click="updateAll(true)"
            >Show</v-btn
          >
          <v-btn
            v-if="
              !$apollo.loading &&
                productCustomerAuthLevel &&
                productCustomerAuthLevel === 2 &&
                (productCustomers || productCustomers.length > 0) &&
                product &&
                product.productState === 'FINAL' &&
                selected &&
                selected.length > 0
            "
            rounded
            small
            color="info"
            :disabled="processing"
            @click="updateAll(false)"
            >Hide</v-btn
          >
          <v-btn
            v-if="
              !$apollo.loading &&
                productCustomerAuthLevel &&
                productCustomerAuthLevel === 2 &&
                (!productCustomers || productCustomers.length !== 0) &&
                product &&
                product.productState === 'FINAL'
            "
            rounded
            small
            color="primary"
            class="ml-2"
            :loading="processing"
            @click="populate"
            >Populate</v-btn
          >
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="productCustomers"
          :search="search"
          sort-by="customer.storeName"
          fixed-header
          show-select
          :hide-default-header="
            !productCustomers || productCustomers.length === 0
          "
          :items-per-page="-1"
          hide-default-footer
        >
          <template v-slot:item.customer.companyName="{ item }">
            <div
              v-if="
                item &&
                  item.customer.companyName &&
                  item.customer.storeName &&
                  item.customer.storeName != item.customer.companyName
              "
            >
              {{ item.customer.companyName }}
            </div>
          </template>
          <template v-slot:item.active="{ item }">
            <v-switch
              v-model="item.active"
              color="info"
              @click="update(item)"
            ></v-switch>
          </template>
          <template v-slot:no-data>
            <message-box
              title="No Results"
              title-color="primary--text"
              caption="Populate the list with customers"
              caption-color=""
            >
              <v-btn
                v-if="
                  !$apollo.loading &&
                    productCustomerAuthLevel &&
                    productCustomerAuthLevel === 2 &&
                    (!productCustomers || productCustomers.length === 0) &&
                    product &&
                    product.productState === 'FINAL'
                "
                rounded
                color="info"
                :loading="processing"
                @click="populate"
                >Populate List</v-btn
              >
            </message-box>
          </template>
          <template v-slot:loading>
            <v-skeleton-loader
              v-for="item in 5"
              :key="item"
              type="list-item"
              class="mx-auto"
            ></v-skeleton-loader>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import PRODUCT_SUBSCRIPTION_LIST from '@/graphql/ProductSubscriptions.gql'
import POPULATE_PRODUCTS_SUBSCRIPTIONS from '@/graphql/ProductSubscriptionsPopulate.gql'
import UPDATE_PRODUCTS_SUBSCRIPTIONS from '@/graphql/ProductSubscriptionsUpdate.gql'

export default {
  name: 'ProductionProductCustomers',
  components: { MessageBox },
  props: {
    product: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    colors: { type: Array, default: () => [] },
    categories: { type: Array, default: () => [] },
    users: { type: Array, default: () => [] },
    sizeGroups: { type: Array, default: () => [] },
    customerList: { type: Object, default: () => {} }
  },
  apollo: {
    productCustomers: {
      query: PRODUCT_SUBSCRIPTION_LIST,
      variables() {
        return {
          productID: this.$route.params.productID
        }
      },
      update: data => data.findProductSubscriptionsByProductID || []
      // fetchPolicy: 'no-cache'
    }
  },
  data: () => ({
    selected: [],
    search: null,
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    editDialog: false,
    removeDialog: false,
    removeableCustomer: null,
    processing: false,
    editMode: false,
    errorMsg: '',
    defaultItem: {
      id: null,
      productID: null,
      customerID: null
    },
    editedItem: null,
    productCustomers: []
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Store',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'customer.storeName'
        },
        {
          text: 'Company',
          align: 'left',
          sortable: true,
          filterable: true,
          value: 'customer.companyName'
        },
        {
          text: 'Visible',
          value: 'active',
          sortable: true,
          filterable: true
        }
      ]
    },
    productCustomerAuthLevel() {
      return this.$store.getters.authLevel('product_customers')
    },
    customers() {
      var items = []
      this._.forEach(this.selected, item => items.push(item.customer?.id))

      return items
    }
  },
  methods: {
    populate() {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: POPULATE_PRODUCTS_SUBSCRIPTIONS,
          variables: {
            input: {
              productID: this.$route.params.productID
            }
          }
        })
        .then(() => {
          this.$apollo.queries.productCustomers.refetch()
          this.processing = false
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    update(item) {
      this.updateItems([item.customer.id], item.active)
    },
    updateAll(active) {
      this.updateItems(this.customers, active)
      this.selected = []
    },
    updateItems(customers, visible) {
      this.processing = true

      this.$apollo
        .mutate({
          mutation: UPDATE_PRODUCTS_SUBSCRIPTIONS,
          variables: {
            input: {
              productID: this.$route.params.productID,
              customers: customers,
              active: visible
            }
          }
        })
        .then(() => {
          this.$apollo.queries.productCustomers.refetch()
          this.processing = false
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style scoped lang="scss"></style>
