var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header"},[(_vm.lineSheet)?_c('v-toolbar',{staticClass:"mb-0 b-radius",attrs:{"extended":"","flat":""},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-tabs',{attrs:{"next-icon":"mdi-chevron-right","prev-icon":"mdi-chevron-left","background-color":"transparent","align-with-title":"","center-active":"","show-arrows":""}},[_c('v-tabs-slider',{attrs:{"color":"primary"}}),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LineSheetDetail',
              params: { lineSheetID: _vm.$route.params.lineSheetID }
            },"exact":""}},[_vm._v("Details")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LineSheetProducts',
              params: { lineSheetID: _vm.$route.params.lineSheetID }
            }}},[_vm._v("Products")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LineSheetSubscriberList',
              params: { lineSheetID: _vm.$route.params.lineSheetID }
            }}},[_vm._v("Subscribers")]),_c('v-tab',{staticClass:"text-capitalize",attrs:{"to":{
              name: 'LineSheetSettings',
              params: { lineSheetID: _vm.$route.params.lineSheetID }
            }}},[_vm._v("Settings")])],1),_vm._t("controls",function(){return [_c('v-btn',{attrs:{"color":"primary","small":"","rounded":"","loading":_vm.generatePdf},on:{"click":_vm.downloadPdf}},[_vm._v("Download")])]})]},proxy:true}],null,true)},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',{attrs:{"justify":"start","align":"start"}},[_c('div',{attrs:{"sm6":""}},[_c('h2',{staticClass:"mb-0 primary--text"},[_vm._v(" "+_vm._s(_vm.lineSheet.name)+" ")]),_c('div',[_c('span',{staticClass:"subtitle-2"},[_c('active-inactive-label',{attrs:{"state":_vm.lineSheet.state}})],1)])]),_c('v-spacer'),_c('div',{attrs:{"sm6":""}})],1)],1)],1)],1)],1):_vm._e()],1),_c('div',{staticClass:"main-page-column pt-0"},[_c('div',{staticClass:"scroll-container"},[_c('router-view',{attrs:{"line-sheet":_vm.lineSheet,"seasons":_vm.seasons,"current-user":_vm.currentUser,"auth-level":_vm.authLevel,"loading":_vm.$apollo.queries.lineSheet.loading},on:{"refetch-line-sheet":_vm.refetch}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }