<template>
  <div class="page-wrap">
    <div
      v-if="
        search ||
          (warehouses && warehouses.items && warehouses.items.length > 0)
      "
      class="page-header"
    >
      <v-toolbar class="mb-0 b-radius" flat>
        <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
        <v-row>
          <v-col>
            <v-btn class="ml-2" color="primary" rounded @click="addWarehouse">
              add warehouse
            </v-btn>
          </v-col>
          <v-col>
            <v-text-field
              v-model="search"
              placeholder="search"
              autocomplete="off"
              hide-details="auto"
              outlined
              dense
              clearable
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-toolbar>
    </div>

    <div class="main-page-column pt-0">
      <warehouse-edit-dialog
        :dialog="dialog"
        :warehouse="editedItem"
        :warehouse-providers="warehouseProviders.items"
        :edit-mode="editMode"
        :processing="processing"
        :error-msg="errorMsg"
        @close-warehouse-edit-dialog="close"
        @save-warehouse-edit-dialog="save"
      ></warehouse-edit-dialog>
      <div class="scroll-container pt-0">
        <v-row
          v-if="
            !$apollo.loading &&
              warehouses &&
              warehouses.items &&
              warehouses.items.length > 0
          "
        >
          <v-col
            v-for="warehouse in warehouses.items"
            :key="warehouse.id"
            cols="12"
            md="4"
            lg="3"
          >
            <v-card
              :to="{
                name: 'SalesWarehouseDetail',
                params: { warehouseID: warehouse.id }
              }"
              class="card-bordered"
              outlined
              hover
            >
              <v-card-title>{{ warehouse.name }}</v-card-title>
              <v-card-subtitle v-if="warehouse.provider" class="pb-2">{{
                warehouse.provider.name
              }}</v-card-subtitle>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="grey--text caption pt-0">
                    <div class="text-truncate">
                      <v-icon small color="grey lighten-1" class="mr-1"
                        >mdi-map-marker</v-icon
                      >
                      <span
                        v-if="warehouse.address && warehouse.address.city"
                        >{{ warehouse.address.city }}</span
                      ><span
                        v-if="
                          warehouse.address &&
                            warehouse.address.city &&
                            warehouse.address.country
                        "
                        >,
                      </span>
                      <span
                        v-if="warehouse.address && warehouse.address.country"
                        >{{
                          getFullCountryName(warehouse.address.country)
                        }}</span
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-if="
            !$apollo.loading &&
              warehouses &&
              warehouses.items &&
              warehouses.items.length === 0
          "
        >
          <v-col cols="12">
            <v-card flat>
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Warehouses"
                :caption="
                  search
                    ? 'Try adjusting your filters to get some results'
                    : 'Add your first warehouse to get started'
                "
              >
                <v-btn
                  v-if="!search"
                  class="ml-2"
                  color="primary lighten-2"
                  rounded
                  small
                  @click="addWarehouse"
                >
                  add warehouse
                </v-btn>
              </message-box>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-if="$apollo.loading">
          <v-col v-for="item in 5" :key="item">
            <v-sheet color="grey lighten-4" class="px-3 pt-3 pb-3">
              <v-skeleton-loader
                class="mx-auto"
                max-width="300"
                type="card"
              ></v-skeleton-loader>
            </v-sheet>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { lookup } from 'country-data-list'
import MessageBox from '@/components/core/MessageBox.vue'
import WarehouseEditDialog from '@/modules/logistics/components/core/WarehouseEditDialog.vue'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'
import WAREHOUSE_PROVIDER_LIST from '@/graphql/WarehouseProviders.gql'
import WAREHOUSE_CREATE from '@/graphql/WarehouseCreate.gql'

export default {
  name: 'SalesWarehouseList',
  components: { MessageBox, WarehouseEditDialog },
  data: () => ({
    filters: false,
    search: null,
    options: { page: 1, itemsPerPage: 100 },
    itemCount: 0,
    selected: [],
    warehouses: { items: [] },
    warehouseProviders: { items: [] },
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    defaultItem: {
      id: null,
      name: null,
      notes: null,
      providerKey: null,
      testEndpoint: null,
      liveEndpoint: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null,
        country: null
      }
    },
    editedItem: {
      id: null,
      name: null,
      notes: null,
      providerKey: null,
      testEndpoint: null,
      liveEndpoint: null,
      address: {
        id: null,
        email: null,
        phone: null,
        line1: null,
        line2: null,
        line3: null,
        city: null,
        postCode: null,
        country: null
      }
    }
  }),
  apollo: {
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            page: 1,
            pageSize: 1000
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.warehouses.page
        this.itemsPerPage = result.data.warehouses.pageSize
        this.itemCount = result.data.warehouses.total
      }
    },
    warehouseProviders: {
      query: WAREHOUSE_PROVIDER_LIST,
      variables() {
        return {
          filters: {
            search: null,
            page: 1,
            pageSize: 1000
          }
        }
      },
      fetchPolicy: 'no-cache'
    }
  },
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Location',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'address.name'
        },
        {
          text: 'Phone',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'address.phone'
        },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'address.email'
        },
        {
          text: 'Last Updated',
          value: 'updatedAt',
          sortable: true,
          filterable: true
        },
        {
          text: '',
          value: 'controls',
          sortable: false,
          filterable: true
        }
      ]
    }
  },
  methods: {
    getFullCountryName(code) {
      return lookup.countries({ alpha2: code })[0]?.name
    },

    navToDetail(item) {
      this.$router.push({
        name: 'LogisticsWarehouseDetail',
        params: { warehouseID: item.id }
      })
    },
    addWarehouse() {
      let provider = this._.find(this.warehouseProviders.items, item => {
        return item.name === 'Daily Paper'
      })

      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.editedItem.provider = provider
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      // if (this.editMode) {
      //   // Edit item
      //   delete item.id
      //   delete item.address
      // } else {
      // Save New Item

      delete item.id
      delete item.address.id

      this.$apollo
        .mutate({
          mutation: WAREHOUSE_CREATE,
          variables: {
            input: item
          }
          // update: (store, { data: { createWarehouse } }) => {
          //   // Read the data from our cache for this query.
          //   const data = store.readQuery({
          //     query: WAREHOUSE_LIST
          //   })
          //   // Add our requirement from the mutation to the end
          //   data.warehouses.push(createWarehouse)
          //   // Write our data back to the cache.
          //   store.writeQuery({
          //     query: WAREHOUSE_LIST,
          //     data
          //   })
          // }
        })
        .then(result => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Warehouse Added'
          })
          this.$router.push({
            name: 'SalesWarehouseDetail',
            params: { warehouseID: result.data.createWarehouse }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
