<template>
  <v-dialog
    v-model="dialog"
    width="calc(100vw - 200px)"
    persistent
    scrollable
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card color="light-theme">
      <!-- <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title> -->
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <v-card-text style="max-height: calc(100vh - 200px);">
        <v-form
          ref="addToCartForm"
          v-model="valid"
          lazy-validation
          @submit.prevent
        >
          <v-row v-if="item" class="py-4">
            <v-col cols="12" md="4">
              <v-card flat class="mb-5">
                <v-card-text>
                  <v-row>
                    <v-col cols="8">
                      <div
                        class="text-h5 grey--text text--darken-3 text-truncate"
                      >
                        {{ product.name }}
                      </div>
                      <div class="caption">{{ product.seasonColor }}</div>
                    </v-col>
                    <v-col cols="4">
                      <v-row justify="end">
                        <div class="text-h6 info--text font-weight-medium pr-3">
                          {{
                            (editedItem.wholesalePrice / 100)
                              | currency(editedItem.currencySymbol, 2, {
                                spaceBetweenAmountAndSymbol: true
                              })
                          }}
                        </div>
                      </v-row>
                      <v-row justify="end">
                        <div class="text-caption pr-3">
                          {{
                            (editedItem.retailPrice / 100)
                              | currency(editedItem.currencySymbol, 2, {
                                spaceBetweenAmountAndSymbol: true
                              })
                          }}
                          RRP
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>

                  <product-image-carousel
                    :front-image="product.frontImage"
                    :rear-image="product.rearImage"
                  ></product-image-carousel>
                </v-card-text>
              </v-card>

              <v-card flat class="mb-5">
                <v-card-title class="grey--text text--darken-2">
                  Details
                </v-card-title>
                <v-card-text>
                  <v-row no-gutters class="py-2">
                    <v-col cols="12" md="4">
                      <span class="grey--text">Style Number</span>
                    </v-col>
                    <v-col cols="12" md="8">
                      {{ product.styleNumber }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row no-gutters class="py-2">
                    <v-col cols="12" md="4">
                      <span class="grey--text">Season</span>
                    </v-col>
                    <v-col cols="12" md="8">
                      {{ product.season.shortName }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row no-gutters class="py-2">
                    <v-col cols="12" md="4">
                      <span class="grey--text">Color</span>
                    </v-col>
                    <v-col cols="12" md="8">
                      {{ product.seasonColor }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row no-gutters class="py-2">
                    <v-col cols="12" md="4">
                      <span class="grey--text">Article Group</span>
                    </v-col>
                    <v-col cols="12" md="8">
                      {{ product.articleGroup.name }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row no-gutters class="py-2">
                    <v-col cols="12" md="4">
                      <span class="grey--text">Weight</span>
                    </v-col>
                    <v-col cols="12" md="8">
                      {{ product.weight || 0 }} kg
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row no-gutters class="py-2">
                    <v-col cols="12" md="4">
                      <span class="grey--text">Composition</span>
                    </v-col>
                    <v-col cols="12" md="8">
                      {{ product.textileContent }}
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row no-gutters class="py-2">
                    <v-col cols="12" md="4">
                      <span class="grey--text">Description</span>
                    </v-col>
                    <v-col cols="12" md="8">
                      {{ product.description }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="8">
              <v-card flat class="mb-5">
                <v-card-text>
                  <v-row justify="end" align="center" class="px-3">
                    <v-col cols="8" md="6" class="text-h5">
                      <v-row justify="space-between">
                        <span>Items: {{ itemCount }}</span>
                        <span class="pl-6">{{
                          (total / 100)
                            | currency(item.currencySymbol, 2, {
                              spaceBetweenAmountAndSymbol: true
                            })
                        }}</span>
                      </v-row>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-btn
                      v-if="
                        salesOrder &&
                          salesOrder.state &&
                          (salesOrder.state === 'DRAFT' ||
                            salesOrder.state === 'SUBMITTED') &&
                          ((authLevel && authLevel === 2) ||
                            (currentUser &&
                              (currentUser.isAdmin ||
                                currentUser.isSuperAdmin ||
                                currentUser.userRole === 'manager' ||
                                currentUser.userRole === 'seniormanager' ||
                                currentUser.userRole === 'director')))
                      "
                      :loading="processing"
                      rounded
                      color="primary lighten-2"
                      @click="save"
                    >
                      <v-icon>mdi-cart-variant</v-icon>
                      <span v-if="editMode">Update Cart</span
                      ><span v-else>Add To Cart</span></v-btn
                    >
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card flat class="mb-5">
                <v-card-text>
                  <v-row>
                    <v-col
                      v-for="line in orderBy(item.items, 'order')"
                      :key="line.id"
                      cols="12"
                      sm="3"
                      md="2"
                      lg="2"
                      xl="2"
                      xs="12"
                    >
                      <v-text-field
                        v-model="line.quantity"
                        autocomplete="off"
                        min="0"
                        dense
                        placeholder="Qty"
                        type="number"
                        outlined
                        hide-details
                        :disabled="
                          salesOrder.state !== 'DRAFT' &&
                            salesOrder.state !== 'SUBMITTED'
                        "
                      >
                        <template v-slot:label>
                          <span class="font-weight-bold">{{ line.name }}</span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card flat class="mb-5">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-textarea
                        v-model="item.notes"
                        autocomplete="off"
                        outlined
                        label="Notes"
                        placeholder="Add any special requests or remarks."
                        rows="3"
                        hide-details
                        dense
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card flat class="mb-5">
                <v-card-title class="grey--text text--darken-2">
                  Inventory
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" class="text-caption pb-0">Available</v-col>
                    <v-col
                      v-for="item in stockItems"
                      :key="item.id"
                      cols="12"
                      sm="3"
                      md="2"
                      lg="2"
                      xl="2"
                      xs="12"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="pa-2 text-truncate"
                          style="border: 1px solid var(--v-light-theme-darken1);border-radius: 4px;"
                        >
                          <span>{{ item.size.name }} |</span>
                          <strong> {{ item.availableQuantity }}</strong>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="pt-3"><v-divider></v-divider></v-row>
                  <v-row>
                    <v-col cols="12" class="text-caption pb-0"
                      >In Production</v-col
                    >
                    <v-col
                      v-for="item in orderBy(stockItems, 'size.promeseCode')"
                      :key="item.id"
                      cols="12"
                      sm="3"
                      md="2"
                      lg="2"
                      xl="2"
                      xs="12"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="pa-2 text-truncate"
                          style="border: 1px solid var(--v-light-theme-darken1);border-radius: 4px;"
                        >
                          <span>{{ item.size.name }} |</span>
                          <strong> {{ item.productionQuantity }}</strong>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import ProductImageCarousel from '../../../production/components/core/ProductImageCarousel.vue'

export default {
  name: 'AddToCartDialog',
  components: { ProductImageCarousel },
  mixins: [Vue2Filters.mixin],
  props: {
    salesOrder: {
      type: Object,
      required: true,
      default: () => ({})
    },
    product: {
      type: Object,
      required: true,
      default: () => ({})
    },
    stockItems: {
      type: Array,
      required: true,
      default: () => []
    },
    editedItem: {
      type: Object,
      required: true,
      default: () => ({ items: [] })
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    authLevel: { type: Number, default: () => 0 },
    currentUser: { type: Object, default: () => {} },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    item: null
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Product' : 'Add Product'
    },
    itemCount() {
      var count = 0

      if (this.item && this.item.items && this.item.items.length > 0) {
        this.item.items.forEach(item => {
          count += parseInt(item.quantity)
        })
      }

      return count
    },
    total() {
      return this.itemCount * this.editedItem.wholesalePrice
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.editedItem)
    }
  },
  methods: {
    close() {
      // this.$refs.addToCartForm.reset()
      this.$emit('close-add-to-cart-dialog', false)
    },
    save() {
      if (this.$refs.addToCartForm.validate()) {
        var lineItems = []

        this.item.items.forEach(line => {
          var orderLine = {
            name: line.name,
            productSizeID: line.productSizeID,
            barcode: line.barcode.toString(),
            quantity: parseInt(line.quantity) < 0 ? 0 : parseInt(line.quantity)
          }

          if (this.editMode) {
            orderLine.id = line.id
            orderLine.salesOrderLineID = line.salesOrderLineID
          }

          lineItems.push(orderLine)
        })

        var data = {
          id: this.item.id,
          notes: this.item.notes,
          items: lineItems
        }

        if (!this.editMode) {
          this._.assignIn(data, {
            salesOrderID: this.item.salesOrderID,
            seasonProductID: this.item.seasonProductID,
            styleNumber: this.item.styleNumber,
            notes: this.item.notes,
            currencyID: this.item.currencyID,
            currencySymbol: this.item.currencySymbol,
            currencyISO: this.item.currencyISO,
            exchangeRate: this.item.exchangeRate,
            quantity: this.itemCount,
            wholesalePrice: this.item.wholesalePrice,
            retailPrice: this.item.retailPrice,
            net: this.total
          })

          delete data.id
        }

        this.$emit('save-add-to-cart-dialog', data)
      }
    }
  }
}
</script>

<style></style>
