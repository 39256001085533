<template>
  <v-row>
    <agreement-edit-dialog
      :dialog="dialog"
      :agreement="editedItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-agreement-edit-dialog="close"
      @save-agreement-edit-dialog="save"
    ></agreement-edit-dialog>
    <agreement-sign-edit-dialog
      :dialog="signDialog"
      :agreement="editSignItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-agreement-edit-dialog="close"
      @save-agreement-sign-dialog="signAgreement"
    ></agreement-sign-edit-dialog>
    <payment-term-edit-dialog
      :dialog="paymentTermDialog"
      :payment-term="editedPaymentTermItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-payment-term-edit-dialog="close"
      @save-payment-term-edit-dialog="save"
    ></payment-term-edit-dialog>
    <v-dialog v-model="removeAgreementDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Agreement</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the agreement and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="error" text @click="removeAgreement">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col v-if="agent" cols="12" class="py-0">
      <v-row no-gutters>
        <v-col class="pl-0 mb-6">
          <v-row no-gutters>
            <v-btn color="primary" rounded @click="editAgreement">
              add agreement
            </v-btn>
          </v-row>
          <v-row v-if="addressError" no-gutters>
            <v-col cols="12">
              <v-alert type="info" class="mb-0 mt-6" dismissible border="left">
                Please add all customer addresses before continuing
              </v-alert>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" md="10" xl="8">
          <v-row no-gutters>
            <v-col
              v-if="
                !loading && generalAgreements && generalAgreements.length > 0
              "
              cols="12"
              class="mb-10"
              ><h3 class="pl-1 mb-3">General</h3>
              <agreement-list-table
                :agreements="generalAgreements || []"
                :loading="loading"
                @edit-agreement="editAgreement"
                @open-sign-agreement="openSignAgreement"
                @remove-agreement="openRemoveAgreementDialog"
                @edit-payment-term="editPaymentTerm"
              ></agreement-list-table>
            </v-col>

            <v-col
              v-if="
                !loading &&
                  comissionAgreements &&
                  comissionAgreements.length > 0
              "
              cols="12"
            >
              <h3 class="pl-1 mb-3">Commission</h3>

              <agreement-list-table
                :agreements="comissionAgreements || []"
                :loading="loading"
                @edit-agreement="editAgreement"
                @open-sign-agreement="openSignAgreement"
                @remove-agreement="openRemoveAgreementDialog"
                @edit-payment-term="editPaymentTerm"
              ></agreement-list-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { getName } from 'country-list'
import AgreementEditDialog from '@/modules/sales/components/core/AgreementEditDialog.vue'
import AgreementSignEditDialog from '@/modules/sales/components/core/AgreementSignEditDialog.vue'
import PaymentTermEditDialog from '@/modules/sales/components/core/PaymentTermEditDialog.vue'
import AgreementListTable from '../components/core/AgreementListTable.vue'
import AGREEMENT_CREATE from '@/graphql/AgreementProposalCreate.gql'
import AGREEMENT_UPDATE from '@/graphql/AgreementProposalUpdate.gql'
import AGREEMENT_SIGN from '@/graphql/AgreementSign.gql'
import AGREEMENT_REMOVE from '@/graphql/AgreementRemove.gql'

export default {
  name: 'AgentAgreementList',
  components: {
    AgreementEditDialog,
    AgreementSignEditDialog,
    PaymentTermEditDialog,
    AgreementListTable
  },
  props: {
    agent: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    dialog: false,
    removeAgreementDialog: false,
    signDialog: false,
    paymentTermDialog: false,
    addressError: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    removeAgreementID: null,
    defaultItem: {
      agentID: null,
      proposalFile: null,
      type: null,
      expiration: null,
      commissionPercentage: 0
    },
    editSignItem: {},
    editedItem: {
      id: null,
      agentID: null,
      proposalFile: null,
      type: null,
      expiration: null,
      commissionPercentage: 0
    },
    defaultPaymentTermItem: {
      id: null,
      agreementID: null,
      paymentMethod: 'WIRE',
      type: 'DAYS',
      percentage: null
    },
    editedPaymentTermItem: {}
  }),
  computed: {
    generalAgreements() {
      return this._.filter(this.agent.agreements, item => {
        return item.type === 'AGENT'
      })
    },
    comissionAgreements() {
      return this._.filter(this.agent.agreements, item => {
        return item.type === 'COMMISSION'
      })
    }
  },
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    editAgreement(item) {
      if (
        !this.customer.visitingAddress ||
        !this.customer.billingAddress ||
        !this.customer.shippingAddress
      ) {
        this.addressError = true
      } else {
        if (item?.id) {
          this.editMode = true
          this.editedItem = this._.cloneDeep(item)
        } else {
          this.editedItem = this._.cloneDeep(this.defaultItem)
          this.editedItem.agentID = this.$route.params.agentID
          this.editedItem.type = 'AGENT'

          let date = new Date()
          date.setDate(date.getDate() + 365)

          this.editedItem.expiration = date.toISOString()
        }

        this.dialog = true
      }
    },
    openSignAgreement(item) {
      if (
        !this.customer.visitingAddress ||
        !this.customer.billingAddress ||
        !this.customer.shippingAddress
      ) {
        this.addressError = true
      } else {
        this.editSignItem = this._.cloneDeep(item)

        this.signDialog = true
      }
    },
    openRemoveAgreementDialog(id) {
      this.removeAgreementID = id
      this.removeAgreementDialog = true
    },
    close() {
      this.dialog = false
      this.signDialog = false
      this.paymentTermDialog = false
      this.removeAgreementDialog = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.editSignItem = {}
      this.editedPaymentTermItem = this._.cloneDeep(this.defaultPaymentTermItem)
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    signAgreement(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: AGREEMENT_SIGN,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('refetch-agent')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Agreement Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true
      delete item.customerID

      if (!this.editMode) {
        this.$apollo
          .mutate({
            mutation: AGREEMENT_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-agent')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Agreement Saved'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: AGREEMENT_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-agent')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Agreement Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    removeAgreement() {
      this.$apollo
        .mutate({
          mutation: AGREEMENT_REMOVE,
          variables: {
            id: this.removeAgreementID
          }
        })
        .then(() => {
          this.$emit('refetch-agent')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Agreement Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },

    editPaymentTerm(agreementID, item) {
      if (item?.id) {
        this.editMode = true
        this.editedPaymentTermItem = this._.cloneDeep(item)
      } else {
        this.editedPaymentTermItem = this._.cloneDeep(
          this.defaultPaymentTermItem
        )
        this.editedItem.agreementID = agreementID
      }

      this.paymentTermDialog = true
    },
    savePaymentTerm() {}
  }
}
</script>

<style></style>
