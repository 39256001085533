<template>
  <div class="page-wrap">
    <div class="main-page-column pt-0">
      <div class="scroll-container pt-0">
          <v-row class="mb-3">
              <v-col cols="4" md="3">
                <v-autocomplete
                  v-model="customerID"
                  no-filter
                  :items.sync="customers.items"
                  :search-input.sync="searchCustomers"
                  outlined
                  dense
                  clearable
                  background-color="#fff"
                  item-value="id"
                  item-text="storeName"
                  hide-details="auto"
                  label="Customer"
                  placeholder="-- Select --"
                >
                <template v-slot:selection="data">
                  {{ data.item.storeName }} <span v-if="data.item.companyName && data.item.storeName !== data.item.companyName" class="pl-2">({{ data.item.companyName }})</span>
                </template>
                <template v-slot:item="data">
                  {{ data.item.storeName }} <span v-if="data.item.companyName && data.item.storeName !== data.item.companyName" class="pl-2">({{ data.item.companyName }})</span>
                </template>
              </v-autocomplete>
              </v-col>
              <v-col cols="4" md="3">
                <v-autocomplete
                  v-model="warehouseID"
                  :items="warehouses.items"
                  outlined
                  dense
                  clearable
                  background-color="#fff"
                  item-value="id"
                  hide-details="auto"
                  single-line
                  label="Warehouse"
                  placeholder="-- Select --"
                >
                <template v-slot:selection="data">
                  <div class="text-truncate">{{ data.item.name }} ({{ data.item.provider.name }})</div>
                </template>
                <template v-slot:item="data">
                  <div class="text-truncate">{{ data.item.name }} ({{ data.item.provider.name }})</div>
                </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="4" md="3">
                <v-text-field
                  v-model="searchSalesOrder"
                  hide-details
                  outlined
                  dense
                  clearable
                  background-color="#fff"
                  placeholder="Search by sales order..."
                  prepend-inner-icon="mdi-magnify"
                  autocomplete="off"
                ></v-text-field>
              </v-col>
            </v-row>

        <v-card flat>
          <v-data-table
            v-model="selected"
            :loading="$apollo.loading"
            :headers="headers"
            :items="deliveryNotes.items"
            :options.sync="options"
            :server-items-length="itemCount"
            :mobile-breakpoint="null"
            :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
            fixed-header
            class="plain-table"
            :hide-default-header="
              !$apollo.loading &&
                !search &&
                (!deliveryNotes ||
                  !deliveryNotes.items ||
                  deliveryNotes.items.length === 0)
            "
            :hide-default-footer="
              !deliveryNotes || itemCount < options.itemsPerPage + 1
            "
            @click:row="navToDetail"
          >
            <template v-slot:item.createdAt="{ item }">
              <span v-if="item.createdAt" class="text-no-wrap">{{
                item.createdAt | moment('LLL')
              }}</span>
            </template>

            <template v-slot:item.warehouse="{ item }">
              <div>
                {{ item.warehouse.name }} ({{ item.warehouse.provider.name }})
              </div>
            </template>

            <template v-slot:item.trackAndTraceValue="{ item }">
              <div>
                <a
                  v-if="item.trackAndTraceValue"
                  target="_blank"
                  :href="item.trackAndTraceValue"
                  >{{ item.trackAndTraceValue }}</a
                >
                <span v-else>{{
                  item.carrier + ' - ' + item.trackAndTraceType
                }}</span>
              </div>
            </template>

            <template v-slot:no-data>
              <message-box
                icon="mdi-emoticon-neutral-outline"
                title="No Delivery Notes"
                :caption="'Try adjusting your search to get some results'"
              >
              </message-box>
            </template>
            <template v-slot:loading>
              <v-skeleton-loader
                v-for="item in 5"
                :key="item"
                type="list-item"
                class="mx-auto"
              ></v-skeleton-loader>
            </template>
          </v-data-table>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import MessageBox from '@/components/core/MessageBox.vue'
import DELIVERY_NOTE_LIST from '@/graphql/DeliveryNotes.gql'
import CUSTOMER_LIST from '@/graphql/SalesCustomers.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'

export default {
  name: 'DeliveryNoteList',
  components: { MessageBox },
  data: () => ({
    search: null,
    searchCustomers: null,
    searchSalesOrder: null,
    searchWarehouses: null,
    customerID: null,
    warehouseID: null,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    selected: [],
    deliveryNotes: { items: [] },
    customers: { items: [] },
    warehouses: { items: [] },
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    warehouseID: null,
    headers: [
      {
        text: 'Order No.',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'salesOrder.orderNumber'
      },
      {
        text: '',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'state'
      },
      {
        text: 'Customer',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'salesOrder.customer.storeName'
      },
      {
        text: 'Warehouse',
        sortable: false,
        filterable: true,
        value: 'warehouse'
      },
      {
        text: 'Tracking',
        sortable: false,
        filterable: true,
        value: 'trackAndTraceValue'
      },
      {
        text: 'Created On',
        sortable: false,
        filterable: true,
        value: 'createdAt'
      }
    ],
    defaultItem: {
      id: null,
      stockChange: 0
    },
    editedItem: {
      id: null,
      stockChange: 0
    }
  }),
  apollo: {
    deliveryNotes: {
      query: DELIVERY_NOTE_LIST,
      variables() {
        return {
          filters: {
            customerID: this.customerID,
            warehouseID: this.warehouseID,
            salesOrderID: this.searchSalesOrder,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      // fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.deliveryNotes.page
        this.itemsPerPage = result.data.deliveryNotes.pageSize
        this.itemCount = result.data.deliveryNotes.total
      }
    },
    customers: {
      query: CUSTOMER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchCustomers,
            page: 1,
            pageSize: 10000
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200
    },
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            search: this.searchWarehouses,
            page: 1,
            pageSize: 1000
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
    },
  },
  computed: {
    currentRoute() {
      return this.$route
    }
  },
  methods: {
    navToDetail(item) {
      var appName = this.currentRoute?.meta?.appName

      this.$router.push({
        name: appName + 'DeliveryNoteDetails',
        params: { deliveryNoteID: item.id }
      })
    }
  }
}
</script>

<style></style>
