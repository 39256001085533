<template>
  <v-row>
    <agent-edit-dialog
      :dialog="dialog"
      :agent="editedItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-agent-edit-dialog="close"
      @save-agent-edit-dialog="save"
    ></agent-edit-dialog>
    <address-edit-dialog
      :dialog="addressDialog"
      :address="addressEditedItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-address-edit-dialog="close"
      @save-address-edit-dialog="saveAddress"
    ></address-edit-dialog>
    <contact-edit-dialog
      :dialog="contactDialog"
      :contact="contactEditedItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-contact-edit-dialog="close"
      @save-contact-edit-dialog="saveContact"
    ></contact-edit-dialog>
    <v-dialog v-model="removeContactDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Contact</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the contact and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="error" text @click="removeContact">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col v-if="agent" cols="12" class="py-0">
      <v-row>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title class="mb-3"
                  ><span>Details</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((agent && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editAgent"
                  >
                    edit
                  </v-btn></v-card-title
                >
                <v-card-text>
                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">Name</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.name }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Website</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <a
                            v-if="agent.website"
                            :href="agent.website"
                            target="_blank"
                            >{{ agent.website }}</a
                          >
                          <span
                            v-else
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.website }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Company Registration</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.commerceReg }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >VAT Registration</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.vatId }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Facebook</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.facebook }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Instagram</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.instagram }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Linkedin</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.linkedin }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Twitter</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.twitter }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Last Update</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.updatedAt | moment('from', 'now') }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title class="mb-3"
                  ><span>Contacts</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((agent && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editContact"
                  >
                    add contact
                  </v-btn></v-card-title
                >
                <v-data-table
                  v-if="agent.contacts"
                  :headers="contactHeaders"
                  :items="agent.contacts"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    <div class="text-truncate">
                      <span v-if="item.title">{{ item.title + ' ' }}</span>
                      <span>{{ item.firstName + ' ' + item.lastName }}</span>
                    </div>
                  </template>

                  <template v-slot:item.status="{ item }">
                    <div class="text-truncate">
                      <v-chip
                        v-if="item.status == 'ACTIVE'"
                        x-small
                        label
                        outlined
                        color="success"
                        >{{ item.status }}</v-chip
                      >
                      <v-chip v-else x-small label outlined>{{
                        item.status
                      }}</v-chip>

                      <!-- <v-tooltip v-if="item.isUser" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            x-small
                            label
                            outlined
                            color="info"
                            class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                            >portal</v-chip
                          >
                        </template>
                        <span>Contact Has Access To Partner Portal</span>
                      </v-tooltip> -->
                    </div>
                  </template>
                  <template v-slot:item.controls="{ item }">
                    <div class="text-truncate">
                      <v-btn
                        small
                        rounded
                        color="info"
                        text
                        class=" text-lowercase"
                        @click.prevent="editContact(item)"
                        >edit</v-btn
                      >
                      <v-btn
                        small
                        rounded
                        text
                        color="grey lighten-1"
                        class="ml-3 text-lowercase"
                        @click.prevent="openRemoveContactDialog(item.id)"
                        >delete</v-btn
                      >
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title class="mb-3"
                  ><span>Visiting Address</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((agent && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editAddress('VISITING')"
                  >
                    <span
                      v-if="agent.visitingAddress && agent.visitingAddress.id"
                      >edit</span
                    >
                    <span v-else>add</span>
                  </v-btn></v-card-title
                >
                <v-card-text
                  v-if="agent.visitingAddress && agent.visitingAddress.id"
                >
                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Street</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ agent.visitingAddress.line1 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ agent.visitingAddress.line2 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ agent.visitingAddress.line3 }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Postcode</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.visitingAddress.postCode }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">City</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.visitingAddress.city }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Country</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            v-if="
                              agent.visitingAddress &&
                                agent.visitingAddress.country
                            "
                            class="font-weight-medium grey--text text--darken-2"
                            >{{
                              getFullCountryName(agent.visitingAddress.country)
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Email</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.visitingAddress.email }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Phone</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.visitingAddress.phone }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title class="mb-3"
                  ><span>Billing Address</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((agent && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager'))) &&
                        agent.visitingAddress
                    "
                    rounded
                    text
                    small
                    color="info"
                    class="mr-2"
                    :disabled="processing"
                    @click="copyAddress('visiting')"
                  >
                    same as visiting
                  </v-btn>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((agent && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editAddress('BILLING')"
                  >
                    <span v-if="agent.billingAddress && agent.billingAddress.id"
                      >edit</span
                    >
                    <span v-else>add</span>
                  </v-btn></v-card-title
                >
                <v-card-text
                  v-if="agent.billingAddress && agent.billingAddress.id"
                >
                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Street</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ agent.billingAddress.line1 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ agent.billingAddress.line2 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ agent.billingAddress.line3 }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Postcode</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.billingAddress.postCode }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">City</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.billingAddress.city }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Country</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            v-if="
                              agent.billingAddress &&
                                agent.billingAddress.country
                            "
                            class="font-weight-medium grey--text text--darken-2"
                            >{{
                              getFullCountryName(agent.billingAddress.country)
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Email</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.billingAddress.email }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Phone</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ agent.billingAddress.phone }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { getName } from 'country-list'
import AgentEditDialog from '@/modules/sales/components/core/AgentEditDialog.vue'
import AddressEditDialog from '@/modules/sales/components/core/AddressEditDialog.vue'
import ContactEditDialog from '@/modules/sales/components/core/ContactEditDialog.vue'
import AGENT_UPDATE from '@/graphql/AgentUpdate.gql'
import ADDRESS_CREATE from '@/graphql/AgentAddressCreate.gql'
import ADDRESS_UPDATE from '@/graphql/AgentAddressUpdate.gql'
import CONTACT_CREATE from '@/graphql/AgentContactCreate.gql'
import CONTACT_UPDATE from '@/graphql/AgentContactUpdate.gql'
import CONTACT_REMOVE from '@/graphql/AgentContactRemove.gql'

export default {
  name: 'AgentDetail',
  components: { AgentEditDialog, AddressEditDialog, ContactEditDialog },
  props: {
    agent: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    dialog: false,
    addressDialog: false,
    contactDialog: false,
    removeContactDialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    removeContactID: null,
    defaultItem: {
      id: null,
      name: '',
      commerceReg: '',
      shippingNumber: '',
      vatId: '',
      status: '',
      website: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: ''
    },
    editedItem: {
      id: null,
      name: '',
      commerceReg: '',
      shippingNumber: '',
      vatId: '',
      status: '',
      website: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: ''
    },
    addressDefaultItem: {
      id: null,
      agentID: null,
      customerID: null,
      line1: null,
      line2: null,
      line3: null,
      postCode: null,
      city: null,
      country: null,
      addressType: null,
      email: null,
      phone: null
    },
    addressEditedItem: {
      id: null,
      agentID: null,
      customerID: null,
      line1: null,
      line2: null,
      line3: null,
      postCode: null,
      city: null,
      country: null,
      addressType: null,
      email: null,
      phone: null
    },
    contactDefaultItem: {
      id: null,
      title: null,
      firstName: null,
      lastName: null,
      status: 'ACTIVE',
      type: null,
      isUser: false,
      email: null,
      phone: null
    },
    contactEditedItem: {
      id: null,
      title: null,
      firstName: null,
      lastName: null,
      status: null,
      type: null,
      isUser: false,
      email: null,
      phone: null
    }
  }),
  computed: {
    contactHeaders() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'email'
        },
        {
          text: 'Phone',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'phone'
        },
        {
          text: 'Status',
          value: 'status',
          visible: false,
          sortable: false
        },
        {
          text: '',
          value: 'controls',
          align: 'right',
          visible: false,
          sortable: false
        }
      ]
    }
  },
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    editAddress(addressType) {
      if (addressType === 'VISITING') {
        if (
          this.agent?.visitingAddress?.id &&
          this.agent?.visitingAddress?.addressType === 'VISITING'
        ) {
          this.addressEditedItem = this._.cloneDeep(this.agent.visitingAddress)
          this.editMode = true
        } else {
          this.addressEditedItem = this._.cloneDeep(this.addressDefaultItem)
          this.addressEditedItem.addressType = 'VISITING'
        }
      }

      if (addressType === 'BILLING') {
        if (
          this.agent?.billingAddress?.id &&
          this.agent?.billingAddress?.addressType === 'BILLING'
        ) {
          this.addressEditedItem = this._.cloneDeep(this.agent.billingAddress)
          this.editMode = true
        } else {
          this.addressEditedItem = this._.cloneDeep(this.addressDefaultItem)
          this.addressEditedItem.addressType = 'BILLING'
        }
      }

      this.addressEditedItem.agentID = this.$route.params.agentID

      this.addressDialog = true
    },
    copyAddress() {
      this.editMode = false
      var data = {
        agentID: this.$route.params.agentID,
        line1: this.agent?.visitingAddress?.line1,
        line2: this.agent?.visitingAddress?.line2,
        line3: this.agent?.visitingAddress?.line3,
        postCode: this.agent?.visitingAddress?.postCode,
        city: this.agent?.visitingAddress?.city,
        country: this.agent?.visitingAddress?.country,
        addressType: 'BILLING',
        email: this.agent?.visitingAddress?.email,
        phone: this.agent?.visitingAddress?.phone
      }
      this.saveAddress(data)
    },
    editContact(item) {
      if (item?.id) {
        this.editMode = true
        this.contactEditedItem = this._.cloneDeep(item)
      } else {
        this.contactEditedItem = this._.cloneDeep(this.contactDefaultItem)
        this.contactEditedItem.agentID = this.$route.params.agentID
        this.contactEditedItem.type = 'AGENT'
      }

      this.contactDialog = true
    },
    openRemoveContactDialog(id) {
      this.removeContactID = id
      this.removeContactDialog = true
    },
    editAgent() {
      this.editedItem = this._.cloneDeep(this.agent)
      this.editMode = true
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.addressDialog = false
      this.contactDialog = false
      this.removeContactDialog = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.addressEditedItem = this._.cloneDeep(this.addressDefaultItem)
      this.contactEditedItem = this._.cloneDeep(this.contactDefaultItem)
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: AGENT_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('refetch-agent')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Agent Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    saveAddress(item) {
      this.errorMsg = ''
      this.processing = true

      if (this.editMode) {
        this.$apollo
          .mutate({
            mutation: ADDRESS_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-agent')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Address Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: ADDRESS_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-agent')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Address Created'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    saveContact(item) {
      this.errorMsg = ''
      this.processing = true

      if (this.editMode) {
        this.$apollo
          .mutate({
            mutation: CONTACT_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-agent')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Contact Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: CONTACT_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-agent')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Contact Created'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    removeContact() {
      this.$apollo
        .mutate({
          mutation: CONTACT_REMOVE,
          variables: {
            id: this.removeContactID
          }
        })
        .then(() => {
          this.$emit('refetch-agent')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Contact Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
