<template>
  <v-row>
    <add-to-cart-dialog
      :sales-order="salesOrder"
      :product="product"
      :stock-items="stockItems.items"
      :edited-item="editedItem"
      :auth-level="authLevel"
      :current-user="currentUser"
      :dialog="dialog"
      :edit-mode="editMode"
      :error-msg="errorMsg"
      :processing="processing"
      @close-add-to-cart-dialog="closeProductDialog"
      @save-add-to-cart-dialog="saveOrderLine"
    ></add-to-cart-dialog>
    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Product</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the item and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closeProductDialog"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="removeOrderLine">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12" class="pt-0">
      <v-toolbar flat rounded>
        <v-row justify="end" class="text-h6 font-weight-medium pr-3">
          <v-col>
            <v-row v-if="salesOrder">
              <v-col>
                {{ itemCount }}

                <span
                  class="grey--text text--darken-1 text-subtitle-1 font-weight-normal"
                  >Products</span
                >
              </v-col>
              <v-col>
                {{ salesOrder.qty }}
                <span
                  class="grey--text text--darken-1 text-subtitle-1 font-weight-normal"
                  >Units</span
                >
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-row justify="end">
                  <div class="grey--text text--darken-3">
                    <span class="grey--text text-subtitle-1 pr-3"
                      >Total Amount
                    </span>
                    {{
                      (salesOrder.subTotal / 100)
                        | currency('€', 2, {
                          spaceBetweenAmountAndSymbol: true
                        })
                    }}
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-col>

    <v-col cols="12" class="py-0">
      <v-data-iterator
        :items="salesOrderLines"
        :search="search"
        :loading="$apollo.loading"
        :items-per-page="-1"
        hide-default-footer
      >
        <template v-slot:default="{ items }">
          <v-row v-for="line in orderBy(items, 'id')" :key="line.id">
            <v-col cols="12">
              <v-card flat class="card-bordered-alt">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="3" lg="2" class="mb-3">
                      <v-row>
                        <v-img
                          v-if="line.product.frontImage"
                          :src="line.product.frontImage"
                          max-height="150px"
                          contain
                        ></v-img>
                        <v-img
                          v-else
                          src="/DAILY_PAPER_LOGO.png"
                          max-height="50px"
                          class="mt-6"
                          contain
                        ></v-img>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="9" lg="10" class="mb-3">
                      <v-row justify="end">
                        <div class="text-body-2 text-truncate pl-3 mb-3">
                          {{ line.product.name }}
                        </div>
                        <v-spacer></v-spacer>
                        <v-btn
                          v-if="
                            salesOrder &&
                              salesOrder.state &&
                              (salesOrder.state === 'DRAFT' ||
                                salesOrder.state === 'SUBMITTED') &&
                              salesOrder.lines &&
                              salesOrder.lines.length > 0 &&
                              ((authLevel && authLevel === 2) ||
                                (currentUser &&
                                  (currentUser.isAdmin ||
                                    currentUser.isSuperAdmin ||
                                    currentUser.userRole === 'manager' ||
                                    currentUser.userRole === 'seniormanager' ||
                                    currentUser.userRole === 'director')))
                          "
                          text
                          x-small
                          color="grey lighten-1"
                          @click="openRemoveDialog(line.id)"
                          >Remove</v-btn
                        >
                      </v-row>
                      <v-row no-gutters>
                        <v-col
                          ><div class="grey--text text-caption">
                            {{ line.product.styleNumber }}
                          </div>
                          <div class="grey--text text-caption">
                            {{ line.product.season.shortName }}
                          </div>
                          <div class="grey--text text-caption">
                            {{ line.product.seasonColor }}
                          </div>
                          <div class="grey--text text-caption">
                            {{ line.product.articleGroup.name }}
                          </div>
                          <div class="grey--text text-caption">
                            {{ line.product.textileContent }}
                          </div>
                          <div class="grey--text text-caption pt-6">
                            {{ line.notes }}
                          </div></v-col
                        >
                        <v-col>
                          <v-row no-gutters>
                            <v-col cols="12" class="pb-2">
                              <div class="grey--text text-caption">
                                Wholesale Price
                              </div>
                              <div>
                                {{
                                  (line.wholesalePrice / 100)
                                    | currency(line.currencySymbol, 2, {
                                      spaceBetweenAmountAndSymbol: true
                                    })
                                }}
                              </div>
                            </v-col>
                            <v-col cols="12" class="py-3">
                              <div class="grey--text text-caption">
                                RRP
                              </div>
                              <div>
                                {{
                                  (line.retailPrice / 100)
                                    | currency(line.currencySymbol, 2, {
                                      spaceBetweenAmountAndSymbol: true
                                    })
                                }}
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mt-6">
                    <v-col
                      v-for="item in sizeFilter(line.items)"
                      :key="item.id"
                      cols="12"
                      sm="3"
                      md="2"
                      lg="1"
                      xl="1"
                    >
                      <v-row no-gutters>
                        <v-col
                          cols="12"
                          class="pa-2 text-truncate"
                          style="border: 1px solid var(--v-light-theme-darken1);border-radius: 4px;"
                        >
                          <span>{{ item.name }} |</span>
                          <strong> {{ item.quantity }}</strong>
                        </v-col>
                      </v-row>
                      <v-row
                        v-if="salesOrder.type === 'RETURN'"
                        no-gutters
                        class="pt-5"
                      >
                        <v-col cols="12">
                          Received
                        </v-col>
                        <v-col
                          cols="12"
                          class="pa-2 text-truncate"
                          style="border: 1px solid var(--v-light-theme-darken1);border-radius: 4px;"
                        >
                          <span>{{ item.name }} |</span>
                          <strong> {{ item.fulfilledQuantity }}</strong>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row class="mt-3">
                    <v-col>
                      <v-btn
                        v-if="
                          salesOrder &&
                            salesOrder.state &&
                            (salesOrder.state === 'DRAFT' ||
                              salesOrder.state === 'SUBMITTED') &&
                            salesOrder.lines &&
                            salesOrder.lines.length > 0 &&
                            ((authLevel && authLevel === 2) ||
                              (currentUser &&
                                (currentUser.isAdmin ||
                                  currentUser.isSuperAdmin ||
                                  currentUser.userRole === 'manager' ||
                                  currentUser.userRole === 'seniormanager' ||
                                  currentUser.userRole === 'director')))
                        "
                        text
                        x-small
                        rounded
                        color="info"
                        @click="openProductDialog(line)"
                        >edit item</v-btn
                      >
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-row justify="end">
                        <v-col>
                          <div class="text-right">
                            <span class="grey--text text-caption">Units:</span>
                            <span class="pl-3 font-weight-medium">{{
                              line.quantity
                            }}</span>
                          </div></v-col
                        >
                        <v-col>
                          <div class="text-right">
                            <span class="grey--text text-caption">Amount:</span>
                            <span class="pl-3 font-weight-medium">{{
                              (line.net / 100)
                                | currency(line.currencySymbol, 2, {
                                  spaceBetweenAmountAndSymbol: true
                                })
                            }}</span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <template v-slot:loading>
          <v-row>
            <v-col v-for="i in 10" :key="i" cols="12" md="2">
              <v-sheet color="grey lighten-4" class="pa-3">
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item"
                ></v-skeleton-loader>
              </v-sheet>
            </v-col>
          </v-row>
        </template>
        <template v-slot:no-data>
          <v-row justify="center">
            <message-box
              title="No Items"
              caption="Add items to the this order from the shop page"
              icon="mdi-cart-variant"
            ></message-box>
          </v-row>
        </template>
      </v-data-iterator>
    </v-col>
  </v-row>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import AddToCartDialog from '../components/core/AddToCartDialog.vue'
import MessageBox from '@/components/core/MessageBox.vue'
import STOCK_ITEM_LIST from '@/graphql/StockItems.gql'
import SALES_ORDER_LINE_UPDATE from '@/graphql/SalesOrderLineUpdate.gql'
import SALES_ORDER_LINE_REMOVE from '@/graphql/SalesOrderLineRemove.gql'

export default {
  name: 'CustomerProductCart',
  components: { AddToCartDialog, MessageBox },
  mixins: [Vue2Filters.mixin],
  props: {
    seasons: {
      type: Object,
      default: () => {
        return { seasons: [] }
      }
    },
    salesOrder: { type: Object, default: () => ({ lines: [] }) },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    articleGroups: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    dialog: false,
    removeDialog: false,
    errorMsg: '',
    processing: false,
    search: null,
    removeableID: null,
    editMode: false,
    products: [],
    editedItem: {},
    product: {},
    stockItems: {}
  }),
  apollo: {},
  computed: {
    itemCount() {
      return this.salesOrder?.lines?.length || 0
    },
    salesOrderLines() {
      if (this.salesOrder?.lines?.length > 0) {
        return this._.orderBy(this.salesOrder.lines, ['createdAt'], ['asc'])
      } else {
        return []
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    sizeFilter(items) {
      return this._.chain(items).filter(line => line.quantity > 0).sortBy('order').value()
    },
    openProductDialog(item) {
      this.product = item.product

      this.editedItem = item

      this.$apollo
        .query({
          query: STOCK_ITEM_LIST,
          variables: {
            filters: {
              nameSearch: null,
              warehouseID: this.salesOrder?.warehouse?.id,
              productID: item?.product?.id,
              page: 1,
              pageSize: 100
            }
          },
          fetchPolicy: 'network-only'
        })
        .then(result => {
          this.stockItems = result.data.stockItems
          this.editMode = true
          this.dialog = true
        })
    },
    openRemoveDialog(id) {
      this.removeableID = id
      this.removeDialog = true
    },
    closeProductDialog() {
      this.editedItem = this._.cloneDeep({})
      this.removeableID = null

      this.errorMsg = ''
      this.dialog = false

      this.removeDialog = false
      this.processing = false
    },
    saveOrderLine(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_LINE_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('refresh-sales-order')
          this.closeProductDialog()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Product Added'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    removeOrderLine() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_LINE_REMOVE,
          variables: {
            id: this.removeableID
          }
        })
        .then(() => {
          this.$emit('refresh-sales-order')
          this.closeProductDialog()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Order Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.card-bordered-alt {
  border: 2px solid rgb(229, 237, 244);
}
</style>
