<template>
  <div class="page-wrap">
    <agent-detail-header
      :agent="agent"
      :auth-level="authLevel"
    ></agent-detail-header>

    <div class="main-page-column">
      <div class="scroll-container">
        <router-view
          :agent="agent"
          :current-user="currentUser"
          :auth-level="authLevel"
          :currencies="currencies"
          :loading="$apollo.loading"
          @refetch-agent="$apollo.queries.agent.refetch()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AgentDetailHeader from '@/modules/sales/components/core/AgentDetailHeader.vue'
import AGENT from '@/graphql/Agent.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'

export default {
  name: 'AgentDetailLayout',
  components: {
    AgentDetailHeader
  },
  data: () => ({}),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('agents')
    }
  },
  apollo: {
    agent: {
      query: AGENT,
      variables() {
        return {
          id: this.$route.params.agentID
        }
      }
    },
    currencies: CURRENCY_LIST
  },
  watch: {},
  created() {},
  methods: {}
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 92px;
  /* --page-wrap-offset: -20px; */
}
</style>
