<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header pt-0 px-1 pr-6">
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <line-sheet-edit-dialog
            :dialog="dialog"
            :line-sheet="editedItem"
            :seasons="seasons.seasons"
            :edit-mode="edit"
            :processing="processing"
            :error-msg="errorMsg"
            @close-line-sheet-edit-dialog="close"
            @save-line-sheet-edit-dialog="save"
          ></line-sheet-edit-dialog>
          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-btn color="primary" rounded @click="newItem">
                  add linesheet
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn-toggle v-model="gridStyle" dense borderless>
                  <v-btn text :class="[gridStyle === 0 ? 'v-btn--active' : '']"
                    ><v-icon>mdi-view-grid</v-icon></v-btn
                  >
                  <v-btn text :class="[gridStyle === 1 ? 'v-btn--active' : '']"
                    ><v-icon>mdi-view-agenda</v-icon></v-btn
                  >
                </v-btn-toggle>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0 pr-3">
        <div class="scroll-container pt-0">
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search..."
                background-color="white"
                single-line
                hide-details
                clearable
                outlined
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>
          <div v-if="gridStyle === 0">
            <v-data-iterator
              :items="lineSheets.items"
              :search="search"
              :options.sync="options"
              :loading="$apollo.loading"
              :mobile-breakpoint="null"
              class="plain-table"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
              :hide-default-footer="itemCount < options.itemsPerPage + 1"
              :hide-default-header="lineSheets.itemCount === 0"
              @click:row="navToDetail"
            >
              <template v-slot:default="{ items }">
                <v-row>
                  <v-col v-for="item in items" :key="item.id" class="12" md="3">
                    <v-card class="mx-auto">
                      <v-img height="125" :src="item.image"></v-img>

                      <v-card-title>{{ item.name }}</v-card-title>

                      <v-card-text>
                        <!-- <div>
                          {{ item.season.shortName }}
                        </div> -->
                        <v-row class="mx-0">
                          <div>
                            <active-inactive-label
                              :state="item.state"
                            ></active-inactive-label>
                          </div>
                        </v-row>

                        <div class="my-4 text-subtitle-1">
                          {{ item.owner.firstName + ' ' + item.owner.lastName }}
                        </div>
                        <v-icon
                          v-if="item.preBookStart || item.preBookEnd"
                          small
                          class="info--text mr-2"
                          >mdi-calendar-month-outline</v-icon
                        >
                        <span
                          >{{ item.preBookStart | moment('DD MMM YY') }}
                        </span>
                        <span v-if="item.preBookStart && item.preBookEnd"
                          >&nbsp;-&nbsp;</span
                        >
                        <span
                          >{{ item.preBookEnd | moment('DD MMM YY') }}
                        </span>
                      </v-card-text>

                      <v-card-actions class="pt-0">
                        <v-btn
                          color="info"
                          text
                          rounded
                          @click="navToDetail(item)"
                        >
                          view
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </v-data-iterator>
          </div>

          <v-card v-if="gridStyle === 1" flat class="my-3">
            <!-- <v-card-title>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
                clearable
                autocomplete="off"
                :disabled="!lineSheets || lineSheets.itemCount === 0"
              ></v-text-field>
            </v-card-title> -->
            <v-data-table
              :headers="headers"
              :items="lineSheets.items"
              :search="search"
              :options.sync="options"
              :loading="$apollo.loading"
              :mobile-breakpoint="null"
              class="plain-table"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
              :hide-default-footer="itemCount < options.itemsPerPage + 1"
              :hide-default-header="lineSheets.itemCount === 0"
              @click:row="navToDetail"
            >
              <template v-slot:item.image="{ item }">
                <v-img tumbnail max-width="150" :src="item.image"></v-img>
              </template>
              <template v-slot:item.state="{ item }">
                <active-inactive-label
                  :state="item.state"
                ></active-inactive-label>
              </template>
              <template v-slot:item.preBookStart="{ item }">
                <v-icon
                  v-if="item.preBookStart || item.preBookEnd"
                  small
                  class="info--text mr-2"
                  >mdi-calendar-month-outline</v-icon
                >
                <span>{{ item.preBookStart | moment('DD MMM YY') }} </span>
                <span v-if="item.preBookStart && item.preBookEnd"
                  >&nbsp;-&nbsp;</span
                >
                <span>{{ item.preBookEnd | moment('DD MMM YY') }} </span>
              </template>
              <template v-slot:item.season="{ item }">
                <span v-if="item.season && item.season.id">{{
                  item.season.shortName
                }}</span>
              </template>

              <template v-slot:item.owner="{ item }">
                <div v-if="item.owner && item.owner.id">
                  {{ item.owner.firstName + ' ' + item.owner.lastName }}
                </div>

                <div v-if="item.owner && item.owner.id" class="caption">
                  {{ item.owner.email }}
                </div>
              </template>
              <template v-slot:no-data>Nothing here yet...</template>
              <!-- <template v-slot:item.controls="{ item }">
                    <v-btn icon small
                      ><v-icon small @click.stop="editItem(item)"
                        >mdi-pencil-outline</v-icon
                      ></v-btn
                    >
                  </template> -->
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActiveInactiveLabel from '@/components/core/ActiveInactiveLabel.vue'
import LINESHEETS from '@/graphql/LineSheets.gql'
import LINE_SHEET_CREATE from '@/graphql/LineSheetCreate.gql'
import SEASONS from '@/graphql/Seasons.gql'
import LineSheetEditDialog from '@/modules/sales/components/core/LineSheetEditDialog.vue'

export default {
  name: 'LineSheetList',
  components: { LineSheetEditDialog, ActiveInactiveLabel },
  data: () => ({
    dialog: false,
    gridStyle: 0,
    edit: false,
    search: '',
    itemCount: 0,
    errorMsg: '',
    processing: false,
    options: { page: 1, itemsPerPage: 15 },
    lineSheets: { items: [], itemCount: 0, page: 1 },
    seasons: { seasons: [] },
    defaultItem: {
      id: null,
      name: null,
      description: null,
      seasonID: null,
      image: null,
      state: 'INACTIVE',
      prebookStartDate: new Date().toISOString(),
      prebookEndDate: new Date().toISOString()
    },
    editedItem: {
      id: null,
      name: null,
      description: null,
      seasonID: null,
      image: null,
      state: 'INACTIVE',
      prebookStartDate: new Date().toISOString(),
      prebookEndDate: new Date().toISOString()
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: '',
          align: 'left',
          sortable: false,
          filterable: false,
          width: 150,
          value: 'image'
        },
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: false,
          value: 'name'
        },
        {
          text: 'Season',
          align: 'left',
          sortable: false,
          filterable: false,
          value: 'season'
        },
        {
          text: 'Owner',
          align: 'left',
          sortable: false,
          filterable: false,
          value: 'owner'
        },
        {
          text: 'Status',
          value: 'state',
          sortable: false
        },
        {
          text: 'Prebook Period',
          align: 'left',
          value: 'preBookStart',
          filterable: true,
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('lineSheets')
    }
  },
  apollo: {
    lineSheets: {
      query: LINESHEETS,
      variables() {
        return {
          filters: {
            search: this.search,
            seasonID: this.selectedSeason,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        if (result?.data?.length > 0) {
          this.page = result.data.lineSheets.page
          this.itemsPerPage = result.data.lineSheets.pageSize
          this.itemCount = result.data.lineSheets.total

          // Set page state
          this.$store.commit('sales/SET_LINE_SHEETS_PAGE', this.options.page)
          this.$store.commit(
            'sales/SET_LINE_SHEETS_PAGE_SIZE',
            this.options.itemsPerPage
          )
          this.$store.commit(
            'sales/SET_LINE_SHEETS_SEASON',
            this.selectedSeason
          )
          this.$store.commit('sales/SET_LINE_SHEETS_SEARCH', this.search)
        }
      }
    },
    seasons: SEASONS
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    },
    gridStyle(val) {
      this.$store.commit('sales/SET_LINE_SHEETS_GRID', val)
    }
  },
  created() {
    var that = this
    var filters = this.$store.state.sales.lineSheetsPage
    that.gridStyle = filters.gridStyle
    window.onpopstate = function() {
      // Apply state filters
      that.options.page = filters.page
      that.options.itemsPerPage = filters.pageSize
      // that.selectedSeason = filters.season
      that.owner = filters.owner
      that.season = filters.season

      if (filters.search !== '') that.search = filters.search
      // that.backNav = false
    }
  },
  methods: {
    close(success, id) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      this.errorMsg = ''
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Line Sheet Saved'
        })

        this.$router.push({
          name: 'LineSheetDetail',
          params: { seasonID: id }
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    navToDetail(item) {
      this.$router.push({
        name: 'LineSheetDetail',
        params: { lineSheetID: item.id }
      })
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: LINE_SHEET_CREATE,
          variables: {
            input: item
          }
        })
        .then(result => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Line Sheet Added'
          })
          this.$router.push({
            name: 'LineSheetDetail',
            params: { lineSheetID: result.data.createLineSheet }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style lang="scss"></style>
