<template>
  <span>{{ returnPolicyFormat(returnPolicy) }}</span>
</template>

<script>
export default {
  name: 'ReturnPolicyFormatter',
  props: {
    returnPolicy: {
      type: Object,
      default: () => ({}),
      required: true
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  methods: {
    returnPolicyFormat(item) {
      if (item.allowed) {
        return `Allowed up to ${this.currencySymbol}${item.amount.toFixed(2)}`
      }

      return 'Not allowed'
    }
  }
}
</script>

<style></style>
