<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container">
          <v-form
            ref="customerForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="item.agent"
                      :items="agents.items"
                      :search-input="search"
                      :disabled="subitem"
                      autocomplete="off"
                      label="Agent"
                      hide-details="auto"
                      outlined
                      item-text="name"
                      :rules="requiredSelectRule"
                      dense
                      return-object
                    ></v-autocomplete>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.companyName"
                          autocomplete="off"
                          label="Company Name"
                          hide-details="auto"
                          outlined
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.storeName"
                          autocomplete="off"
                          label="Store Name"
                          hide-details="auto"
                          outlined
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.commerceReg"
                          autocomplete="off"
                          label="Company Registration"
                          persistent-hint
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Priority</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.shippingNumber"
                          autocomplete="off"
                          label="Shipping Number"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="item.preNotification"
                          autocomplete="off"
                          label="Pre Notification"
                          hide-details="auto"
                          class="mt-0"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          v-model="item.deliveryConditions"
                          :items="['DAP', 'EXW', 'DDP']"
                          autocomplete="off"
                          label="DeliveryConditions"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="item.warehouse"
                          :items="warehouses.items"
                          outlined
                          dense
                          item-text="name"
                          return-object
                          hide-details="auto"
                          label="Warehouse"
                          :rules="requiredRule"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.promeseID"
                          autocomplete="off"
                          label="Promese ID"
                          hint="This will auto generate. Only override if you know what you are doing."
                          persistent-hint
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col md="6" class="py-0">
                    <v-row>
                      <v-col cols="12" class="py-0">
                        <v-select
                          v-model="item.currencyID"
                          :items="currencies"
                          :rules="requiredRule"
                          outlined
                          autocomplete
                          label="Currency"
                          item-value="id"
                          item-text="isoName"
                          hide-details="auto"
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.eoriNumber"
                          autocomplete="off"
                          label="EORI Number"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.vatId"
                          autocomplete="off"
                          label="VAT ID"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.danNumber"
                          autocomplete="off"
                          label="DAN Number"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.website"
                          autocomplete="off"
                          label="Website"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.linkedin"
                          autocomplete="off"
                          label="Linkedin"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.facebook"
                          autocomplete="off"
                          label="Facebook"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.twitter"
                          outlined
                          autocomplete
                          hide-details="auto"
                          return-object
                          dense
                          label="Twitter"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.instagram"
                          autocomplete="off"
                          label="Instagram"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text color="grey lighten-1" :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import country from 'country-list'

export default {
  name: 'CustomerEditDialog',
  props: {
    customer: {
      type: Object,
      required: true,
      default: () => ({})
    },
    agents: {
      type: Object,
      required: true,
      default: () => ({ items: [], total: 0, page: 1 })
    },
    warehouses: {
      type: Object,
      required: true,
      default: () => ({ items: [], total: 0, page: 1 })
    },
    currencies: {
      type: Array,
      required: true,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    subitem: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    search: '',
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required']
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Customer' : 'New Customer'
    },
    item() {
      return this._.cloneDeep(this.customer)
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    }
  },
  watch: {
    search(val) {
      return this.$emit('agent-search', val)
    }
  },
  methods: {
    close() {
      this.$refs.customerForm.resetValidation()
      this.$emit('close-customer-edit-dialog', false)
    },
    save() {
      if (this.$refs.customerForm.validate()) {
        var data = {
          id: this.item.id,
          currencyID: this.item.currencyID,
          promeseID: this.item.promeseID,
          agentID: this.item.agent?.id,
          companyName: this.item.companyName,
          storeName: this.item.storeName,
          commerceReg: this.item.commerceReg,
          vatId: this.item.vatId,
          state: 'PENDINGCONTRACT',
          website: this.item.website,
          facebook: this.item.facebook,
          twitter: this.item.twitter,
          linkedin: this.item.linkedin,
          instagram: this.item.instagram,
          preNotification: this.item.preNotification,
          deliveryConditions: this.item.deliveryConditions,
          warehouseID: this.item.warehouse?.id,
          eoriNumber: this.item.eoriNumber,
          danNumber: this.item.danNumber,
          shippingNumber: this.item.shippingNumber
        }

        if (!this.editMode) {
          delete data.id
        }

        this.$emit('save-customer-edit-dialog', data)
      }
    }
  }
}
</script>

<style></style>
