<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header">
        <v-toolbar class="mb-3" color="white" rounded flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col cols="12">
              <h2 class="info--text text-truncate">
                Stock Level Report
              </h2>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <v-row justify="end" align="center" style="height: 40px;">
            <span class="pr-3 text-truncate">{{ itemCount }} Rows</span>
          </v-row>
        </v-toolbar>

        <v-toolbar class="mb-0" color="white" rounded flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col cols="4" md="3" lg="2">
              <v-select
                v-model="selectedWarehouses"
                :items="warehouses.items"
                outlined
                dense
                label="Warehouses"
                placeholder="All"
                item-text="name"
                item-value="id"
                color="info"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                multiple
                :disabled="loading"
              >
                <template v-slot:selection="{ index }">
                  <div
                    v-if="index == 0"
                    class="text-truncate"
                    style="width: 80%"
                  >
                    <span class="grey--text text--darken1 text-truncate"
                      >{{ selectedWarehouses.length }} of
                      {{ warehouses.items.length }}</span
                    >
                  </div>
                </template>
              </v-select>
            </v-col>

            <v-col class="pl-sm-0 pl-xs-0 pr-sm-1 pr-xs-1">
              <v-row
                align="center"
                justify="end"
                no-gutters
                style="height: 40px"
              >
                <!-- Report excel -->
                <div v-if="exportData.length > 0">
                  <xlsx-workbook style="width: 100%;">
                    <xlsx-sheet :collection="exportData" sheet-name="Sheet1" />
                    <xlsx-download :filename="fileName" class="px-0 mx-0">
                      <v-btn
                        color="info"
                        small
                        rounded
                        class="text-capitalize font-weight-regular text-body-1"
                        >Download Excel</v-btn
                      >
                    </xlsx-download>
                  </xlsx-workbook>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col v-if="reportData.length === 0 || loading || $apollo.loading">
              <v-card flat>
                <message-box
                  :icon="
                    $apollo.loading || loading ? '' : 'mdi-database-off-outline'
                  "
                  :title="
                    $apollo.loading || loading ? 'Loading...' : 'No Results'
                  "
                  :caption="
                    loading
                      ? 'Please sit back while we load your data'
                      : 'Try adjusting your filters to get some results'
                  "
                >
                  <v-progress-circular
                    v-if="$apollo.loading"
                    indeterminate
                    color="info"
                  ></v-progress-circular>
                </message-box>
              </v-card>
            </v-col>
            <v-col v-else>
              <v-data-table
                :headers="headers"
                :loading="$apollo.loading"
                :items="reportData"
                :items-per-page="-1"
                hide-default-footer
                :mobile-breakpoint="null"
              >
                <template v-slot:loading>
                  <v-skeleton-loader
                    v-for="item in 5"
                    :key="item"
                    type="list-item"
                    class="mx-auto"
                  ></v-skeleton-loader>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'
import MessageBox from '@/components/core/MessageBox.vue'
import STOCK_REPORT from '@/graphql/ReportStockLevels.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'

export default {
  name: 'ReportStockLevel',
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    MessageBox
  },
  data() {
    return {
      loading: false,
      reportData: [],
      exportData: [],

      headers: [
        {
          text: 'Barcode',
          value: 'barcode'
        },
        {
          text: 'Style Number',
          value: 'styleNumber'
        },
        {
          text: 'Season',
          value: 'season'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Color',
          value: 'color'
        },
        {
          text: 'Size',
          value: 'size'
        },
        {
          text: 'Fob Cost (EUR)',
          value: 'fobCost',
          align: 'end'
        },
        {
          text: 'Unit Cost (EUR)',
          value: 'unitCost',
          align: 'end'
        },
        {
          text: 'Wholesale Price (EUR)',
          value: 'wholesalePrice',
          align: 'end'
        },
        {
          text: 'Retail Price (EUR)',
          value: 'retailPrice',
          align: 'end'
        },
        {
          text: 'Production Quantity',
          value: 'productionQuantity',
          align: 'end'
        },
        {
          text: 'Physical Quantity',
          value: 'physicalQuantity',
          align: 'end'
        },
        {
          text: 'Pickgin Quantity',
          value: 'pickingQuantity',
          align: 'end'
        },
        {
          text: 'Available Quantity',
          value: 'availableQuantity',
          align: 'end'
        }
      ],

      warehouses: { items: [] },
      selectedWarehouses: [],

      orderTypes: ['PREBOOK', 'REORDER', 'RETURN'],
      selectedOrderType: null
    }
  },
  computed: {
    fileName() {
      return `stock_levels_${this.$moment().format('DDMMYYYY')}.xlsx`
    },
    itemCount() {
      return this.reportData?.length
    }
  },
  watch: {},
  apollo: {
    stockReport: {
      query: STOCK_REPORT,
      variables() {
        return {
          filters: {
            warehouses: this.selectedWarehouses
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 100,
      result(result) {
        if (result && result.data && result.data.stockReport) {
          this.convertedReportFile(result.data.stockReport)
        } else {
          this.reportData = []
        }
        this.loading = false
      }
      // skip() {
      //   return (
      //     this.selectedStyleStates?.length === 0 ||
      //     this.selectedProductStates.length === 0
      //   )
      // }
    },
    warehouses: WAREHOUSE_LIST
  },
  methods: {
    convertedReportFile(data) {
      if (data && data.length > 0) {
        this.reportData = []
        this.exportData = []

        this.reportData = this._.each(data, function(item) {
          item.wholesalePrice = (item.wholesalePrice / 100).toFixed(2)
          item.retailPrice = (item.retailPrice / 100).toFixed(2)
          item.fobCost = (item.fobCost / 100).toFixed(2)
          item.unitCost = (item.unitCost / 100).toFixed(2)
          item.total = (item.total / 100).toFixed(2)
          delete item.__typename
        })

        // Create export data
        this.reportData.forEach(item => {
          this.exportData.push({
            Barcode: item.barcode,
            Season: item.season,
            'Style Number': item.styleNumber,
            Name: item.name,
            Color: item.color,
            Size: item.size,
            'Fob Cost (EUR)': parseFloat(item.fobCost),
            'Unit Cost (EUR)': parseFloat(item.unitCost),
            'Wholesale Price (EUR)': parseFloat(item.wholesalePrice),
            'Retail Price (EUR)': parseFloat(item.retailPrice),
            'Production Qty': parseFloat(item.productionQuantity),
            'Physical Qty': parseFloat(item.physicalQuantity),
            'Picking Qty': parseFloat(item.pickingQuantity),
            'Available Qty': parseFloat(item.availableQuantity)
          })
        })
      } else {
        this.reportData = []
      }
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 128px;
  /* --page-wrap-offset: -20px; */
}
</style>
