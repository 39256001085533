<template>
  <v-row justify="center">
    <v-dialog v-model="removeDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Agent</v-card-title
        >
        <v-card-text>
          <p>
            This will delete this agent. This will also remove all items
            associated with this agent.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closeRemoveDialog(false)"
            >Cancel</v-btn
          >
          <v-btn color="error" text @click="openSecondRemoveDialog"
            >Remove</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="secondRemoveDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Are you really sure?</v-card-title
        >
        <v-card-text>
          <p>
            This operation can't be undone!
          </p>

          <v-checkbox
            v-model="removalConfirmation"
            color="error"
            label="I accept the concequences!"
          >
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="closeRemoveDialog(false)"
            >Cancel</v-btn
          >
          <v-btn
            color="error"
            text
            :disabled="!removalConfirmation"
            @click="removeAgent"
            >Remove Agent</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col v-if="agent" cols="12" md="10" lg="8" xl="6">
      <v-card v-if="currentUser && authLevel === 2" flat class="mx-auto">
        <v-card-title class="mb-3">Delete Agent</v-card-title>
        <v-card-subtitle
          >Once you delete this Agent, you will lose all data associated with
          it.</v-card-subtitle
        >

        <v-card-text>
          <v-btn
            v-if="
              agent &&
                authLevel &&
                authLevel === 2 &&
                currentUser &&
                (currentUser.isSuperAdmin ||
                  currentUser.userRole === 'director' ||
                  currentUser.userRole === 'seniormanager' ||
                  currentUser.userRole === 'projectmanager')
            "
            color="error lighten-2"
            depressed
            rounded
            small
            class="mt-3 upperCaseSpacing"
            @click="openSecondRemoveDialog"
          >
            delete agent
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import REMOVE_AGENT from '@/graphql/AgentRemove.gql'

export default {
  name: 'AgentSettings',
  components: {},
  props: {
    agent: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  data: () => ({
    processing: false,
    errorMsg: '',
    editMode: false,
    removeDialog: false,
    secondRemoveDialog: false,
    removalConfirmation: false
  }),
  computed: {},
  methods: {
    closeRemoveDialog(success) {
      this.removeDialog = false
      this.secondRemoveDialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Agent Removed'
        })
      }
    },

    openSecondRemoveDialog() {
      this.removalConfirmation = false
      this.removeDialog = false
      this.secondRemoveDialog = true
    },

    removeAgent() {
      var that = this
      if (this.removalConfirmation) {
        this.$apollo
          .mutate({
            mutation: REMOVE_AGENT,
            variables: {
              id: that.agent.id
            }
          })
          .then(() => {
            this.closeRemoveDialog(true)

            this.$router.replace({ name: 'AgentList' })
          })
      }
    }
  }
}
</script>

<style></style>
