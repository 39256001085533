<template>
  <v-row>
    <customer-edit-dialog
      :dialog="dialog"
      :customer="editedItem"
      :agents="agents"
      :currencies="currencies"
      :warehouses="warehouses"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-customer-edit-dialog="close"
      @save-customer-edit-dialog="save"
      @agent-search="agentSearch"
    ></customer-edit-dialog>
    <address-edit-dialog
      :dialog="addressDialog"
      :address="addressEditedItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-address-edit-dialog="close"
      @save-address-edit-dialog="saveAddress"
    ></address-edit-dialog>
    <contact-edit-dialog
      :dialog="contactDialog"
      :contact="contactEditedItem"
      :edit-mode="editMode"
      :processing="processing"
      :error-msg="errorMsg"
      @close-contact-edit-dialog="close"
      @save-contact-edit-dialog="saveContact"
    ></contact-edit-dialog>
    <v-dialog v-model="removeContactDialog" persistent max-width="350">
      <v-card>
        <v-card-title class="headline error--text mb-3"
          >Remove Contact</v-card-title
        >
        <v-card-text>
          <p>
            This will remove the contact and can't be undone.
          </p>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
          <v-btn color="error" text @click="removeContact">Remove</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-col v-if="customer" cols="12" class="py-0">
      <v-row>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title class="mb-3"
                  ><span>Details</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((customer && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editCustomer"
                  >
                    edit
                  </v-btn></v-card-title
                >
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Store</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.storeName }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Company</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.companyName }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Agent</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            v-if="customer.agent"
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.agent.name }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Company Registration</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.commerceReg }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >DAN Number</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.danNumber }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >EORI Number</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.eoriNumber }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >VAT Registration</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.vatId }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Pre Notification</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.preNotification }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Delivery Conditions</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.deliveryConditions }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Currency</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ lookupCurrencyName(customer.currencyID) }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Warehouse</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <div class="text-truncate">
                            <span
                              v-if="customer.warehouse"
                              class="font-weight-medium grey--text text--darken-2"
                              >{{ customer.warehouse.name }}</span
                            ><span
                              v-if="customer.warehouse.provider"
                              class="pl-3"
                              >({{ customer.warehouse.provider.name }})</span
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Shipping Number</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.shippingNumber }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Website</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <a
                            v-if="customer.website"
                            :href="customer.website"
                            target="_blank"
                            >{{ customer.website }}</a
                          >
                          <span
                            v-else
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.website }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Promese ID</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.promeseID }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Facebook</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.facebook }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Instagram</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.instagram }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Linkedin</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.linkedin }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Twitter</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.twitter }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Last Update</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{
                              customer.updatedAt | moment('from', 'now')
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title class="mb-3"
                  ><span>Contacts</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((customer && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editContact"
                  >
                    add contact
                  </v-btn></v-card-title
                >
                <v-data-table
                  v-if="customer.contacts"
                  :headers="contactHeaders"
                  :items="customer.contacts"
                  :items-per-page="-1"
                  hide-default-footer
                >
                  <template v-slot:item.name="{ item }">
                    <div class="text-truncate">
                      <span v-if="item.title">{{ item.title + ' ' }}</span>
                      <span>{{ item.firstName + ' ' + item.lastName }}</span>
                    </div>
                  </template>

                  <template v-slot:item.status="{ item }">
                    <div class="text-truncate">
                      <v-chip
                        v-if="item.status == 'ACTIVE'"
                        x-small
                        label
                        outlined
                        color="success"
                        >{{ item.status }}</v-chip
                      >
                      <v-chip v-else x-small label outlined>{{
                        item.status
                      }}</v-chip>

                      <v-tooltip v-if="item.isUser" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            x-small
                            label
                            outlined
                            color="info"
                            class="ml-2"
                            v-bind="attrs"
                            v-on="on"
                            >portal</v-chip
                          >
                        </template>
                        <span>Contact Has Access To Partner Portal</span>
                      </v-tooltip>
                    </div>
                  </template>
                  <template v-slot:item.controls="{ item }">
                    <div class="text-truncate">
                      <v-btn
                        small
                        rounded
                        color="info"
                        text
                        class=" text-lowercase"
                        @click.prevent="editContact(item)"
                        >edit</v-btn
                      >
                      <v-btn
                        small
                        rounded
                        text
                        color="grey lighten-1"
                        class="ml-3 text-lowercase"
                        @click.prevent="openRemoveContactDialog(item.id)"
                        >delete</v-btn
                      >
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title class="mb-3"
                  ><span>Visiting Address</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((customer && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editAddress('VISITING')"
                  >
                    <span
                      v-if="
                        customer.visitingAddress && customer.visitingAddress.id
                      "
                      >edit</span
                    >
                    <span v-else>add</span>
                  </v-btn></v-card-title
                >
                <v-card-text
                  v-if="customer.visitingAddress && customer.visitingAddress.id"
                >
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Street</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ customer.visitingAddress.line1 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ customer.visitingAddress.line2 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ customer.visitingAddress.line3 }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Postcode</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.visitingAddress.postCode }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">City</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.visitingAddress.city }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">Province/State</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.visitingAddress.state }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Country</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            v-if="
                              customer.visitingAddress &&
                                customer.visitingAddress.country
                            "
                            class="font-weight-medium grey--text text--darken-2"
                            >{{
                              getFullCountryName(
                                customer.visitingAddress.country
                              )
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Email</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.visitingAddress.email }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Phone</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.visitingAddress.phone }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title class="mb-3"
                  ><span>Billing Address</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((customer && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager'))) &&
                        customer.visitingAddress
                    "
                    rounded
                    text
                    small
                    color="info"
                    class="mr-2"
                    :disabled="processing"
                    @click="copyAddress('visiting')"
                  >
                    same as visiting
                  </v-btn>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((customer && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editAddress('BILLING')"
                  >
                    <span
                      v-if="
                        customer.billingAddress && customer.billingAddress.id
                      "
                      >edit</span
                    >
                    <span v-else>add</span>
                  </v-btn></v-card-title
                >
                <v-card-text
                  v-if="customer.billingAddress && customer.billingAddress.id"
                >
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Street</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ customer.billingAddress.line1 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ customer.billingAddress.line2 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ customer.billingAddress.line3 }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Postcode</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.billingAddress.postCode }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">City</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.billingAddress.city }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">Province/State</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.billingAddress.state }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Country</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            v-if="
                              customer.billingAddress &&
                                customer.billingAddress.country
                            "
                            class="font-weight-medium grey--text text--darken-2"
                            >{{
                              getFullCountryName(
                                customer.billingAddress.country
                              )
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Email</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.billingAddress.email }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Phone</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.billingAddress.phone }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" class="py-0">
              <v-card flat class="mb-3">
                <v-card-title class="mb-3"
                  ><span>Shipping Address</span><v-spacer></v-spacer>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((customer && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager'))) &&
                        customer.billingAddress
                    "
                    rounded
                    text
                    small
                    color="info"
                    class="mr-2"
                    :disabled="processing"
                    @click="copyAddress('billing')"
                  >
                    same as billing
                  </v-btn>
                  <v-btn
                    v-if="
                      !editMode &&
                        ((customer && authLevel && authLevel === 2) ||
                          (currentUser &&
                            (currentUser.isSuperAdmin ||
                              currentUser.userRole === 'director' ||
                              currentUser.userRole === 'seniormanager' ||
                              currentUser.userRole === 'projectmanager')))
                    "
                    rounded
                    outlined
                    small
                    color="grey"
                    :disabled="processing"
                    @click="editAddress('SHIPPING')"
                  >
                    <span
                      v-if="
                        customer.shippingAddress && customer.shippingAddress.id
                      "
                      >edit</span
                    >
                    <span v-else>add</span>
                  </v-btn></v-card-title
                >
                <v-card-text
                  v-if="customer.shippingAddress && customer.shippingAddress.id"
                >
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Street</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ customer.shippingAddress.line1 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ customer.shippingAddress.line2 }}
                          </div>
                          <div
                            class="font-weight-medium grey--text text--darken-2"
                          >
                            {{ customer.shippingAddress.line3 }}
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Postcode</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.shippingAddress.postCode }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">City</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.shippingAddress.city }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1">Province/State</label>
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.shippingAddress.state }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Country</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            v-if="
                              customer.shippingAddress &&
                                customer.shippingAddress.country
                            "
                            class="font-weight-medium grey--text text--darken-2"
                            >{{
                              getFullCountryName(
                                customer.shippingAddress.country
                              )
                            }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Email</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.shippingAddress.email }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <label class="grey--text text--lighten-1"
                            >Phone</label
                          >
                        </v-col>
                        <v-col cols="12">
                          <span
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ customer.shippingAddress.phone }}</span
                          >
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {lookup} from 'country-data-list'
import CustomerEditDialog from '@/modules/sales/components/core/CustomerEditDialog.vue'
import AddressEditDialog from '@/modules/sales/components/core/AddressEditDialog.vue'
import ContactEditDialog from '@/modules/sales/components/core/ContactEditDialog.vue'
import CUSTOMER_UPDATE from '@/graphql/SalesCustomerUpdate.gql'
import ADDRESS_CREATE from '@/graphql/SalesCustomerAddressCreate.gql'
import ADDRESS_UPDATE from '@/graphql/SalesCustomerAddressUpdate.gql'
import CONTACT_CREATE from '@/graphql/SalesCustomerContactCreate.gql'
import CONTACT_UPDATE from '@/graphql/SalesCustomerContactUpdate.gql'
import CONTACT_REMOVE from '@/graphql/SalesCustomerContactRemove.gql'

export default {
  name: 'CustomerDetail',
  components: { CustomerEditDialog, AddressEditDialog, ContactEditDialog },
  props: {
    agents: { type: Object, default: () => {} },
    warehouses: { type: Object, default: () => {} },
    customer: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    agentSearch: '',
    dialog: false,
    addressDialog: false,
    contactDialog: false,
    removeContactDialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    removeContactID: null,
    defaultItem: {
      id: null,
      promeseID: null,
      companyName: null,
      storeName: null,
      commerceReg: null,
      vatId: null,
      status: null,
      website: null,
      facebook: null,
      twitter: null,
      linkedin: null,
      instagram: null,
      preNotification: null,
      deliveryConditions: 'DAP',
      eoriNumber: null,
      danNumber: null,
      shippingNumber: null
    },
    editedItem: {
      id: null,
      promeseID: null,
      companyName: null,
      storeName: null,
      commerceReg: null,
      vatId: null,
      status: null,
      website: null,
      facebook: null,
      twitter: null,
      linkedin: null,
      instagram: null,
      preNotification: null,
      deliveryConditions: 'DAP',
      eoriNumber: null,
      danNumber: null,
      shippingNumber: null
    },
    addressDefaultItem: {
      id: null,
      customerID: null,
      agentID: null,
      line1: null,
      line2: null,
      line3: null,
      postCode: null,
      state: null,
      city: null,
      country: null,
      addressType: null,
      email: null,
      phone: null
    },
    addressEditedItem: {
      id: null,
      customerID: null,
      agentID: null,
      line1: null,
      line2: null,
      line3: null,
      postCode: null,
      state: null,
      city: null,
      country: null,
      addressType: null,
      email: null,
      phone: null
    },
    contactDefaultItem: {
      id: null,
      title: null,
      firstName: null,
      lastName: null,
      status: 'ACTIVE',
      type: null,
      isUser: false,
      email: null,
      phone: null
    },
    contactEditedItem: {
      id: null,
      title: null,
      firstName: null,
      lastName: null,
      status: null,
      type: null,
      isUser: false,
      email: null,
      phone: null
    }
  }),
  computed: {
    contactHeaders() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Email',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'email'
        },
        {
          text: 'Phone',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'phone'
        },
        {
          text: 'Status',
          value: 'status',
          visible: false,
          sortable: false
        },
        {
          text: '',
          align: 'right',
          value: 'controls',
          visible: false,
          sortable: false
        }
      ]
    }
  },
  methods: {
    lookupCurrencyName(currencyID) {
      let currency = this._.find(this.currencies, (cur) => cur.id === currencyID)

      return currency.isoName
    },
    getFullCountryName(code) {
      var countryName = lookup.countries({alpha2: code})[0]
      if (countryName && countryName.name) {
        return countryName.name
      } else {
        return ''
      }
    },
    editAddress(addressType) {
      if (addressType === 'VISITING') {
        if (
          this.customer?.visitingAddress?.id &&
          this.customer?.visitingAddress?.addressType === 'VISITING'
        ) {
          this.addressEditedItem = this._.cloneDeep(
            this.customer.visitingAddress
          )
          this.editMode = true
        } else {
          this.addressEditedItem = this._.cloneDeep(this.addressDefaultItem)
          this.addressEditedItem.addressType = 'VISITING'
        }
      }

      if (addressType === 'BILLING') {
        if (
          this.customer?.billingAddress?.id &&
          this.customer?.billingAddress?.addressType === 'BILLING'
        ) {
          this.addressEditedItem = this._.cloneDeep(
            this.customer.billingAddress
          )
          this.editMode = true
        } else {
          this.addressEditedItem = this._.cloneDeep(this.addressDefaultItem)
          this.addressEditedItem.addressType = 'BILLING'
        }
      }

      if (addressType === 'SHIPPING') {
        if (
          this.customer?.shippingAddress?.id &&
          this.customer?.shippingAddress?.addressType === 'SHIPPING'
        ) {
          this.addressEditedItem = this._.cloneDeep(
            this.customer.shippingAddress
          )
          this.editMode = true
        } else {
          this.addressEditedItem = this._.cloneDeep(this.addressDefaultItem)
          this.addressEditedItem.addressType = 'SHIPPING'
        }
      }

      this.addressEditedItem.customerID = this.$route.params.customerID

      this.addressDialog = true
    },
    editContact(item) {
      if (item?.id) {
        this.editMode = true
        this.contactEditedItem = this._.cloneDeep(item)
      } else {
        this.contactEditedItem = this._.cloneDeep(this.contactDefaultItem)
        this.contactEditedItem.customerID = this.$route.params.customerID
        this.contactEditedItem.type = 'BUYER'
      }

      this.contactDialog = true
    },
    openRemoveContactDialog(id) {
      this.removeContactID = id
      this.removeContactDialog = true
    },
    editCustomer() {
      this.editedItem = this._.cloneDeep(this.customer)
      this.editMode = true
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.addressDialog = false
      this.contactDialog = false
      this.removeContactDialog = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.addressEditedItem = this._.cloneDeep(this.addressDefaultItem)
      this.contactEditedItem = this._.cloneDeep(this.contactDefaultItem)
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: CUSTOMER_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          this.$emit('refetch-customer')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Customer Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    copyAddress(addressType) {
      this.editMode = false
      var data = {}
      switch (addressType.toLowerCase()) {
        case 'visiting':
          data = {
            customerID: this.$route.params.customerID,
            line1: this.customer?.visitingAddress?.line1,
            line2: this.customer?.visitingAddress?.line2,
            line3: this.customer?.visitingAddress?.line3,
            postCode: this.customer?.visitingAddress?.postCode,
            state: this.customer?.visitingAddress?.state,
            city: this.customer?.visitingAddress?.city,
            country: this.customer?.visitingAddress?.country,
            addressType: 'BILLING',
            email: this.customer?.visitingAddress?.email,
            phone: this.customer?.visitingAddress?.phone
          }
          this.saveAddress(data)
          break

        case 'billing':
          data = {
            customerID: this.$route.params.customerID,
            line1: this.customer?.billingAddress?.line1,
            line2: this.customer?.billingAddress?.line2,
            line3: this.customer?.billingAddress?.line3,
            postCode: this.customer?.billingAddress?.postCode,
            state: this.customer?.billingAddress?.state,
            city: this.customer?.billingAddress?.city,
            country: this.customer?.billingAddress?.country,
            addressType: 'SHIPPING',
            email: this.customer?.billingAddress?.email,
            phone: this.customer?.billingAddress?.phone
          }
          this.saveAddress(data)
          break
      }
    },
    saveAddress(item) {
      this.errorMsg = ''
      this.processing = true

      if (this.editMode) {
        this.$apollo
          .mutate({
            mutation: ADDRESS_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Address Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: ADDRESS_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Address Created'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    saveContact(item) {
      this.errorMsg = ''
      this.processing = true

      if (this.editMode) {
        this.$apollo
          .mutate({
            mutation: CONTACT_UPDATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Contact Updated'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      } else {
        this.$apollo
          .mutate({
            mutation: CONTACT_CREATE,
            variables: {
              input: item
            }
          })
          .then(() => {
            this.$emit('refetch-customer')
            this.close()
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Contact Created'
            })
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    },
    removeContact() {
      this.$apollo
        .mutate({
          mutation: CONTACT_REMOVE,
          variables: {
            id: this.removeContactID
          }
        })
        .then(() => {
          this.$emit('refetch-customer')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Contact Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style></style>
