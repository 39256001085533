<template>
  <div>
    <v-toolbar color="transparent" flat class="mb-3 px-0 b-radius">
      <sales-order-edit-dialog
        :dialog="dialog"
        :edited-item="editedItem"
        :customers="customers"
        :warehouses="warehouses"
        :edit-mode="edit"
        :processing="processing"
        :error-msg="errorMsg"
        @close-sales-order-edit-dialog="close"
        @save-sales-order-edit-dialog="save"
      ></sales-order-edit-dialog>
      <v-row no-gutters>
        <!-- <v-col>
              <v-row no-gutters>
                <v-btn color="primary" rounded @click="newItem">
                  add order
                </v-btn>
              </v-row>
            </v-col> -->
        <v-col cols="12" md="4" lg="3" class="pr-3">
          <v-select
            v-model="salesOrderState"
            :items="salesOrderStates"
            label="Status"
            placeholder="all"
            hide-details
            outlined
            flat
            dense
            clearable
            background-color="#fff"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" lg="3" class="px-3">
          <v-select
            v-model="salesOrderType"
            :items="salesOrderTypes"
            label="Type"
            placeholder="all"
            hide-details
            outlined
            flat
            dense
            clearable
            background-color="#fff"
          ></v-select>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-card flat class="mb-3">
      <v-card-title>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Search..."
          single-line
          hide-details
          clearable
          autocomplete="off"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="salesOrders.items"
        :search="search"
        :options.sync="options"
        :loading="$apollo.loading"
        :mobile-breakpoint="null"
        :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
        class="plain-table grey--text text--darken-2"
        :hide-default-footer="salesOrders.total < options.itemsPerPage + 1"
        :hide-default-header="salesOrders.total === 0"
        @click:row="navToDetail"
      >
        <template v-slot:item.customer="{ item }">
          <div class="text-truncate">{{ item.customer.storeName }}</div>
        </template>
        <template v-slot:item.state="{ item }">
          <sales-order-state-label
            :state="item.state"
            x-small
          ></sales-order-state-label>
        </template>
        <!-- <template v-slot:item.type="{ item }">
                <div>
                  <v-chip
                    v-if="item.type === 'PREBOOK'"
                    x-small
                    color="success lighten-3 font-weight-medium"
                    text-color="success"
                    >{{ item.type }}</v-chip
                  >
                  <v-chip
                    v-if="item.type === 'REORDER'"
                    x-small
                    color="info lighten-2 font-weight-medium"
                    text-color="info darken-1"
                    >{{ item.type }}</v-chip
                  >
                  <v-chip
                    v-if="item.type === 'RETURN'"
                    x-small
                    color="warning lighten-1 font-weight-medium"
                    text-color="warning darken-3"
                    >{{ item.type }}</v-chip
                  >
                </div>
              </template> -->
        <template v-slot:item.notes="{ item }">
          <div class="text-truncate">{{ item.notes }}</div>
        </template>
        <template v-slot:item.net="{ item }">
          <div class="text-truncate">
            {{
              (item.net / 100)
                | currency(item.currencySymbol, 2, {
                  spaceBetweenAmountAndSymbol: true
                })
            }}
          </div>
        </template>
        <template v-slot:item.orderDate="{ item }">
          {{ item.orderDate | moment('LL') }}
        </template>
        <template v-slot:item.createdBy="{ item }">
          <div
            v-if="item.createdBy && item.createdBy.firstName"
            class="text-truncate"
          >
            {{ item.createdBy.firstName + ' ' + item.createdBy.lastName }}
          </div>
        </template>
        <template v-slot:no-data>Nothing here yet...</template>
        <!-- <template v-slot:item.controls="{ item }">
                    <v-btn icon small
                      ><v-icon small @click.stop="editItem(item)"
                        >mdi-pencil-outline</v-icon
                      ></v-btn
                    >
                  </template> -->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import SalesOrderStateLabel from '../components/core/SalesOrderStateLabel.vue'
import SalesOrderEditDialog from '@/modules/sales/components/core/SalesOrderEditDialog.vue'
import CUSTOMER_LIST from '@/graphql/SalesCustomers.gql'
import SALES_ORDER_LIST_BY_AGENT from '@/graphql/SalesOrdersByAgent.gql'
import SALES_ORDER_CREATE from '@/graphql/SalesOrderCreate.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'

export default {
  name: 'SalesOrderListByAgent',
  components: { SalesOrderStateLabel, SalesOrderEditDialog },
  data: () => ({
    dialog: false,
    edit: false,
    errorMsg: '',
    processing: false,
    search: '',
    searchCustomers: '',
    searchLineSheet: '',
    options: { page: 1, itemsPerPage: 15 },
    salesOrders: { items: [], total: 0, page: 1 },
    defaultItem: {
      id: null,
      customerID: null,
      notes: null,
      customerPoNumber: null,
      rmaNumber: null,
      type: 'REORDER'
    },
    editedItem: {},
    salesOrderState: null,
    salesOrderStates: [
      'DRAFT',
      'SUBMITTED',
      'REVIEW',
      'APPROVED',
      'PROCESSEDPARTIAL',
      'PROCESSED',
      'SHIPPEDPARTIAL',
      'SHIPPED',
      'CANCELED'
    ],
    salesOrderType: null,
    salesOrderTypes: ['PREBOOK', 'REORDER', 'RETURN']
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Order #',
          value: 'orderNumber',
          align: 'left',
          visible: false,
          sortable: false
        },
        {
          text: 'NuOrder Order #',
          value: 'nuorderOrderNumber',
          align: 'left',
          visible: false,
          sortable: false
        },
        {
          text: 'Order Date',
          align: 'left',
          value: 'orderDate',
          filterable: true,
          sortable: false
        },
        {
          text: '',
          value: 'state',
          visible: false,
          sortable: false,
          align: 'center'
        },
        {
          text: 'Order Type',
          value: 'type',
          visible: false,
          sortable: false
        },
        {
          text: 'Customer',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'customer'
        },
        {
          text: 'Order Notes',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'notes'
        },
        {
          text: 'Total',
          align: 'right',
          value: 'net',
          filterable: true,
          sortable: false
        },
        {
          text: 'Total Units',
          align: 'right',
          value: 'qty',
          filterable: true,
          sortable: false
        },
        {
          text: 'Created By',
          value: 'createdBy',
          filterable: true,
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('salesOrders')
    }
  },
  apollo: {
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            search: null,
            page: 1,
            pageSize: 1000
          }
        }
      }
    },
    customers: {
      query: CUSTOMER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchCustomers,
            page: 1,
            pageSize: 25
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200
    },
    salesOrders: {
      query: SALES_ORDER_LIST_BY_AGENT,
      variables() {
        return {
          filters: {
            agentID: this.$route.params.agentID,
            search: this.search,
            status: this.salesOrderState,
            orderType: this.salesOrderType,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      update: data => data.salesOrdersByAgent,
      debounce: 200
    }
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    close(success, id) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Line Sheet Saved'
        })

        this.$router.push({
          name: 'SalesOrderDetails',
          params: { seasonID: id }
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_CREATE,
          variables: {
            input: item
          }
        })
        .then(result => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Created'
          })
          this.$router.push({
            name: 'SalesOrderDetails',
            params: { salesOrderID: result.data.createSalesOrder }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    navToDetail(item) {
      this.$router.push({
        name: 'SalesOrderDetails',
        params: { salesOrderID: item.id }
      })
    }
  }
}
</script>

<style lang="scss">
// .list-item {
//   // border: 1px solid transparent;
//   &:hover {
//     border-color: var(--v-primary-base);
//   }
// }

// .plain-table .v-input__slot {
//   background: transparent !important;
// }

// .plain-table.theme--light.v-data-table
//   tbody
//   tr:hover:not(.v-data-table__expanded__content) {
//   cursor: pointer !important;
// }
</style>
