<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="main-page-column pt-0 pr-4">
        <v-dialog v-model="removeDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline error--text mb-3"
              >Remove Pick Ticket</v-card-title
            >
            <div v-if="errorMsg !== ''" class="pa-3 pb-0">
              <v-alert type="error" class="mb-0">
                {{ errorMsg }}
              </v-alert>
            </div>
            <v-card-text>
              <p>
                This will remove the pick ticket and can't be undone.
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="grey darken-1" text @click="removeDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="error"
                text
                :loading="processing"
                @click="removePickTicket"
                >Remove</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="resendDialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline primary--text mb-3"
              >Resend Pick Ticket</v-card-title
            >
            <div v-if="errorMsg !== ''" class="pa-3 pb-0">
              <v-alert type="error" class="mb-0">
                {{ errorMsg }}
              </v-alert>
            </div>
            <v-card-text>
              <p>
                This will resend the pick ticket to the warehouse.
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="grey darken-1" text @click="resendDialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                small
                rounded
                :loading="processing"
                @click="resendPickTicket"
                >Resend</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" persistent max-width="350">
          <v-card>
            <v-card-title class="headline primary--text mb-3"
              >Edit Delivery Dates</v-card-title
            >
            <div v-if="errorMsg !== ''" class="pa-3 pb-0">
              <v-alert type="error" class="mb-0">
                {{ errorMsg }}
              </v-alert>
            </div>
            <v-card-text>
              <v-form
                ref="pickTicketDeliveryForm"
                v-model="valid"
                lazy-validation
                @submit.prevent
              >
                <v-row>
                  <v-col cols="12">
                    <v-menu
                      v-model="startDate"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedDeliveryStartDate"
                          autocomplete="off"
                          outlined
                          label="Delivery Start"
                          placeholder="Delivery Start"
                          prepend-inner-icon="mdi-calendar-month-outline"
                          dense
                          hide-details="auto"
                          readonly
                          :rules="requiredRule"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="deliveryStartDate"
                        no-title
                        hide-details
                        first-day-of-week="1"
                        @input="endDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      v-model="endDate"
                      :close-on-content-click="true"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="formattedDeliveryEndDate"
                          autocomplete="off"
                          outlined
                          label="Delivery End"
                          placeholder="Delivery End"
                          prepend-inner-icon="mdi-calendar-month-outline"
                          dense
                          hide-details="auto"
                          readonly
                          :rules="requiredRule"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="deliveryEndDate"
                        no-title
                        hide-details
                        first-day-of-week="1"
                        :min="
                          $moment(deliveryStartDate) > $moment()
                            ? $moment(deliveryStartDate)
                                .add(1, 'days')
                                .format('YYYY-MM-DD')
                            : $moment()
                                .add(2, 'days')
                                .format('YYYY-MM-DD')
                        "
                        @input="endDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="grey darken-1" text rounded @click="dialog = false"
                >Cancel</v-btn
              >
              <v-btn
                color="primary"
                rounded
                small
                :loading="processing"
                @click="save"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="scroll-container pt-0">
          <v-card v-if="pickTicket && pickTicket.salesOrder" flat class="mb-3">
            <v-card-title class="mb-3 grey--text"
              >Ticket No.
              <span class="primary--text pl-2">
                {{ pickTicket.pickTicketSerial }}</span
              ><v-spacer></v-spacer>
              <v-btn
                v-if="pickTicket.state === 'SCHEDULED'"
                rounded
                small
                color="primary"
                class="mr-2"
                :disabled="processing"
                @click="dialog = true"
              >
                edit delivery date
              </v-btn>
              <v-btn
                v-if="pickTicket.state === 'SCHEDULED' && pickTicket.warehouse.type === 'WHOLESALE'"
                rounded
                text
                small
                color="grey"
                :disabled="processing"
                @click="openRemoveDialog"
              >
                remove
              </v-btn>
              <v-btn
                v-if="pickTicket.state === 'SENT'"
                rounded
                text
                small
                color="grey"
                :disabled="processing"
                @click="openResendDialog"
              >
                resend
              </v-btn>
            </v-card-title>
            <v-card-subtitle class="mb-3 grey--text"
              >Order No.
              <span class="primary--text pl-2">
                {{ pickTicket.salesOrder.orderNumber }}</span
              >
            </v-card-subtitle>
            <v-card-text>
              <v-row no-gutters>
                <v-col cols="12" class="mb-3">
                  <v-chip
                    v-if="pickTicket.state === 'SCHEDULED'"
                    x-small
                    color="success lighten-3 font-weight-medium"
                    text-color="success"
                    >{{ pickTicket.state }}</v-chip
                  >
                  <v-chip
                    v-if="pickTicket.state === 'SENT'"
                    x-small
                    color="info lighten-2 font-weight-medium"
                    text-color="info darken-1"
                    >{{ pickTicket.state }}</v-chip
                  >
                  <v-chip
                    v-if="pickTicket.state === 'CLOSED'"
                    x-small
                    color="warning lighten-1 font-weight-medium"
                    text-color="warning darken-3"
                    >{{ pickTicket.state }}</v-chip
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col class="py-0" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Order Type</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ pickTicket.salesOrder.type }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Store</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ pickTicket.salesOrder.customer.storeName }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Company</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ pickTicket.salesOrder.customer.companyName }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1">Agent</label>
                    </v-col>
                    <v-col cols="12">
                      <span
                        v-if="pickTicket.salesOrder.agent"
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ pickTicket.salesOrder.agent.name }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Order Reference / Customer PO</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{ pickTicket.salesOrderReference }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Delivery Start</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{
                          pickTicket.deliveryStartDate | moment('DD MMM YY')
                        }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-col class="py-0" cols="12" md="6">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Delivery End</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <span
                        class="font-weight-medium grey--text text--darken-2"
                        >{{
                          pickTicket.deliveryEndDate | moment('DD MMM YY')
                        }}</span
                      >
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="py-0" cols="12">
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="grey--text text--lighten-1"
                        >Warehouse</label
                      >
                    </v-col>
                    <v-col cols="12">
                      <div class="text-truncate">
                        <span
                          v-if="pickTicket.warehouse"
                          class="font-weight-medium grey--text text--darken-2"
                          >{{ pickTicket.warehouse.name }}</span
                        ><span v-if="pickTicket.warehouse.provider" class="pl-3"
                          >({{ pickTicket.warehouse.provider.name }})</span
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card flat>
            <v-data-table
              :loading="$apollo.loading"
              :headers="headers"
              :items="orderBy(pickTicket.lines, 'productSize.id')"
              :items-per-page="-1"
              :mobile-breakpoint="null"
              fixed-header
              hide-default-footer
            >
              <template v-slot:item.createdAt="{ item }">
                <span v-if="item.createdAt" class="text-no-wrap">{{
                  item.createdAt | moment('from', 'now')
                }}</span>
              </template>

              <template v-slot:item.state="{ item }">
                <div>
                  <v-chip
                    v-if="item.state === 'SCHEDULED'"
                    x-small
                    color="success lighten-3 font-weight-medium"
                    text-color="success"
                    >{{ item.state }}</v-chip
                  >
                  <v-chip
                    v-if="item.state === 'SENT'"
                    x-small
                    color="info lighten-2 font-weight-medium"
                    text-color="info darken-1"
                    >{{ item.state }}</v-chip
                  >
                  <v-chip
                    v-if="item.state === 'CLOSED'"
                    x-small
                    color="warning lighten-1 font-weight-medium"
                    text-color="warning darken-3"
                    >{{ item.state }}</v-chip
                  >
                </div>
              </template>

              <template v-slot:item.deliveryStartDate="{ item }">
                <span>{{ item.deliveryStartDate | moment('DD MMM YY') }} </span>
                <span v-if="item.deliveryStartDate && item.deliveryEndDate"
                  >&nbsp;-&nbsp;</span
                >
                <span>{{ item.deliveryEndDate | moment('DD MMM YY') }} </span>
              </template>
              <template v-slot:item.seasonProduct.styleNumber="{ item }">
                <span
                  >{{ item.seasonProduct.styleNumber + item.productSize.name }}
                </span>
              </template>

              <template v-slot:no-data>
                <message-box
                  icon="mdi-emoticon-neutral-outline"
                  title="No Pick Tickets"
                  :caption="'Try adjusting your search to get some results'"
                >
                </message-box>
              </template>
              <template v-slot:loading>
                <v-skeleton-loader
                  v-for="item in 5"
                  :key="item"
                  type="list-item"
                  class="mx-auto"
                ></v-skeleton-loader>
              </template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import MessageBox from '@/components/core/MessageBox.vue'
import PICK_TICKET from '@/graphql/PickTicket.gql'
import PICK_TICKET_REMOVE from '@/graphql/PickTicketRemove.gql'
import PICK_TICKET_RESEND from '@/graphql/PickTicketResend.gql'
import PICK_TICKET_DELIVERY from '@/graphql/PickTicketDelivery.gql'

export default {
  name: 'PickTicketDetails',
  components: { MessageBox },
  mixins: [Vue2Filters.mixin],
  data: () => ({
    search: null,
    itemCount: 0,
    pickTicket: {},
    removeDialog: false,
    resendDialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    headers: [
      // {
      //   text: '',
      //   align: 'left',
      //   sortable: false,
      //   filterable: true,
      //   value: 'seasonProduct.frontImage'
      // },
      {
        text: 'Product Name',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'seasonProduct.name'
      },
      {
        text: '',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'seasonProduct.seasonColor'
      },
      {
        text: 'Size',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'productSize.name'
      },
      {
        text: 'Quanitity',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'quantity'
      },
      // {
      //   text: 'SKU',
      //   sortable: false,
      //   filterable: true,
      //   value: 'seasonProduct.styleNumber'
      // },
      {
        text: 'Barcode',
        sortable: false,
        filterable: true,
        value: 'barcode'
      }
    ],

    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    dialog: false,
    deliveryStartDate: null,
    deliveryEndDate: null,
    startDate: null,
    endDate: null
  }),
  apollo: {
    pickTicket: {
      query: PICK_TICKET,
      variables() {
        return {
          id: this.$route.params.pickTicketID
        }
      },
      result(result) {
        var item = JSON.parse(JSON.stringify(result?.data?.pickTicket))

        this.deliveryStartDate = item.deliveryStartDate
        this.deliveryEndDate = item.deliveryEndDate
      }
    }
  },
  computed: {
    formattedDeliveryStartDate() {
      return this.deliveryStartDate
        ? this.$moment(this.deliveryStartDate).format('LL')
        : ''
    },
    formattedDeliveryEndDate() {
      return this.deliveryEndDate
        ? this.$moment(this.deliveryEndDate).format('LL')
        : ''
    }
  },
  created() {},
  methods: {
    openRemoveDialog() {
      this.errorMsg = ''
      this.processing = false
      this.removeDialog = true
    },
    openResendDialog() {
      this.errorMsg = ''
      this.processing = false
      this.resendDialog = true


    },
    removePickTicket() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: PICK_TICKET_REMOVE,
          variables: {
            id: this.pickTicket.id
          }
        })
        .then(() => {
          this.processing = false

          this.errorMsg = ''

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Pick Ticket Removed'
          })

          this.$router.back()
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    resendPickTicket() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: PICK_TICKET_RESEND,
          variables: {
            id: this.pickTicket.id
          }
        })
        .then(() => {
          this.processing = false

          this.errorMsg = ''

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Pick Ticket Resend'
          })

          this.$apollo.queries.pickTicket.refetch()

          this.resendDialog = false

        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    save() {
      this.errorMsg = ''
      this.processing = true

      if (this.$refs.pickTicketDeliveryForm.validate()) {
        var data = {
          id: this.pickTicket.id,
          deliveryStartDate: this.deliveryStartDate
            ? this.$moment(this.deliveryStartDate).utc()
            : this.pickTicket.deliveryStartDate,
          deliveryEndDate: this.deliveryEndDate
            ? this.$moment(this.deliveryEndDate).utc()
            : this.pickTicket.deliveryEndDate
        }
        this.$apollo
          .mutate({
            mutation: PICK_TICKET_DELIVERY,
            variables: {
              input: data
            }
          })
          .then(() => {
            this.processing = false

            this.errorMsg = ''

            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Delivery Dates Updated'
            })

            this.$apollo.queries.pickTicket.refetch()

            this.dialog = false
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      }
    }
  }
}
</script>

<style></style>
