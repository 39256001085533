var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"plain-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"footer-props":{'items-per-page-options':[15, 30, 50, 100]},"items":_vm.pickTickets.items,"options":_vm.tableOptions,"mobile-breakpoint":null,"fixed-header":"","hide-default-header":!_vm.loading &&
        !_vm.search &&
        (!_vm.pickTickets || !_vm.pickTickets.items || _vm.pickTickets.items.length === 0),"hide-default-footer":!_vm.pickTickets || _vm.itemCount < 16},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
        var item = ref.item;
return [(item.createdAt)?_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("moment")(item.createdAt,'LLL')))]):_vm._e()]}},{key:"item.state",fn:function(ref){
        var item = ref.item;
return [_c('div',[(item.state === 'SCHEDULED')?_c('v-chip',{attrs:{"x-small":"","color":"success lighten-3 font-weight-medium","text-color":"success"}},[_vm._v(_vm._s(item.state))]):_vm._e(),(item.state === 'SENT')?_c('v-chip',{attrs:{"x-small":"","color":"info lighten-2 font-weight-medium","text-color":"info darken-1"}},[_vm._v(_vm._s(item.state))]):_vm._e(),(item.state === 'CLOSED')?_c('v-chip',{attrs:{"x-small":"","color":"warning lighten-1 font-weight-medium","text-color":"warning darken-3"}},[_vm._v(_vm._s(item.state))]):_vm._e()],1)]}},{key:"item.deliveryStartDate",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.deliveryStartDate,'DD MMM YY'))+" ")]),(item.deliveryStartDate && item.deliveryEndDate)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.deliveryEndDate,'DD MMM YY'))+" ")])]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"icon":"mdi-emoticon-neutral-outline","title":"No Pick Tickets","caption":'Try adjusting your search to get some results'}})]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }