var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[(_vm.product)?_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"subtitle-1"},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":"","clearable":"","outlined":"","dense":"","autocomplete":"off","disabled":!_vm.productCustomers ||
              (_vm.productCustomers && _vm.productCustomers.length === 0)},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),(
            !_vm.$apollo.loading &&
              _vm.productCustomerAuthLevel &&
              _vm.productCustomerAuthLevel === 2 &&
              (_vm.productCustomers || _vm.productCustomers.length > 0) &&
              _vm.product &&
              _vm.product.productState === 'FINAL' &&
              _vm.selected &&
              _vm.selected.length > 0
          )?_c('v-btn',{staticClass:"mr-3",attrs:{"rounded":"","small":"","color":"info","disabled":_vm.processing},on:{"click":function($event){return _vm.updateAll(true)}}},[_vm._v("Show")]):_vm._e(),(
            !_vm.$apollo.loading &&
              _vm.productCustomerAuthLevel &&
              _vm.productCustomerAuthLevel === 2 &&
              (_vm.productCustomers || _vm.productCustomers.length > 0) &&
              _vm.product &&
              _vm.product.productState === 'FINAL' &&
              _vm.selected &&
              _vm.selected.length > 0
          )?_c('v-btn',{attrs:{"rounded":"","small":"","color":"info","disabled":_vm.processing},on:{"click":function($event){return _vm.updateAll(false)}}},[_vm._v("Hide")]):_vm._e(),(
            !_vm.$apollo.loading &&
              _vm.productCustomerAuthLevel &&
              _vm.productCustomerAuthLevel === 2 &&
              (!_vm.productCustomers || _vm.productCustomers.length !== 0) &&
              _vm.product &&
              _vm.product.productState === 'FINAL'
          )?_c('v-btn',{staticClass:"ml-2",attrs:{"rounded":"","small":"","color":"primary","loading":_vm.processing},on:{"click":_vm.populate}},[_vm._v("Populate")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productCustomers,"search":_vm.search,"sort-by":"customer.storeName","fixed-header":"","show-select":"","hide-default-header":!_vm.productCustomers || _vm.productCustomers.length === 0,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.customer.companyName",fn:function(ref){
          var item = ref.item;
return [(
              item &&
                item.customer.companyName &&
                item.customer.storeName &&
                item.customer.storeName != item.customer.companyName
            )?_c('div',[_vm._v(" "+_vm._s(item.customer.companyName)+" ")]):_vm._e()]}},{key:"item.active",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"color":"info"},on:{"click":function($event){return _vm.update(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"no-data",fn:function(){return [_c('message-box',{attrs:{"title":"No Results","title-color":"primary--text","caption":"Populate the list with customers","caption-color":""}},[(
                !_vm.$apollo.loading &&
                  _vm.productCustomerAuthLevel &&
                  _vm.productCustomerAuthLevel === 2 &&
                  (!_vm.productCustomers || _vm.productCustomers.length === 0) &&
                  _vm.product &&
                  _vm.product.productState === 'FINAL'
              )?_c('v-btn',{attrs:{"rounded":"","color":"info","loading":_vm.processing},on:{"click":_vm.populate}},[_vm._v("Populate List")]):_vm._e()],1)]},proxy:true},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}],null,false,3744191971),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }