var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600","persistent":"","fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))])]),(_vm.errorMsg !== '')?_c('div',{staticClass:"pa-3 pb-0"},[_c('v-alert',{staticClass:"mb-0",attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])],1):_vm._e(),_c('div',[_c('div',{staticClass:"scroll-container py-0"},[_c('v-form',{ref:"salesOrderForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.item)?_c('v-row',{attrs:{"id":"scroll-target","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.customers.items,"outlined":"","dense":"","item-text":"storeName","return-object":"","hide-details":"auto","label":"Customer","rules":_vm.requiredRule},model:{value:(_vm.item.customer),callback:function ($$v) {_vm.$set(_vm.item, "customer", $$v)},expression:"item.customer"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Customer PO Number (optional)","outlined":"","dense":"","hide-details":"auto","autocomplete":"off"},model:{value:(_vm.item.customerPoNumber),callback:function ($$v) {_vm.$set(_vm.item, "customerPoNumber", $$v)},expression:"item.customerPoNumber"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.deliveryStartDate,"clearable":"","label":"Delivery Start","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","hide-details":"auto","autocomplete":"off"},on:{"click:clear":function($event){_vm.item.deliveryStartDate = null}}},'v-text-field',attrs,false),on))]}}],null,false,1411743914),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"min":_vm.$moment().format('YYYY-MM-DD'),"no-title":"","scrollable":""},on:{"change":function($event){_vm.menu1 = false}},model:{value:(_vm.item.deliveryStartDate),callback:function ($$v) {_vm.$set(_vm.item, "deliveryStartDate", $$v)},expression:"item.deliveryStartDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.deliveryEndDate,"clearable":"","label":"Delivery End","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":"","hide-details":"auto","autocomplete":"off"},on:{"click:clear":function($event){_vm.item.deliveryEndDate = null}}},'v-text-field',attrs,false),on))]}}],null,false,1604957317),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"min":_vm.$moment(_vm.item.deliveryStartDate)
                              .add(1, 'days')
                              .format('YYYY-MM-DD'),"no-title":"","scrollable":""},on:{"change":function($event){_vm.menu2 = false}},model:{value:(_vm.item.deliveryEndDate),callback:function ($$v) {_vm.$set(_vm.item, "deliveryEndDate", $$v)},expression:"item.deliveryEndDate"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Notes (optional)","outlined":"","dense":"","hide-details":"auto","autocomplete":"off"},model:{value:(_vm.item.notes),callback:function ($$v) {_vm.$set(_vm.item, "notes", $$v)},expression:"item.notes"}})],1)],1)],1)],1):_vm._e()],1)],1)]),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","rounded":"","color":"grey lighten-1","disabled":_vm.processing},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary lighten-2","rounded":"","loading":_vm.processing},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }