<template>
  <v-dialog
    v-model="dialog"
    width="500"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="subscriberForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col v-if="!editMode" class="py-0" cols="12">
                    <v-autocomplete
                      v-model="item.customerID"
                      :items="customers.items"
                      autocomplete="off"
                      label="Customer"
                      hide-details="auto"
                      item-text="storeName"
                      item-value="id"
                      :rules="requiredSelectRule"
                      outlined
                      dense
                      :multiple="!editMode"
                      :disabled="editMode"
                    >
                      <template v-slot:item="data">
                        <v-list-item-content>
                          <v-list-item-title
                            class="primary--text"
                            v-html="data.item.storeName"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            class="text-caption"
                            v-html="data.item.companyName"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="editMode && item.customer && !multiple">
                    <h3 class="info--text font-weight-medium">
                      {{ item.customer.storeName }}
                    </h3>
                    <div class="text-caption">
                      {{ item.customer.companyName }}
                    </div>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-checkbox
                      v-model="item.visible"
                      autocomplete="off"
                      label="Visible to Customer"
                      hide-details="auto"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>

                <!-- <v-row>
                  <v-col class="py-0">
                    <v-checkbox
                      v-model="item.prebook"
                      autocomplete="off"
                      label="Prebook"
                      hide-details="auto"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row> -->
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import country from 'country-list'

export default {
  name: 'LineSheetSubscriberEditDialog',
  props: {
    subscriber: {
      type: Object,
      required: true,
      default: () => ({})
    },
    customers: {
      type: Object,
      required: true,
      default: () => {}
    },
    lineSheet: {
      type: Object,
      default: () => {}
    },
    editMode: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    priorities: ['low', 'medium', 'high'],
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    titles: ['Mr', 'Mrs', 'Ms', 'Miss', 'Mx', 'Other']
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Subscriber' : 'New Subscriber'
    },
    item() {
      return this._.cloneDeep(this.subscriber)
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    }
  },
  watch: {},
  methods: {
    close() {
      this.$refs.subscriberForm.resetValidation()
      this.$emit('close-subscriber-edit-dialog', false)

      // this.$nextTick(() => {
      // this.$refs.subscriberForm.resetValidation()
      // })
    },
    save() {
      if (this.$refs.subscriberForm.validate()) {
        var data = {
          id: this.item.id,
          customerID: this.item.customerID || this.item.customer?.id || null,
          lineSheetID: this.lineSheet.id,
          visible: this.item.visible,
          prebook: this.item.prebook
        }

        if (!this.editMode) {
          delete data.id
        }

        if (this.multiple) {
          delete data.id
          delete data.customerID
        }

        this.$emit('save-subscriber-edit-dialog', data)
        // this.$nextTick(() => {
        //   that.$refs.subscriberForm.resetValidation()
        // })
      }
    }
  }
}
</script>

<style></style>
