<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="lineSheetForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="item.name"
                      label="Name"
                      autocomplete="off"
                      outlined
                      :rules="requiredRule"
                      hide-details="auto"
                      dense
                      autofocus
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="item.description"
                      label="Description"
                      autocomplete="off"
                      outlined
                      hide-details="auto"
                      dense
                      rows="3"
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-file-input
                      v-model="image"
                      label="Select Image"
                      prepend-inner-icon="mdi-file-outline"
                      :prepend-icon="null"
                      outlined
                      dense
                      hide-details="auto"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row>
                  <!-- <v-col cols="12" md="6">
                    <v-autocomplete
                      v-model="item.season"
                      :items="seasons"
                      label="Seasons"
                      outlined
                      dense
                      return-object
                      item-text="shortName"
                      hide-details="auto"
                      :rules="requiredSelectRule"
                    >
                    </v-autocomplete>
                  </v-col> -->
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="item.state"
                      :items="['ACTIVE', 'INACTIVE']"
                      label="Status"
                      outlined
                      dense
                      hide-details="auto"
                      :rules="requiredSelectRule"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-menu
                          ref="menu1"
                          v-model="preBookStartDate"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formattedPreBookStartDate"
                              outlined
                              prepend-inner-icon="mdi-calendar-month-outline"
                              dense
                              label="Prebook Start Date"
                              hide-details="auto"
                              autocomplete="off"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.preBookStart"
                            no-title
                            @input="preBookStartDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-menu
                          ref="menu2"
                          v-model="preBookEndDate"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="formattedPreBookEndDate"
                              outlined
                              prepend-inner-icon="mdi-calendar-month-outline"
                              dense
                              label="Prebook End Date"
                              autocomplete="off"
                              hide-details="auto"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="item.preBookEnd"
                            no-title
                            @input="preBookEndDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LineSheetEditDialog',
  props: {
    lineSheet: {
      type: Object,
      required: true,
      default: () => ({})
    },
    seasons: {
      type: Array,
      required: true,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    preBookStartDate: false,
    startDate: false,
    preBookEndDate: false,
    endDate: false,
    image: null,
    item: null
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Line Sheet' : 'New Line Sheet'
    },
    formattedPreBookStartDate() {
      return this.item.preBookStart
        ? this.$moment(this.item.preBookStart).format('LL')
        : ''
    },
    formattedPreBookEndDate() {
      return this.item.preBookEnd
        ? this.$moment(this.item.preBookEnd).format('LL')
        : ''
    }
  },
  watch: {
    image(val) {
      if (val) this.item.image = val
    },
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.lineSheet)
    }
  },
  methods: {
    close() {
      this.$refs.lineSheetForm.reset()
      this.$emit('close-line-sheet-edit-dialog', false)

      // this.$nextTick(() => {
      // this.$refs.lineSheetForm.resetValidation()
      // })
    },
    save() {
      if (this.$refs.lineSheetForm.validate()) {
        var data = {
          id: this.item.id,
          seasonID: this.item.season?.id,
          name: this.item.name,
          image: this.item.image,
          description: this.item.description,
          state: this.item.state,
          preBookStart: this.$moment(this.item.preBookStart).utc(),
          preBookEnd: this.$moment(this.item.preBookEnd).utc()
        }

        if (typeof data.image === 'string') {
          data.image = null
        }

        if (!this.editMode) {
          delete data.id
        }

        this.$emit('save-line-sheet-edit-dialog', data)
        // this.$nextTick(() => {
        //   that.$refs.lineSheetForm.reset()
        // })
      }
    }
  }
}
</script>

<style></style>
