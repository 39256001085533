<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header">
        <v-toolbar class="mb-3" color="white" rounded flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col cols="12">
              <h2 class="info--text text-truncate">
                Sales Orders By Barcode Report
              </h2>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
          <v-row justify="end" align="center" style="height: 40px;">
            <span class="pr-3 text-truncate">{{ itemCount }} Rows</span>
          </v-row>
        </v-toolbar>

        <v-toolbar class="mb-0" color="white" rounded flat>
          <!-- <v-toolbar-title class="mr-5">{{ workflow.name }}</v-toolbar-title> -->
          <v-row>
            <v-col cols="2">
              <v-menu
                v-model="menu1"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedFromDate"
                    label="From"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    autocomplete="off"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="from"
                  no-title
                  scrollable
                  @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formattedTillDate"
                    label="Till"
                    append-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    hide-details="auto"
                    autocomplete="off"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="till"
                  :min="
                    $moment(from)
                      .add(1, 'days')
                      .format('YYYY-MM-DD')
                  "
                  no-title
                  scrollable
                  @change="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedOrderStates"
                :items="orderStates"
                outlined
                dense
                label="Status"
                color="info"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                multiple
                :disabled="loading"
              >
                <template v-slot:selection="{ index }">
                  <div
                    v-if="index === 0"
                    class="text-truncate"
                    style="width: 80%"
                  >
                    <span
                      v-if="selectedOrderStates.length === orderStates.length"
                      class="grey--text text--darken1 text-truncate"
                      >All</span
                    >
                    <span v-else class="grey--text text--darken1 text-truncate"
                      >{{ selectedOrderStates.length }} of
                      {{ orderStates.length }}</span
                    >
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                v-model="selectedOrderType"
                :items="orderTypes"
                clearable
                outlined
                dense
                label="Order Type"
                color="info"
                background-color="white"
                prepend-inner-icon="mdi-filter-variant"
                hide-details
                autocomplete="off"
                :disabled="loading"
              >
              </v-select>
            </v-col>
            <v-col cols="4" class="pl-sm-0 pl-xs-0 pr-sm-1 pr-xs-1">
              <v-row
                align="center"
                justify="end"
                no-gutters
                style="height: 40px"
              >
                <!-- Report excel -->
                <div v-if="exportData.length > 0">
                  <xlsx-workbook style="width: 100%;">
                    <xlsx-sheet :collection="exportData" sheet-name="Sheet1" />
                    <xlsx-download :filename="fileName" class="px-0 mx-0">
                      <v-btn
                        color="info"
                        small
                        rounded
                        class="text-capitalize font-weight-regular text-body-1"
                        >Download Excel</v-btn
                      >
                    </xlsx-download>
                  </xlsx-workbook>
                </div>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col v-if="reportData.length === 0">
              <v-card flat>
                <message-box
                  :icon="loading ? '' : 'mdi-database-off-outline'"
                  :title="loading ? 'Loading...' : 'No Results'"
                  :caption="
                    loading
                      ? 'Please sit back while we load your data'
                      : 'Try adjusting your filters to get some results'
                  "
                >
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="info"
                  ></v-progress-circular>
                </message-box>
              </v-card>
            </v-col>
            <v-col v-else>
              <v-data-table
                :headers="headers"
                :loading="loading"
                :items="reportData"
                :items-per-page="-1"
                hide-default-footer
                :mobile-breakpoint="null"
              >
                <template v-slot:loading>
                  <v-skeleton-loader
                    v-for="item in 5"
                    :key="item"
                    type="list-item"
                    class="mx-auto"
                  ></v-skeleton-loader>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from 'vue-xlsx'
import MessageBox from '@/components/core/MessageBox.vue'
import BARCODE_REPORT from '@/graphql/ReportSalesOrdersByBarcode.gql'

export default {
  name: 'BarcodeSalesOrderReport',
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    MessageBox
  },
  data() {
    return {
      loading: false,
      reportData: [],
      exportData: [],

      menu1: false,
      menu2: false,

      from: this.$moment()
        .subtract(30, 'days')
        .toISOString(),
      till: this.$moment().toISOString(),
      headers: [
        {
          text: 'Barcode',
          value: 'barcode'
        },
        {
          text: 'Style Number',
          value: 'styleNumber'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Size',
          value: 'size'
        },
        {
          text: 'Quantity',
          value: 'qty',
          align: 'end'
        },
        {
          text: 'Wholesale Price (EUR)',
          value: 'wholesalePrice',
          disabled: false,
          header: '',
          align: 'end'
        },
        {
          text: 'Retail Price (EUR)',
          value: 'retailPrice',
          disabled: false,
          header: '',
          align: 'end'
        },
        {
          text: 'Total (EUR)',
          value: 'total',
          align: 'end'
        }
      ],

      orderTypes: ['PREBOOK', 'REORDER', 'RETURN'],
      selectedOrderType: null,

      selectedOrderStates: [
        'SUBMITTED',
        'REVIEW',
        'APPROVED',
        'PROCESSED',
        'SHIPPED'
      ],
      orderStates: [
        {
          text: 'Draft',
          value: 'DRAFT'
        },
        {
          text: 'Submitted',
          value: 'SUBMITTED'
        },
        {
          text: 'Review',
          value: 'REVIEW'
        },
        {
          text: 'Approved',
          value: 'APPROVED'
        },
        {
          text: 'Processed',
          value: 'PROCESSED'
        },
        {
          text: 'Shipped',
          value: 'SHIPPED'
        },
        {
          text: 'Canceled',
          value: 'CANCELED'
        }
      ]
    }
  },
  computed: {
    fileName() {
      return `sales_orders_by_barcode_${this.$moment(this.from).format(
        'DDMMYYYY'
      )}-${this.$moment(this.till).format('DDMMYYYY')}.xlsx`
    },
    itemCount() {
      return this.reportData?.length
    },
    formattedFromDate() {
      return this.from ? this.$moment(this.from).format('LL') : ''
    },
    formattedTillDate() {
      return this.till ? this.$moment(this.till).format('LL') : ''
    },
    stateFilter() {
      return this.selectedOrderStates.join()
    }
  },
  watch: {},
  apollo: {
    barcodeOrderReport: {
      query: BARCODE_REPORT,
      variables() {
        return {
          filters: {
            from: this.$moment(this.from).utc(),
            till: this.$moment(this.till).utc(),
            status: this.selectedOrderStates,
            orderType: this.selectedOrderType
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 100,
      result(result) {
        if (result && result.data && result.data.barcodeOrderReport) {
          this.convertedReportFile(result.data.barcodeOrderReport)
        } else {
          this.reportData = []
        }
        this.loading = false
      }
      // skip() {
      //   return (
      //     this.selectedStyleStates?.length === 0 ||
      //     this.selectedProductStates.length === 0
      //   )
      // }
    }
  },
  methods: {
    convertedReportFile(data) {
      if (data && data.length > 0) {
        this.reportData = []
        this.exportData = []
        var temp = this._.sortBy(data, 'barcode')

        this.reportData = this._.each(temp, function(item) {
          item.wholesalePrice = (item.wholesalePrice / 100).toFixed(2)
          item.retailPrice = (item.retailPrice / 100).toFixed(2)
          item.total = (item.total / 100).toFixed(2)
          delete item.__typename
          delete item.currency
        })

        // Create export data
        this.reportData.forEach(item => {
          this.exportData.push({
            Barcode: item.barcode,
            'Style Number': item.styleNumber,
            Name: item.name,
            Size: item.size,
            Quantity: item.qty,
            'Wholesale Price (EUR)': parseFloat(item.wholesalePrice),
            'Retail Price (EUR)': parseFloat(item.retailPrice),
            'Total (EUR)': parseFloat(item.total)
          })
        })
      } else {
        this.reportData = []
      }
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 128px;
  /* --page-wrap-offset: -20px; */
}
</style>
