var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-layout"},[_c('div',{staticClass:"page-wrap"},[_c('div',{staticClass:"page-header pt-0 px-1"},[_c('v-toolbar',{staticClass:"mb-3 b-radius",attrs:{"color":"transparent","flat":""}},[_c('customer-edit-dialog',{attrs:{"dialog":_vm.dialog,"customer":_vm.editedItem,"agents":_vm.agents,"currencies":_vm.currencies,"warehouses":_vm.warehouses,"edit-mode":_vm.editMode,"processing":_vm.processing,"error-msg":_vm.errorMsg},on:{"close-customer-edit-dialog":_vm.close,"save-customer-edit-dialog":_vm.save,"agent-search":_vm.agentSearch}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"color":"primary","rounded":""},on:{"click":_vm.addCustomer}},[_vm._v(" add customer ")])],1)],1)],1)],1)],1),_c('div',{staticClass:"main-page-column pt-0 pr-3"},[_c('div',{staticClass:"scroll-container pt-0"},[_c('v-card',{staticClass:"mb-3",attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search...","single-line":"","hide-details":"","clearable":"","autocomplete":"off"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"plain-table",attrs:{"headers":_vm.headers,"loading":_vm.$apollo.loading,"items":_vm.customers.items,"options":_vm.options,"server-items-length":_vm.itemCount,"mobile-breakpoint":null,"footer-props":{'items-per-page-options':[15, 30, 50, 100]},"hide-default-footer":!_vm.customers || _vm.itemCount < _vm.options.itemsPerPage + 1},on:{"update:options":function($event){_vm.options=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.navToDetail},scopedSlots:_vm._u([{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updatedAt,'from'))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('agent-state-label',{attrs:{"state":item.state}})]}},{key:"item.storeName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-2"},[_c('div',{staticClass:"text-truncate text-subtitle-1 font-weight-regular primary--text"},[_vm._v(" "+_vm._s(item.storeName)+" ")]),(
                    item &&
                      item.companyName &&
                      item.storeName &&
                      item.storeName != item.companyName
                  )?_c('div',{staticClass:"text-truncate text-caption font-weight-light"},[_vm._v(" "+_vm._s(item.companyName)+" ")]):_vm._e()])]}},{key:"item.agent",fn:function(ref){
                  var item = ref.item;
return [(item.agent)?_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(item.agent.name)+" ")]):_vm._e()]}},{key:"item.visitingAddress",fn:function(ref){
                  var item = ref.item;
return [(item.visitingAddress && item.visitingAddress.id != '')?_c('div',{staticClass:"text-truncate"},[(item.visitingAddress.line1)?_c('span',[_vm._v(_vm._s(item.visitingAddress.line1 + ', '))]):_vm._e(),(item.visitingAddress.city)?_c('span',[_vm._v(_vm._s(item.visitingAddress.city + ', '))]):_vm._e(),(item.visitingAddress.country)?_c('span',[_vm._v(_vm._s(_vm.getFullCountryName(item.visitingAddress.country)))]):_vm._e()]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v("Nothing here yet...")]},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }