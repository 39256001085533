<template>
  <div class="app-layout">
    <div class="page-wrap">
      <!-- <div class="page-header pt-0 px-1">
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <season-edit-dialog
            :open="dialog"
            :edited-item="editedItem"
            :edit-mode="edit"
            @close-edit-season-dialog="close"
          ></season-edit-dialog>
          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-btn
                  v-if="authLevel === 2"
                  color="primary"
                  rounded
                  @click="newItem"
                >
                  add season
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>
      </div> -->
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-row justify="center">
            <v-col sm="12" md="10" lg="10" xl="8">
              <v-card flat>
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details
                    clearable
                    autocomplete="off"
                    :disabled="
                      !seasons ||
                        !seasons.seasons ||
                        seasons.seasons.length === 0
                    "
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="seasons.seasons"
                  :search="search"
                  :loading="$apollo.loading"
                  :mobile-breakpoint="null"
                  class="plain-table"
                  :hide-default-footer="
                    seasons && seasons.seasons && seasons.seasons.length < 11
                  "
                  @click:row="navToDetail"
                >
                  <template v-slot:item.shortName="{ item }">
                    <span>{{ item.shortName }}</span>
                    <v-chip
                      v-if="
                        item.from && item.till
                          ? isCurrentSeason(item.from, item.till)
                          : false
                      "
                      x-small
                      color="success lighten-3 font-weight-medium upperCaseSpacing"
                      text-color="success"
                      class="ml-3"
                      >active</v-chip
                    >
                  </template>
                  <template v-slot:item.seasonPeriod="{ item }">
                    <span>{{ item.from | moment('DD/MM/YY') }}</span>
                    <span>- {{ item.till | moment('DD/MM/YY') }}</span>
                  </template>
                  <template v-slot:item.prebookPeriod="{ item }">
                    <span>{{
                      item.prebookStartDate | moment('DD/MM/YY')
                    }}</span>
                    <span
                      >- {{ item.prebookEndDate | moment('DD/MM/YY') }}</span
                    >
                  </template>
                  <template v-slot:item.firstDropPeriod="{ item }">
                    <span>{{
                      item.firstDropStartDate | moment('DD/MM/YY')
                    }}</span>
                    <span
                      >- {{ item.firstDropEndDate | moment('DD/MM/YY') }}</span
                    >
                  </template>
                  <template v-slot:no-data>Nothing here yet...</template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SEASONS from '@/graphql/Seasons.gql'

export default {
  name: 'SeasonList',
  components: {},
  data: () => ({
    dialog: false,
    edit: false,
    search: '',
    seasons: [],
    defaultItem: {
      id: '',
      shortName: '',
      longName: '',
      status: 'active',
      from: new Date().toISOString(),
      till: new Date().toISOString(),
      updatedAt: new Date(),
      createdAt: new Date()
    },
    editedItem: {
      id: '',
      shortName: '',
      longName: '',
      status: 'active',
      from: new Date().toISOString(),
      till: new Date().toISOString(),
      updatedAt: new Date(),
      createdAt: new Date()
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Code',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'shortName'
        },
        {
          text: 'Name',
          value: 'longName',
          visible: false,
          sortable: false
        },
        {
          text: 'Style Prefix',
          value: 'styleNumberPrefix',
          visible: false,
          sortable: false
        },
        {
          text: 'Season Period',
          align: 'left',
          value: 'seasonPeriod',
          filterable: true,
          sortable: false
        },
        {
          text: 'Prebook Period',
          align: 'left',
          value: 'prebookPeriod',
          filterable: true,
          sortable: false
        },
        {
          text: 'Drop Period',
          align: 'left',
          value: 'firstDropPeriod',
          filterable: true,
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('seasons')
    }
  },
  apollo: {
    seasons: SEASONS
  },
  methods: {
    close(success, id) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Season Saved'
        })

        this.$router.push({
          name: 'SalesSeasonDetail',
          params: { seasonID: id }
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    navToDetail(item) {
      this.$router.push({
        name: 'SalesSeasonDetail',
        params: { seasonID: item.id }
      })
    },
    isCurrentSeason(from, till) {
      var today = this.$moment()
      var start = this.$moment(from)
      var end = this.$moment(till)

      return this.$moment(today).isBetween(start, end)
    }
  }
}
</script>

<style lang="scss">
// .list-item {
//   // border: 1px solid transparent;
//   &:hover {
//     border-color: var(--v-primary-base);
//   }
// }

// .plain-table .v-input__slot {
//   background: transparent !important;
// }

// .plain-table.theme--light.v-data-table
//   tbody
//   tr:hover:not(.v-data-table__expanded__content) {
//   cursor: pointer !important;
// }
</style>
