<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header pt-0 px-1">
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <customer-edit-dialog
            :dialog="dialog"
            :customer="editedItem"
            :agents="agents"
            :currencies="currencies"
            :warehouses="warehouses"
            :edit-mode="editMode"
            :processing="processing"
            :error-msg="errorMsg"
            @close-customer-edit-dialog="close"
            @save-customer-edit-dialog="save"
            @agent-search="agentSearch"
          ></customer-edit-dialog>
          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-btn color="primary" rounded @click="addCustomer">
                  add customer
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0 pr-3">
        <div class="scroll-container pt-0">
          <v-card flat class="mb-3">
            <v-card-title>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
                clearable
                autocomplete="off"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :loading="$apollo.loading"
              :items="customers.items"
              :options.sync="options"
              :server-items-length="itemCount"
              :mobile-breakpoint="null"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
              :hide-default-footer="!customers || itemCount < options.itemsPerPage + 1"
              class="plain-table"
              @page-count="pageCount = $event"
              @click:row="navToDetail"
            >
              <template v-slot:item.updatedAt="{ item }">
                {{ item.updatedAt | moment('from') }}
              </template>
              <template v-slot:item.state="{ item }">
                <agent-state-label :state="item.state"></agent-state-label>
              </template>
              <!-- <template v-slot:item.companyName="{ item }">
                <div class="text-truncate">{{ item.companyName }}</div>
              </template> -->
              <template v-slot:item.storeName="{ item }">
                <div class="py-2">
                  <div
                    class="text-truncate text-subtitle-1 font-weight-regular primary--text"
                  >
                    {{ item.storeName }}
                  </div>
                  <div
                    v-if="
                      item &&
                        item.companyName &&
                        item.storeName &&
                        item.storeName != item.companyName
                    "
                    class="text-truncate text-caption font-weight-light"
                  >
                    {{ item.companyName }}
                  </div>
                </div>
              </template>
              <template v-slot:item.agent="{ item }">
                <div v-if="item.agent" class="text-truncate">
                  {{ item.agent.name }}
                </div>
              </template>
              <template v-slot:item.visitingAddress="{ item }">
                <div
                  v-if="item.visitingAddress && item.visitingAddress.id != ''"
                  class="text-truncate"
                >
                  <span v-if="item.visitingAddress.line1">{{
                    item.visitingAddress.line1 + ', '
                  }}</span>

                  <span v-if="item.visitingAddress.city">{{
                    item.visitingAddress.city + ', '
                  }}</span>
                  <span v-if="item.visitingAddress.country">{{
                    getFullCountryName(item.visitingAddress.country)
                  }}</span>
                </div>
              </template>
              <template v-slot:no-data>Nothing here yet...</template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {lookup} from 'country-data-list'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import AGENTS from '@/graphql/Agents.gql'
import CUSTOMER_LIST from '@/graphql/SalesCustomers.gql'
import CUSTOMER_CREATE from '@/graphql/SalesCustomerCreate.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'
import CustomerEditDialog from '@/modules/sales/components/core/CustomerEditDialog.vue'
import AgentStateLabel from '@/modules/sales/components/core/AgentStateLabel.vue'

export default {
  name: 'CustomersList',
  components: { CustomerEditDialog, AgentStateLabel },
  data: () => ({
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    search: '',
    agentSearch: '',
    selected: [],
    agents: { items: [], total: 0, page: 1 },
    customers: { items: [], total: 0, page: 1 },
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    defaultItem: {
      companyName: '',
      storeName: '',
      commerceReg: '',
      shippingNumber: '',
      vatId: '',
      status: 'PENDINGCONTRACT',
      website: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: '',
      preNotification: false,
      deliveryConditions: 'DAP',
      eoriNumber: null,
      danNumber: null,
      shippingNumber: null
    },
    editedItem: {
      companyName: '',
      storeName: '',
      commerceReg: '',
      shippingNumber: '',
      vatId: '',
      status: 'PENDINGCONTRACT',
      website: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: '',
      preNotification: false,
      deliveryConditions: 'DAP',
      eoriNumber: null,
      danNumber: null,
      shippingNumber: null
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Store Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'storeName'
        },
        // {
        //   text: 'Company Name',
        //   align: 'left',
        //   sortable: false,
        //   filterable: true,
        //   value: 'companyName'
        // },
        {
          text: 'Agent',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'agent'
        },
        {
          text: 'Address',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'visitingAddress'
        },
        {
          text: 'Status',
          value: 'state',
          visible: false,
          sortable: false
        },
        {
          text: 'Last Update',
          align: 'left',
          value: 'updatedAt',
          filterable: true,
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('customers')
    }
  },
  apollo: {
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            search: null,
            page: 1,
            pageSize: 1000
          }
        }
      }
    },
    customers: {
      query: CUSTOMER_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        // console.log('data: ' + result.data.products.page)
        // console.log('key: ' + key)
        this.page = result?.data?.customers.page
        this.itemsPerPage = result?.data?.customers.pageSize
        this.itemCount = result?.data?.customers.total
      }
    },
    agents: {
      query: AGENTS,
      variables() {
        return {
          filters: {
            search: this.agentSearch,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200
    },
    currencies: CURRENCY_LIST
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    getFullCountryName(code) {
      var countryName = lookup.countries({alpha2: code})[0]
      if (countryName && countryName.name) {
        return countryName.name
      } else {
        return ''
      }
    },
    close() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
    },
    addCustomer() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: CUSTOMER_CREATE,
          variables: {
            input: item
          }
        })
        .then(result => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Customer Added'
          })
          this.$router.push({
            name: 'SalesCustomerDetail',
            params: { customerID: result.data.createCustomer }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    navToDetail(item) {
      this.$router.push({
        name: 'SalesCustomerDetail',
        params: { customerID: item.id }
      })
    }
  }
}
</script>

<style lang="scss">
// .list-item {
//   // border: 1px solid transparent;
//   &:hover {
//     border-color: var(--v-primary-base);
//   }
// }

// .plain-table .v-input__slot {
//   background: transparent !important;
// }

// .plain-table.theme--light.v-data-table
//   tbody
//   tr:hover:not(.v-data-table__expanded__content) {
//   cursor: pointer !important;
// }
</style>
