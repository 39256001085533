<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header pt-0 px-1">
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <sales-order-edit-dialog
            :dialog="dialog"
            :edited-item="editedItem"
            :customers="customers"
            :warehouses="warehouses"
            :edit-mode="edit"
            :processing="processing"
            :error-msg="errorMsg"
            @close-sales-order-edit-dialog="close"
            @save-sales-order-edit-dialog="save"
          ></sales-order-edit-dialog>
          <sales-order-clone-dialog
            :dialog="cloneDialog"
            :edited-item="cloneEditedItem"
            :customers="customers"
            :processing="processing"
            :error-msg="errorMsg"
            @close-sales-order-clone-dialog="cancelClone"
            @save-sales-order-clone-dialog="saveClone"
          ></sales-order-clone-dialog>
          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-btn v-if="authLevel === 2" color="primary" rounded @click="newItem">
                  add order
                </v-btn>
              </v-row>
            </v-col>
            <v-col class="px-3">
              <v-autocomplete
                v-model="customerID"
                no-filter
                :items.sync="customers.items"
                :search-input.sync="searchCustomers"
                outlined
                dense
                clearable
                background-color="#fff"
                item-value="id"
                hide-details="auto"
                label="Customer"
                placeholder="-- Select --"
              >
              <template v-slot:selection="data">
                {{ data.item.storeName }} <span v-if="data.item.companyName && data.item.storeName !== data.item.companyName" class="pl-2">({{ data.item.companyName }})</span>
              </template>
              <template v-slot:item="data">
                {{ data.item.storeName }} <span v-if="data.item.companyName && data.item.storeName !== data.item.companyName" class="pl-2">({{ data.item.companyName }})</span>
              </template>
              </v-autocomplete>
            </v-col>
            <v-col class="px-3">
              <v-select
                v-model="salesOrderState"
                :items="salesOrderStates"
                label="Status"
                placeholder="all"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
              ></v-select>
            </v-col>
            <v-col class="px-3">
              <v-select
                v-model="salesOrderType"
                :items="salesOrderTypes"
                label="Type"
                placeholder="all"
                hide-details
                outlined
                flat
                dense
                clearable
                background-color="#fff"
              ></v-select>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0 pr-3">
        <div class="scroll-container pt-0">
          <v-card flat class="mb-3">
            <v-card-title>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
                clearable
                autocomplete="off"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              :headers="headers"
              :items="salesOrders.items"
              :search="search"
              :options.sync="options"
              :loading="$apollo.loading"
              :footer-props="{ 'items-per-page-options': [15, 30, 50, 100] }"
              :server-items-length="itemCount"
              :mobile-breakpoint="null"
              class="plain-table grey--text text--darken-2"
              :hide-default-footer="
                salesOrders.total < options.itemsPerPage + 1
              "
              :hide-default-header="salesOrders.total === 0"
              @click:row="navToDetail"
            >
              <template v-slot:item.customer="{ item }">
                <div class="text-truncate">{{ item.customer.storeName }}</div>
              </template>
              <template v-slot:item.state="{ item }">
                <sales-order-state-label
                  :state="item.state"
                  x-small
                ></sales-order-state-label>
              </template>
              <template v-slot:item.customerPoNumber="{ item }">
                <div class="text-truncate">{{ item.customerPoNumber }}</div>
              </template>
              <template v-slot:item.notes="{ item }">
                <div class="text-truncate">{{ item.notes }}</div>
              </template>
              <template v-slot:item.net="{ item }">
                <div class="text-truncate">
                  {{
                    (item.net / 100)
                      | currency(item.currencySymbol, 2, {
                        spaceBetweenAmountAndSymbol: true
                      })
                  }}
                </div>
              </template>
              <template v-slot:item.orderDate="{ item }">
                {{ item.orderDate | moment('LL') }}
              </template>
              <template v-slot:item.createdBy="{ item }">
                <div
                  v-if="item.createdBy && item.createdBy.firstName"
                  class="text-truncate"
                >
                  {{ item.createdBy.firstName + ' ' + item.createdBy.lastName }}
                </div>
              </template>
              <template v-slot:item.allocation.unFulfilledQuantity="data">
                <span
                  v-if="
                    data.type !== 'RETURN' &&
                      data.item.allocation &&
                      data.item.allocation.id
                  "
                  >{{ data.item.allocation.unFulfilledQuantity }}</span
                >
                <span v-else>{{ data.item.qty }}</span>
              </template>
              <template v-slot:item.controls="data">
                <v-btn
                  v-if="
                    data.type !== 'RETURN' &&
                      data.item.allocation &&
                      data.item.allocation.id
                  "
                  color="info"
                  text
                  small
                  rounded
                  @click.stop="navToAllocation(data.item.allocation.id)"
                  >view allocation</v-btn
                >
              </template>
              <template v-slot:item.cloneControls="data">
                <v-btn
                  v-if="data.item.type !== 'RETURN'"
                  color="grey lighten-1"
                  text
                  small
                  rounded
                  @click.stop="cloneOrder(data.item)"
                  >clone</v-btn
                >
              </template>
              <template v-slot:no-data>Nothing here yet...</template>
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SalesOrderStateLabel from '../components/core/SalesOrderStateLabel.vue'
import SalesOrderEditDialog from '@/modules/sales/components/core/SalesOrderEditDialog.vue'
import SalesOrderCloneDialog from '@/modules/sales/components/core/SalesOrderCloneDialog.vue'
import CUSTOMER_LIST from '@/graphql/SalesCustomers.gql'
import SALES_ORDER_LIST from '@/graphql/SalesOrders.gql'
import SALES_ORDER_CREATE from '@/graphql/SalesOrderCreate.gql'
import SALES_ORDER_CLONE from '@/graphql/SalesOrderClone.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'

export default {
  name: 'SalesOrderList',
  components: {
    SalesOrderStateLabel,
    SalesOrderEditDialog,
    SalesOrderCloneDialog
  },
  data: () => ({
    dialog: false,
    cloneDialog: false,
    edit: false,
    errorMsg: '',
    processing: false,
    search: '',
    customerID: null,
    searchCustomers: '',
    searchLineSheet: '',
    itemCount: 0,
    options: { page: 1, itemsPerPage: 15 },
    salesOrders: { items: [], total: 0, page: 1 },
    customers: { items: [] },
    defaultItem: {
      id: null,
      customerID: null,
      notes: null,
      customerPoNumber: null,
      rmaNumber: null,
      type: 'REORDER'
    },
    editedItem: {},
    cloneEditedItem: {
      orderID: null,
      customerID: null,
      notes: null,
      customerPoNumber: null,
      deliveryStartDate: new Date(),
      deliveryEndDate: new Date()
    },
    salesOrderState: null,
    salesOrderStates: [
      'DRAFT',
      'SUBMITTED',
      'REVIEW',
      'APPROVED',
      'PROCESSEDPARTIAL',
      'PROCESSED',
      'SHIPPEDPARTIAL',
      'SHIPPED',
      'CANCELED'
    ],
    salesOrderType: null,
    salesOrderTypes: ['PREBOOK', 'REORDER', 'RETURN']
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Order #',
          value: 'orderNumber',
          align: 'left',
          visible: false,
          sortable: false
        },
        {
          text: 'NuOrder Order #',
          value: 'nuorderOrderNumber',
          align: 'left',
          visible: false,
          sortable: false
        },
        {
          text: 'Order Date',
          align: 'left',
          value: 'orderDate',
          filterable: true,
          sortable: false
        },
        {
          text: '',
          value: 'state',
          visible: false,
          sortable: false,
          align: 'center'
        },
        {
          text: 'Order Type',
          value: 'type',
          visible: false,
          sortable: false
        },
        {
          text: 'Customer',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'customer'
        },
        {
          text: 'Customer PO',
          align: 'left',
          sortable: false,
          visible: false,
          value:'customerPoNumber'
        },
        {
          text: 'Order Notes',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'notes'
        },
        {
          text: 'Total',
          align: 'right',
          value: 'net',
          filterable: true,
          sortable: false
        },
        {
          text: 'Total Units',
          align: 'right',
          value: 'qty',
          filterable: true,
          sortable: false
        },
        {
          text: 'Unfullfiled Qty',
          align: 'right',
          value: 'allocation.unFulfilledQuantity',
          filterable: true,
          sortable: false
        },
        {
          text: 'Created By',
          value: 'createdBy',
          filterable: true,
          sortable: false
        },
        {
          text: '',
          value: 'controls',
          filterable: true,
          sortable: false
        },
        {
          text: '',
          value: 'cloneControls',
          filterable: true,
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('sales_orders')
    }
  },
  apollo: {
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            search: null,
            page: 1,
            pageSize: 1000
          }
        }
      }
    },
    customers: {
      query: CUSTOMER_LIST,
      variables() {
        return {
          filters: {
            search: this.searchCustomers,
            page: 1,
            pageSize: 10000
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200
    },
    salesOrders: {
      query: SALES_ORDER_LIST,
      variables() {
        return {
          filters: {
            search: this.search,
            customerID: this.customerID,
            status: this.salesOrderState,
            orderType: this.salesOrderType,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.itemCount = result.data.salesOrders.total

        this.options.page = result.data.salesOrders.page
        this.options.itemsPerPage = result.data.salesOrders.pageSize

        // set page state
        this.$store.commit('sales/SET_SALES_ORDERS_PAGE', this.options.page)
        this.$store.commit(
          'sales/SET_SALES_ORDERS_PAGE_SIZE',
          this.options.itemsPerPage
        )
        this.$store.commit('sales/SET_SALES_ORDERS_SEARCH', this.search)
        this.$store.commit('sales/SET_SALES_ORDERS_STATE', this.salesOrderState)
        this.$store.commit('sales/SET_SALES_ORDERS_TYPE', this.salesOrderType)
        this.$store.commit('sales/SET_SALES_ORDERS_CUSTOMER', this.customerID)
      }
    }
  },
  watch: {
    search() {
      this.options.page = 1
    }
  },
  created() {
    var that = this
    var filters = this.$store.state.sales.salesOrdersPage
    window.onpopstate = function() {
      that.options.page = filters.page
      that.options.itemsPerPage = filters.pageSize

      that.search = filters.search
      that.salesOrderState = filters.state
      that.salesOrderType = filters.type
      that.customerID = filters.customer
    }
  },
  methods: {
    cloneOrder(item) {
      this.cloneEditedItem = {
        orderID: item.id,
        customer: item.customer,
        notes: null,
        customerPoNumber: null,
        deliveryStartDate: item.deliveryStartDate,
        deliveryEndDate: item.deliveryEndDate
      }

      if (this.$moment().isAfter(item.deliveryStartDate)) {
        this.cloneEditedItem.deliveryStartDate = this.$moment()
          .add(1, 'days')
          .utc()

        this.cloneEditedItem.deliveryEndDate = this.$moment()
          .add(30, 'days')
          .utc()
      }

      this.cloneDialog = true
    },
    cancelClone() {
      this.cloneEditedItem = {
        orderID: null,
        customerID: null,
        notes: null,
        customerPoNumber: null,
        deliveryStartDate: new Date(),
        deliveryEndDate: new Date()
      }
      this.cloneDialog = false
      this.errorMsg = ''
      this.processing = false
    },
    saveClone(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_CLONE,
          variables: {
            input: item
          }
        })
        .then(result => {
          this.cancelClone()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Cloned'
          })
          this.$router.push({
            name: 'SalesOrderDetails',
            params: { salesOrderID: result.data.cloneSalesOrder }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    close(success, id) {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
      // Show successfully saved
      if (success) {
        this.$store.commit('showSnackbar', {
          active: true,
          message: 'Sales Order Saved'
        })

        this.$router.push({
          name: 'SalesOrderDetails',
          params: { seasonID: id }
        })
      }
    },
    newItem() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: SALES_ORDER_CREATE,
          variables: {
            input: item
          }
        })
        .then(result => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Sales Order Created'
          })
          this.$router.push({
            name: 'SalesOrderDetails',
            params: { salesOrderID: result.data.createSalesOrder }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    navToDetail(item) {
      this.$router.push({
        name: 'SalesOrderDetails',
        params: { salesOrderID: item.id }
      })
    },
    navToAllocation(id) {
      this.$router.push({
        name: 'SalesAllocationDetails',
        params: { allocationID: id }
      })
    }
  }
}
</script>

<style lang="scss">
// .list-item {
//   // border: 1px solid transparent;
//   &:hover {
//     border-color: var(--v-primary-base);
//   }
// }

// .plain-table .v-input__slot {
//   background: transparent !important;
// }

// .plain-table.theme--light.v-data-table
//   tbody
//   tr:hover:not(.v-data-table__expanded__content) {
//   cursor: pointer !important;
// }
</style>
