<template>
  <span>
    <v-chip
      pill
      x-small
      :color="styleState(state)"
      :text-color="styleText(state)"
      class="upperCaseSpacing"
    >
      {{ state }}
    </v-chip>
  </span>
</template>

<script>
export default {
  name: 'AgentStateLabel',
  props: {
    state: { type: String, required: true }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    styleState(state) {
      var color
      switch (state) {
        case 'PENDINGCONTRACT':
          color = 'warning lighten-1 font-weight-medium'
          break
        case 'ACTIVE':
          color = 'success lighten-2 font-weight-medium'
          break
        default:
          color = 'grey lighten-2 font-weight-medium'
          break
      }
      return color
    },
    styleText(state) {
      var color
      switch (state) {
        case 'PENDINGCONTRACT':
          color = 'warning darken-3'
          break
        case 'ACTIVE':
          color = 'success darken-1'
          break
        default:
          color = 'grey darken-1'
          break
      }
      return color
    }
  }
}
</script>

<style></style>
