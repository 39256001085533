<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container pt-0">
          <v-form
            ref="agentForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row id="scroll-target" justify="center">
              <v-col cols="12">
                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.name"
                          autocomplete="off"
                          label="Name"
                          hide-details="auto"
                          outlined
                          :rules="requiredRule"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Name</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.commerceReg"
                          autocomplete="off"
                          label="Company Registration"
                          persistent-hint
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Priority</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.shippingNumber"
                          autocomplete="off"
                          label="Shipping Number"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <!-- <v-col cols="12" class="pb-0">
                        <label>Email</label>
                      </v-col> -->
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.vatId"
                          autocomplete="off"
                          label="VAT ID"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.website"
                          autocomplete="off"
                          label="Website"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.linkedin"
                          autocomplete="off"
                          label="Linkedin"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.facebook"
                          autocomplete="off"
                          label="Facebook"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.twitter"
                          outlined
                          autocomplete
                          hide-details="auto"
                          return-object
                          dense
                          label="Twitter"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="item.instagram"
                          autocomplete="off"
                          label="Instagram"
                          hide-details="auto"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn text color="grey lighten-1" :disabled="processing" @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import country from 'country-list'

export default {
  name: 'AgentEditDialog',
  props: {
    agent: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    priorities: ['low', 'medium', 'high'],
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required']
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Agent' : 'New Agent'
    },
    item() {
      return this._.cloneDeep(this.agent)
    },
    countryList() {
      return this._.sortBy(country.getData(), 'name')
    }
  },
  watch: {},
  methods: {
    close() {
      this.$refs.agentForm.resetValidation()
      this.$emit('close-agent-edit-dialog', false)
    },
    save() {
      if (this.$refs.agentForm.validate()) {
        var data = {
          id: this.item.id,
          name: this.item.name,
          commerceReg: this.item.commerceReg,
          shippingNumber: this.item.shippingNumber,
          vatId: this.item.vatId,
          state: 'PENDINGCONTRACT',
          website: this.item.website,
          facebook: this.item.facebook,
          twitter: this.item.twitter,
          linkedin: this.item.linkedin,
          instagram: this.item.instagram
        }

        if (!this.editMode) {
          delete data.id
          delete data.state
        }

        this.$emit('save-agent-edit-dialog', data)
      }
    }
  }
}
</script>

<style></style>
