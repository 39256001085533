<template>
  <div class="page-wrap">
    <customer-detail-header
      :customer="customer"
      :auth-level="authLevel"
    ></customer-detail-header>

    <div class="main-page-column">
      <div class="scroll-container">
        <router-view
          :customer="customer"
          :agents="agents"
          :warehouses="warehouses"
          :current-user="currentUser"
          :auth-level="authLevel"
          :currencies="currencies"
          :loading="$apollo.loading"
          @refetch-customer="$apollo.queries.customer.refetch()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AGENTS from '@/graphql/Agents.gql'
import CustomerDetailHeader from '@/modules/sales/components/core/CustomerDetailHeader.vue'
import CUSTOMER from '@/graphql/SalesCustomer.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import WAREHOUSE_LIST from '@/graphql/Warehouses.gql'

export default {
  name: 'CustomerDetailLayout',
  components: {
    CustomerDetailHeader
  },
  data: () => ({}),
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('sales_customers')
    }
  },
  apollo: {
    warehouses: {
      query: WAREHOUSE_LIST,
      variables() {
        return {
          filters: {
            search: null,
            page: 1,
            pageSize: 1000
          }
        }
      }
    },
    agents: {
      query: AGENTS,
      variables() {
        return {
          filters: {
            search: this.search,
            page: 1,
            pageSize: 10
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200
    },
    customer: {
      query: CUSTOMER,
      variables() {
        return {
          id: this.$route.params.customerID
        }
      }
    },
    currencies: CURRENCY_LIST
  },
  watch: {},
  created() {},
  methods: {}
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 92px;
  /* --page-wrap-offset: -20px; */
}
</style>
