<template>
  <div>
    <PickTicketListTable
      :pick-tickets="pickTickets"
      :loading="$apollo.loading"
      :options="options"
    ></PickTicketListTable>
  </div>
</template>

<script>
import PickTicketListTable from '@/modules/logistics/components/core/PickTicketListTable.vue'
import PICK_TICKET_LIST_BY_SALES_ORDER from '@/graphql/PickTicketsBySalesOrder.gql'

export default {
  name: 'SalesPickTicketList',
  components: { PickTicketListTable },
  props: {
    salesOrder: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    search: null,
    options: { page: 1, itemsPerPage: 100 },
    itemCount: 0,
    selected: [],
    pickTickets: { items: [] },
    warehouses: { items: [] },
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    warehouseID: null,
    defaultItem: {
      id: null,
      stockChange: 0
    },
    editedItem: {
      id: null,
      stockChange: 0
    }
  }),
  apollo: {
    pickTicketsBySalesOrder: {
      query: PICK_TICKET_LIST_BY_SALES_ORDER,
      variables() {
        return {
          salesOrderID: this.salesOrder.id
        }
      },
      // fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        if (result?.data?.pickTicketsBySalesOrder) {
          this.pickTickets.items = result.data.pickTicketsBySalesOrder
        }
      },
      skip() {
        return !this.salesOrder.id
      }
    }
  },
  computed: {},
  methods: {
    navToDetail(item) {
      this.$router.push({
        name: 'SalesPickTicketDetails',
        params: { pickTicketID: item.id }
      })
    }
  }
}
</script>

<style></style>
