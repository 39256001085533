<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <line-sheet-item-edit-dialog
        :dialog="dialog"
        :line-sheet="lineSheet"
        :line-sheet-item="editedItem"
        :seasons="seasons.seasons"
        :edit-mode="editMode"
        :processing="processing"
        :error-msg="errorMsg"
        @close-line-sheet-item-edit-dialog="close"
        @save-line-sheet-item-edit-dialog="update"
        @remove-line-sheet-item-edit-dialog="openRemoveDialog"
      ></line-sheet-item-edit-dialog>
      <v-dialog v-model="removeDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Line Sheet Item</v-card-title
          >
          <v-card-text>
            <p>
              This will remove the item and can't be undone.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey darken-1" text @click="removeDialog = false"
              >Cancel</v-btn
            >
            <v-btn color="error" text @click="remove">Remove</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="removeCategoryDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline error--text mb-3"
            >Remove Category</v-card-title
          >
          <v-card-text>
            <p>
              This will remove the category, but not its items.
            </p>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey darken-1" text @click="closeEditCategoryDialog"
              >Cancel</v-btn
            >
            <v-btn color="error" text @click="removeCategory">Remove</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="addCategoryDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline primary--text mb-3"
            >Add Category</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editCategory.name"
                  label="Name"
                  autocomplete="off"
                  outlined
                  required
                  hide-details="auto"
                  dense
                  autofocus
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey darken-1" text @click="closeEditCategoryDialog"
              >Cancel</v-btn
            >
            <v-btn color="primary" small rounded @click="addCategory"
              >Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="updateCategoryDialog" persistent max-width="350">
        <v-card>
          <v-card-title class="headline primary--text mb-3"
            >Update Category</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editCategory.name"
                  label="Name"
                  autocomplete="off"
                  outlined
                  required
                  hide-details="auto"
                  dense
                  autofocus
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="grey darken-1" text @click="closeEditCategoryDialog"
              >Cancel</v-btn
            >
            <v-btn color="primary" small rounded @click="updateCategory"
              >Update</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row>
        <v-col cols="12" class="pt-0">
          <!-- New Product Header -->
          <v-card v-if="lineSheet" flat class="px-3 mb-3">
            <v-row>
              <v-col cols="3">
                <v-select
                  v-model="selectedSeason"
                  hide-details
                  outlined
                  flat
                  dense
                  clearable
                  label="season"
                  placeholder="select"
                  item-value="id"
                  :background-color="'#fff'"
                  :items="seasons.seasons"
                  :disabled="!seasons || seasons.seasons.length === 0"
                >
                  <template v-slot:item="{ item }">
                    {{ item.longName }} ({{ item.shortName }})
                  </template>
                  <template v-slot:selection="{ item }">
                    <div class="text-truncate">{{ item.shortName }}</div>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  v-model="selectedProducts"
                  label="Products"
                  placeholder="select product(s)"
                  :search-input.sync="search"
                  item-text="name"
                  item-value="id"
                  multiple
                  autocomplete="off"
                  :items="unusedProducts"
                  dense
                  outlined
                  hide-details
                  clearable
                  :disabled="authLevel !== 2 || !selectedSeason"
                >
                  <template
                    v-if="selectedProducts.length !== unusedProducts.length"
                    v-slot:prepend-item
                  >
                    <v-list-item @click="addAll">
                      <v-list-item-content>
                        <v-list-item-title class="info--text">
                          <div>
                            Select All {{ unusedProducts.length }} Items
                          </div>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:item="{ item }">
                    <div>
                      {{ item.name }}
                      <span
                        v-if="item.seasonColor"
                        class="text-lowercase grey--text darken-1"
                      >
                        - {{ item.seasonColor }}</span
                      >
                    </div>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <v-chip v-if="index === 0" small>
                      <span class="text-truncate">{{ item.name }}</span>
                    </v-chip>
                    <span v-if="index === 1" class="grey--text caption"
                      >(+{{ selectedProducts.length - 1 }} others)</span
                    >
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-btn
                  :disabled="!selectedProducts || selectedProducts.length === 0"
                  :loading="processing"
                  small
                  rounded
                  color="primary lighten-2"
                  class="mt-1"
                  @click="addProducts"
                  >Add Product(s)</v-btn
                >
              </v-col>
              <v-col>
                <v-row justify="end">
                  <div class="mt-2 mr-3">
                    <v-btn
                      small
                      rounded
                      color="primary"
                      @click="openAddCategoryDialog"
                      >Add Category</v-btn
                    >
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          v-if="!$apollo.loading && !loading && lineSheet"
          cols="12"
          class="pt-0"
        >
          <div v-if="lineSheet.categories[0].items.length > 0">
            <v-row
              v-for="category in limitBy(lineSheet.categories, 1)"
              :key="category.id"
              :class="[category.position > -1 ? 'drag-handle' : '']"
            >
              <v-col v-if="category.position > -1" cols="12" class="pb-0">
                <v-row justify="space-between">
                  <v-col class="pb-0">
                    <h5 class="mb-2">
                      {{ category.name }}
                    </h5>
                  </v-col>
                  <v-col class="pb-0">
                    <v-row justify="end">
                      <v-btn
                        text
                        right
                        x-small
                        rounded
                        class="mr-2"
                        color="info"
                        @click="openEditCategoryDialog(category)"
                        >edit</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
              </v-col>

              <v-col cols="12" class="mb-6">
                <draggable
                  v-model="category.items"
                  class="row"
                  group="product"
                  ghost-class="ghost-item"
                  @change="moveItem(category.id, $event)"
                >
                  <v-col
                    v-for="(item, idx) in category.items"
                    :key="idx"
                    cols="12"
                    md="4"
                    lg="3"
                    xl="2"
                  >
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-card flat>
                          <div class="d-flex">
                            <div
                              class="px-3 py-0"
                              style="width: 100px; height: 160px;"
                            >
                              <v-row align="stretch">
                                <v-col>
                                  <v-img
                                    style="border-radius: 4px"
                                    center
                                    :src="
                                      item.product &&
                                      item.product.frontImage &&
                                      item.product.frontImage !== ''
                                        ? item.product.frontImage
                                        : '/DAILY_PAPER_LOGO.png'
                                    "
                                    lazy-src="/DAILY_PAPER_LOGO.png"
                                  ></v-img>
                                </v-col>

                                <v-col class="pa-0">
                                  <v-row class="mx-auto">
                                    <v-col class="pt-1">
                                      <active-inactive-label
                                        :state="item.state"
                                      ></active-inactive-label>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </div>
                            <div v-if="item.product" class="pa-3 text-truncate">
                              <div class="text-caption text-truncate">
                                {{ item.product.name }}
                              </div>
                              <div
                                class="text-caption text-truncate grey--text mt-3"
                              >
                                {{ item.product.styleNumber }}
                              </div>
                              <div
                                class="text-caption text-truncate grey--text"
                              >
                                {{ item.product.seasonColor }}
                              </div>
                              <div class="text-caption text-truncate mt-3">
                                <span class="grey--text">WSP: &nbsp;</span>
                                <span
                                  v-if="item.price && item.price.wholesalePrice"
                                  >{{
                                    (item.price.wholesalePrice / 100)
                                      | currency(
                                        item.price.currency.symbol,
                                        2,
                                        {
                                          spaceBetweenAmountAndSymbol: true
                                        }
                                      )
                                  }}</span
                                >
                              </div>
                              <div class="text-caption text-truncate">
                                <span class="grey--text">RRP: &nbsp;</span>
                                <span
                                  v-if="item.price && item.price.retailPrice"
                                  >{{
                                    (item.price.retailPrice / 100)
                                      | currency(
                                        item.price.currency.symbol,
                                        2,
                                        {
                                          spaceBetweenAmountAndSymbol: true
                                        }
                                      )
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute color="primary">
                              <v-row
                                ><v-btn small @click="editLineSheetItem(item)"
                                  >View Details</v-btn
                                ></v-row
                              >
                              <v-row justify="center" class="mt-3"
                                ><v-btn
                                  small
                                  text
                                  @click="openRemoveDialog(item.id)"
                                  >Remove</v-btn
                                ></v-row
                              >
                            </v-overlay>
                          </v-fade-transition>
                        </v-card>
                      </template>
                    </v-hover>
                  </v-col>
                </draggable>
              </v-col>
            </v-row>
          </div>

          <draggable
            v-model="categories"
            handle=".drag-handle"
            ghost-class="ghost-item"
            group="categories"
          >
            <v-row
              v-for="category in categories"
              :key="category.id"
              :class="[category.position > -1 ? 'drag-handle' : '']"
            >
              <v-col v-if="category.position > -1" cols="12" class="pb-0">
                <v-row justify="space-between">
                  <v-col class="pb-0">
                    <h5 class="mb-2">
                      {{ category.name }}
                    </h5>
                  </v-col>
                  <v-col class="pb-0">
                    <v-row justify="end">
                      <v-btn
                        text
                        right
                        x-small
                        rounded
                        class="mr-2"
                        color="grey lighten-1"
                        @click="openRemoveCategoryDialog(category.id)"
                        >remove</v-btn
                      >
                      <v-btn
                        text
                        right
                        x-small
                        rounded
                        class="mr-2"
                        color="info"
                        @click="openEditCategoryDialog(category)"
                        >edit</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>

                <v-divider></v-divider>
              </v-col>

              <v-col cols="12" class="mb-6">
                <draggable
                  v-model="category.items"
                  class="row"
                  group="product"
                  ghost-class="ghost-item"
                  @change="moveItem(category.id, $event)"
                >
                  <v-col
                    v-for="(item, idx) in category.items"
                    :key="idx"
                    cols="12"
                    md="4"
                    lg="3"
                    xl="2"
                  >
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-card flat>
                          <div class="d-flex">
                            <div
                              class="px-3 py-0"
                              style="width: 100px; height: 160px;"
                            >
                              <v-row align="stretch">
                                <v-col>
                                  <v-img
                                    style="border-radius: 4px"
                                    center
                                    :src="
                                      item.product &&
                                      item.product.frontImage &&
                                      item.product.frontImage !== ''
                                        ? item.product.frontImage
                                        : '/DAILY_PAPER_LOGO.png'
                                    "
                                    lazy-src="/DAILY_PAPER_LOGO.png"
                                  ></v-img>
                                </v-col>

                                <v-col class="pa-0">
                                  <v-row class="mx-auto">
                                    <v-col class="pt-1">
                                      <active-inactive-label
                                        :state="item.state"
                                      ></active-inactive-label>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </div>
                            <div v-if="item.product" class="pa-3 text-truncate">
                              <div class="text-caption text-truncate">
                                {{ item.product.name }}
                              </div>
                              <div
                                class="text-caption text-truncate grey--text mt-3"
                              >
                                {{ item.product.styleNumber }}
                              </div>
                              <div
                                class="text-caption text-truncate grey--text"
                              >
                                {{ item.product.seasonColor }}
                              </div>
                              <div class="text-caption text-truncate mt-3">
                                <span class="grey--text">WSP: &nbsp;</span>
                                <span
                                  v-if="item.price && item.price.wholesalePrice"
                                  >{{
                                    (item.price.wholesalePrice / 100)
                                      | currency(
                                        item.price.currency.symbol,
                                        2,
                                        {
                                          spaceBetweenAmountAndSymbol: true
                                        }
                                      )
                                  }}</span
                                >
                              </div>
                              <div class="text-caption text-truncate">
                                <span class="grey--text">RRP: &nbsp;</span>
                                <span
                                  v-if="item.price && item.price.retailPrice"
                                  >{{
                                    (item.price.retailPrice / 100)
                                      | currency(
                                        item.price.currency.symbol,
                                        2,
                                        {
                                          spaceBetweenAmountAndSymbol: true
                                        }
                                      )
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute color="primary">
                              <v-row
                                ><v-btn small @click="editLineSheetItem(item)"
                                  >View Details</v-btn
                                ></v-row
                              >
                              <v-row justify="center" class="mt-3"
                                ><v-btn
                                  small
                                  text
                                  @click="openRemoveDialog(item.id)"
                                  >Remove</v-btn
                                ></v-row
                              >
                            </v-overlay>
                          </v-fade-transition>
                        </v-card>
                      </template>
                    </v-hover>
                  </v-col>
                </draggable>
              </v-col>
            </v-row>
          </draggable>
        </v-col>
        <v-col v-else cols="12">
          <v-row>
            <v-col v-for="item in 6" :key="item" cols="12" md="3" lg="2">
              <v-skeleton-loader type="card"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import draggable from 'vuedraggable'
import LineSheetItemEditDialog from '@/modules/sales/components/core/LineSheetItemEditDialog.vue'
import ActiveInactiveLabel from '../../../components/core/ActiveInactiveLabel.vue'
import LINE_SHEET_CATEGORY_CREATE from '@/graphql/LineSheetCategoryCreate.gql'
import LINE_SHEET_CATEGORY_UPDATE from '@/graphql/LineSheetCategoryUpdate.gql'
import LINE_SHEET_CATEGORY_REMOVE from '@/graphql/LineSheetCategoryRemove.gql'
import LINE_SHEET_ITEM_CREATE from '@/graphql/LineSheetItemCreate.gql'
import LINE_SHEET_ITEM_UPDATE from '@/graphql/LineSheetItemUpdate.gql'
import LINE_SHEET_ITEM_REMOVE from '@/graphql/LineSheetItemRemove.gql'
import PRODUCTS from '@/graphql/Products.gql'

export default {
  name: 'LineSheetProducts',
  components: { draggable, ActiveInactiveLabel, LineSheetItemEditDialog },
  mixins: [Vue2Filters.mixin],
  props: {
    lineSheet: { type: Object, default: () => {} },
    seasons: {
      type: Object,
      default: () => {
        return { seasons: [] }
      }
    },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 },
    currencies: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },
  data: () => ({
    publicPath: process.env.BASE_URL,
    dialog: false,
    search: null,
    addCategoryDialog: false,
    updateCategoryDialog: false,
    removeDialog: false,
    removeCategoryDialog: false,
    removableID: null,
    editMode: false,
    processing: false,
    errorMsg: '',
    selectedSeason: null,
    selectedProducts: [],
    filteredCategories: [],
    // lineItems: [],
    products: { items: [] },
    defaultItem: {},
    editedItem: {},
    editCategory: {}
  }),
  computed: {
    itemCount() {
      return this.lineSheet?.lineItems?.length || 0
    },
    unusedProducts() {
      var vm = this

      var keys = []
      vm.lineSheet?.lineItems?.length > 0
        ? (vm.productKeys = vm._.map(vm.lineSheet.lineItems, function(line) {
            if (
              line.product?.styleState === 'FINAL' &&
              line.product?.productState === 'FINAL' &&
              line.product.id &&
              line.product.id !== ''
            ) {
              return keys.push(line.product.id)
            }
          }))
        : []

      return vm.products?.items?.length > 0
        ? vm._.filter(vm.products.items, function(item) {
            // if (item.styleState === 'FINAL' && item.productState === 'FINAL')
            return keys.indexOf(item.id) === -1
          })
        : []
    },
    lineItems() {
      var vm = this
      var result = null
      if (vm.lineSheet?.lineItems?.length > 0) {
        result = vm._.chain(vm.lineSheet.lineItems)
          .groupBy(item => item.product.articleGroupName)
          .map((value, key) => ({
            articleGroupName: key || 'Undefined',
            items: value
          }))
          .value()
      }
      return result
    },
    categories: {
      get() {
        return this._.filter(
          this.lineSheet.categories,
          item => item.position > -1
        )
      },
      set(categories) {
        categories.forEach((category, idx) => {
          this.editCategory = {
            id: category.id,
            name: category.name,
            position: idx
          }

          this.updateCategory()
        })
      }
    }
  },
  apollo: {
    products: {
      query: PRODUCTS,
      variables() {
        return {
          filters: {
            page: 1,
            pageSize: 1000,
            nameSearch: this.search,
            season: this.selectedSeason
          }
        }
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.selectedSeason
      }
    }
  },
  methods: {
    openAddCategoryDialog() {
      this.editCategory = {
        lineSheetID: this.lineSheet.id,
        name: '',
        position: this.lineSheet.categories.length - 1
      }
      this.addCategoryDialog = true
    },
    openEditCategoryDialog(item) {
      this.editCategory = {
        id: item.id,
        name: item.name,
        position: item.position
      }
      this.updateCategoryDialog = true
    },
    openRemoveCategoryDialog(categoryID) {
      this.removableID = categoryID
      this.removeCategoryDialog = true
    },
    addCategory() {
      this.$apollo
        .mutate({
          mutation: LINE_SHEET_CATEGORY_CREATE,
          variables: {
            input: this.editCategory
          }
        })
        .then(() => {
          this.$emit('refetch-line-sheet')
          this.closeEditCategoryDialog()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Category Added'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    updateCategory() {
      this.$apollo
        .mutate({
          mutation: LINE_SHEET_CATEGORY_UPDATE,
          variables: {
            input: this.editCategory
          }
        })
        .then(() => {
          this.$emit('refetch-line-sheet')
          this.closeEditCategoryDialog()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Category Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    removeCategory() {
      this.$apollo
        .mutate({
          mutation: LINE_SHEET_CATEGORY_REMOVE,
          variables: {
            id: this.removableID
          }
        })
        .then(() => {
          this.$emit('refetch-line-sheet')
          this.closeEditCategoryDialog()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Category Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    closeEditCategoryDialog() {
      this.editCategory = {}
      this.removableID = null
      this.removeCategoryDialog = false
      this.addCategoryDialog = false
      this.updateCategoryDialog = false
    },
    editLineSheetItem(item) {
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },
    close() {
      this.dialog = false
      // this.editedItem = this._.cloneDeep(this.defaultItem)
      this.processing = false
      this.errorMsg = ''
      this.editMode = false
    },
    addAll() {
      var vm = this
      this._.each(this.unusedProducts, function(item) {
        vm.selectedProducts.push(item.id)
      })
    },
    addProducts() {
      var vm = this
      this.errorMsg = ''
      this.processing = true
      var lineSheetID = this.lineSheet.id
      if (vm.selectedProducts?.length > 0) {
        vm.selectedProducts.forEach((item, idx) => {
          var data = {
            productID: item,
            // seasonID: vm.lineSheet.season.id,
            lineSheetID: lineSheetID
          }

          // Save the line item
          vm.$apollo
            .mutate({
              mutation: LINE_SHEET_ITEM_CREATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              if (idx + 1 === vm.selectedProducts.length) {
                vm.$emit('refetch-line-sheet')
                vm.processing = false
                vm.selectedProducts = []

                vm.$store.commit('showSnackbar', {
                  active: true,
                  message: 'Line Sheet Items Added'
                })
              }
            })
            .catch(() => {
              vm.$store.commit('showSnackbar', {
                active: true,
                message: 'Something went wrong.'
              })
              vm.processing = false
            })
        })
      }
    },
    update(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: LINE_SHEET_ITEM_UPDATE,
          variables: {
            input: item
          }
        })
        .then(() => {
          // this.$emit('refetch-line-sheet')
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'LineSheet Line Updated'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    openRemoveDialog(id) {
      this.removableID = id
      this.close()
      this.removeDialog = true
    },
    remove() {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: LINE_SHEET_ITEM_REMOVE,
          variables: {
            id: this.removableID
          }
        })
        .then(() => {
          this.$emit('refetch-line-sheet')
          this.removableID = null
          this.removeDialog = false
          this.processing = false
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Line Sheet Item Removed'
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    // moveCategory(evt) {
    //   if (evt['moved']) {
    //     var categories = this._.filter(
    //       this.lineSheet.categories,
    //       item => item.position > -1
    //     )
    //     categories.forEach((category, idx) => {
    //       this.editCategory = {
    //         id: category.id,
    //         name: category.name,
    //         position: idx
    //       }

    //       this.updateCategory()
    //     })
    //   }

    //   this.editCategory = {}
    // },
    moveItem(id, evt) {
      if (evt['moved'] || evt['added']) {
        // Get all items and update in list
        var category = this._.find(
          this.lineSheet.categories,
          item => item.id === id
        )

        category.items.forEach((item, idx) => {
          this.update({
            id: item.id,
            state: item.state,
            position: idx,
            categoryID: category.id
          })
        })
      }
    }
  }
}
</script>

<style>
.slidein {
  max-width: 600px;
  padding: 2em 3em;
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  background: #ddd;
  height: 100%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-in-out;
}

/* before the element is shown, start off the screen to the right */
.slide-enter,
.slide-leave-active {
  right: -100%;
}

.ghost-item {
  background: rgb(204, 212, 227);
}
</style>
