<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="page-header">
        <v-toolbar class="mb-0 b-radius" flat>
          <v-row>
            <v-col cols="6" lg="3" xl="3">
              <v-select
                v-model="customerID"
                :items="customers.items"
                hide-details
                outlined
                flat
                dense
                clearable
                item-text="companyName"
                item-value="id"
                label="Customer"
                placeholder="-- customer --"
                prepend-inner-icon="mdi-filter-variant"
                autocomplete="off"
              >
              </v-select>
            </v-col>
            <v-col cols="6" lg="3" xl="3">
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
                clearable
                outlined
                flat
                dense
                autocomplete="off"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-spacer></v-spacer>
        </v-toolbar>
      </div>
      <div class="main-page-column pr-4">
        <div class="scroll-container pt-0">
          <v-data-table
            :headers="headers"
            :items="allocations.items"
            :options.sync="options"
            :loading="$apollo.loading"
            :mobile-breakpoint="null"
            :server-items-length="itemCount"
            class="plain-table grey--text text--darken-2"
            :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
            :hide-default-footer="itemCount < options.itemsPerPage + 1"
            :hide-default-header="allocations.itemCount === 0"
            @click:row="navToDetail"
          >
            <template v-slot:item.warehouse="{ item }">
              <div class="text-truncate">{{ item.warehouse.name }}</div>
              <div class="text-truncate grey--text text-caption">
                {{ item.warehouse.provider.name }}
              </div>
            </template>
            <template v-slot:item.deliveryStartDate="{ item }">
              <span>{{ item.deliveryStartDate | moment('DD MMM YY') }} </span>
              <span v-if="item.deliveryStartDate && item.deliveryEndDate"
                >&nbsp;-&nbsp;</span
              >
              <span>{{ item.deliveryEndDate | moment('DD MMM YY') }} </span>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ item.createdAt | moment('DD MMM YY') }}
            </template>
            <template v-slot:no-data>Nothing here yet...</template>
          </v-data-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ALLOCATION_LIST from '@/graphql/Allocations.gql'
import CUSTOMER_LIST from '@/graphql/SalesCustomers.gql'

export default {
  name: 'SalesAllocationList',
  components: {},
  props: {},
  data: () => ({
    filters: false,
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    allocations: { items: [] },
    customers: { items: [] },
    customerID: null,
    search: null
  }),
  apollo: {
    customers: {
      query: CUSTOMER_LIST,
      variables() {
        return {
          filters: {
            page: 1,
            pageSize: 10000
          }
        }
      },
    },
    allocations: {
      query: ALLOCATION_LIST,
      variables() {
        return {
          filters: {
            page: this.options.page,
            pageSize: this.options.itemsPerPage,
            customerID: this.customerID,
            search: this.search
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        this.page = result.data.allocations.page
        this.itemsPerPage = result.data.allocations.pageSize
        this.itemCount = result.data.allocations.total

        // Set page state
        this.$store.commit('sales/SET_ALLOCATIONS_PAGE', this.options.page)
        this.$store.commit(
          'sales/SET_ALLOCATIONS_PAGE_SIZE',
          this.options.itemsPerPage
        )
      }
    }
  },
  computed: {
    salesAuthLevel() {
      return this.$store.getters.authLevel('product_sales_details')
    },
    designAuthLevel() {
      return this.$store.getters.authLevel('product_design_details')
    },
    developmentAuthLevel() {
      return this.$store.getters.authLevel('product_development_details')
    },
    logisticsAuthLevel() {
      return this.$store.getters.authLevel('product_logistics')
    },
    productDeailAccess() {
      if (
        this.salesAuthLevel > 0 ||
        this.designAuthLevel > 0 ||
        this.developmentAuthLevel > 0 ||
        this.logisticsAuthLevel > 0
      ) {
        return true
      } else {
        return false
      }
    },
    headers() {
      return [
        {
          text: 'Order #',
          align: 'left',
          value: 'orderNumber',
          sortable: false
        },
        {
          text: 'Customer',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'customer.storeName'
        },
        {
          text: 'Warehouse',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'warehouse'
        },
        {
          text: 'Ordered Qty',
          align: 'right',
          value: 'orderedQuantity',
          sortable: false,
          filterable: true
        },
        {
          text: 'Unfulfilled Qty',
          align: 'right',
          sortable: false,
          filterable: true,
          value: 'unFulfilledQuantity'
        },
        {
          text: 'Fulfilled Qty',
          align: 'right',
          sortable: false,
          filterable: true,
          value: 'fulfilledQuantity'
        },
        {
          text: 'Delivery Dates',
          align: 'left',
          value: 'deliveryStartDate',
          sortable: false,
          filterable: true
        },
        {
          text: 'Created On',
          value: 'createdAt',
          sortable: false,
          filterable: true
        }
      ]
    },

    authLevel() {
      return this.$store.getters.authLevel('sales_orders')
    }
  },
  watch: {},
  created() {
    var that = this
    var filters = this.$store.state.sales.allocationsPage
    window.onpopstate = function() {
      that.options.page = filters.page
      that.options.itemsPerPage = filters.pageSize
    }
  },
  methods: {
    navToDetail(item) {
      this.$router.push({
        name: 'SalesAllocationDetails',
        params: { allocationID: item.id }
      })
    }
  }
}
</script>

<style></style>
