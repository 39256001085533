<template>
  <div class="page-wrap">
    <div class="page-header">
      <v-toolbar v-if="lineSheet" class="mb-0 b-radius" extended flat>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="py-0">
              <v-row justify="start" align="start">
                <div sm6>
                  <h2 class="mb-0 primary--text">
                    {{ lineSheet.name }}
                  </h2>
                  <div>
                    <span class="subtitle-2">
                      <active-inactive-label
                        :state="lineSheet.state"
                      ></active-inactive-label>
                    </span>
                    <!-- <v-chip
                    :color="stateColor(purchaseOrder.status)"
                    x-small
                    label
                    class="ml-3"
                    >{{ purchaseOrder.status }}</v-chip
                  > -->
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div sm6></div>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <template v-slot:extension>
          <v-tabs
            next-icon="mdi-chevron-right"
            prev-icon="mdi-chevron-left"
            background-color="transparent"
            align-with-title
            center-active
            show-arrows
          >
            <v-tabs-slider color="primary"></v-tabs-slider>

            <v-tab
              :to="{
                name: 'LineSheetDetail',
                params: { lineSheetID: $route.params.lineSheetID }
              }"
              class="text-capitalize"
              exact
              >Details</v-tab
            >
            <v-tab
              class="text-capitalize"
              :to="{
                name: 'LineSheetProducts',
                params: { lineSheetID: $route.params.lineSheetID }
              }"
              >Products</v-tab
            >
            <v-tab
              class="text-capitalize"
              :to="{
                name: 'LineSheetSubscriberList',
                params: { lineSheetID: $route.params.lineSheetID }
              }"
              >Subscribers</v-tab
            >

            <!-- <v-tab
            :to="{
              name: 'ProductionLineSheetPlanning',
              params: { lineSheetID: $route.params.lineSheetID }
            }"
            class="text-capitalize"
            exact
            >Planning</v-tab
          > -->

            <v-tab
              class="text-capitalize"
              :to="{
                name: 'LineSheetSettings',
                params: { lineSheetID: $route.params.lineSheetID }
              }"
              >Settings</v-tab
            >
          </v-tabs>

          <slot name="controls">
            <v-btn
              color="primary"
              small
              rounded
              :loading="generatePdf"
              @click="downloadPdf"
              >Download</v-btn
            >
          </slot>
        </template>
      </v-toolbar>
    </div>

    <div class="main-page-column pt-0">
      <div class="scroll-container">
        <router-view
          :line-sheet="lineSheet"
          :seasons="seasons"
          :current-user="currentUser"
          :auth-level="authLevel"
          :loading="$apollo.queries.lineSheet.loading"
          @refetch-line-sheet="refetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ActiveInactiveLabel from '@/components/core/ActiveInactiveLabel.vue'
import LINE_SHEET from '@/graphql/LineSheet.gql'
import SEASONS from '@/graphql/Seasons.gql'

export default {
  name: 'LineSheetDetailLayout',
  components: { ActiveInactiveLabel },
  data() {
    return {
      generatePdf: false
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('line_sheets')
    }
  },
  apollo: {
    lineSheet: {
      query: LINE_SHEET,
      variables() {
        return {
          id: this.$route.params.lineSheetID
        }
      },
      fetchPolicy: 'no-cache'
    },
    seasons: SEASONS
  },
  methods: {
    refetch() {
      this.$apollo.queries.lineSheet.refetch()
    },
    downloadPdf() {
      var data = this.lineSheet
      this.generatePdf = true
      var timestamp = this.$moment().format('YYYYMMDD')
      fetch(process.env.https://api.erppaper.com/tools/pdf-gen + '/line-sheet', {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/json'
          // 'Access-Control-Allow-Origin': '*'
          // 'Access-Control-Allow-Credentials': true
        }
      }).then(res => {
        return res
          .arrayBuffer()
          .then(res => {
            const blob = new Blob([res], { type: 'application/pdf' })
            // saveAs(blob, 'invoice.pdf')
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            // link.setAttribute('download', 'invoice.pdf')
            link.download = `line_sheet_${data.name}_${timestamp}.pdf`
            link.click()
            URL.revokeObjectURL(link.href)

            this.generatePdf = false
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Error downloading line sheet'
            })
            this.generatePdf = false
          })
      })
      // this.generatePdf = false
      // this.processing = false
    }
  }
}
</script>

<style scoped>
.page-wrap {
  --page-header-height: 112px;
  /* --page-wrap-offset: -20px; */
}
</style>
