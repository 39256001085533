<template>
  <div class="app-layout">
    <div class="page-wrap pr-4">
      <div class="page-header pt-0 px-1">
        <v-toolbar color="transparent" flat class="mb-3 b-radius">
          <agent-edit-dialog
            :dialog="dialog"
            :agent="editedItem"
            :currencies="currencies"
            :edit-mode="editMode"
            :processing="processing"
            :error-msg="errorMsg"
            @close-agent-edit-dialog="close"
            @save-agent-edit-dialog="save"
          ></agent-edit-dialog>
          <v-row no-gutters>
            <v-col>
              <v-row no-gutters>
                <v-btn color="primary" rounded @click="addAgent">
                  add agent
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-toolbar>
      </div>
      <div class="main-page-column pt-0">
        <div class="scroll-container pt-0">
          <v-card flat class="mb-3">
            <v-card-title>
              <v-text-field
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                label="Search..."
                single-line
                hide-details
                clearable
                autocomplete="off"
              ></v-text-field>
            </v-card-title>
            <v-data-table
              v-model="selected"
              :headers="headers"
              :loading="$apollo.loading"
              :items="agents.items"
              :options.sync="options"
              :server-items-length="itemCount"
              :mobile-breakpoint="null"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
              :hide-default-footer="!agents || itemCount < options.itemsPerPage + 1"
              class="plain-table"
              @click:row="navToDetail"
            >
              <template v-slot:item.updatedAt="{ item }">
                {{ item.updatedAt | moment('from') }}
              </template>
              <template v-slot:item.state="{ item }">
                <agent-state-label :state="item.state"></agent-state-label>
              </template>
              <template v-slot:item.visitingAddress="{ item }">
                <div
                  v-if="item.visitingAddress && item.visitingAddress.id != ''"
                  class="text-truncate"
                >
                  <span v-if="item.visitingAddress.line1">{{
                    item.visitingAddress.line1 + ', '
                  }}</span>

                  <span v-if="item.visitingAddress.city">{{
                    item.visitingAddress.city + ', '
                  }}</span>
                  <span v-if="item.visitingAddress.country">{{
                    getFullCountryName(item.visitingAddress.country)
                  }}</span>
                </div>
              </template>
              <template v-slot:no-data>Nothing here yet...</template>
              <!-- <template v-slot:item.controls="{ item }">
                    <v-btn icon small
                      ><v-icon small @click.stop="editItem(item)"
                        >mdi-pencil-outline</v-icon
                      ></v-btn
                    >
                  </template> -->
            </v-data-table>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getName } from 'country-list'
import AGENTS from '@/graphql/Agents.gql'
import AGENT_CREATE from '@/graphql/AgentCreate.gql'
import CURRENCY_LIST from '@/graphql/Currencies.gql'
import AgentEditDialog from '@/modules/sales/components/core/AgentEditDialog.vue'
import AgentStateLabel from '@/modules/sales/components/core/AgentStateLabel.vue'

export default {
  name: 'AgentList',
  components: { AgentEditDialog, AgentStateLabel },
  data: () => ({
    dialog: false,
    editMode: false,
    processing: false,
    errorMsg: '',
    search: '',
    selected: [],
    agents: { items: [], total: 0, page: 1 },
    options: { page: 1, itemsPerPage: 15 },
    itemCount: 0,
    defaultItem: {
      name: '',
      commerceReg: '',
      shippingNumber: '',
      vatId: '',
      status: 'PENDINGCONTRACT',
      website: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: ''
    },
    editedItem: {
      name: '',
      commerceReg: '',
      shippingNumber: '',
      vatId: '',
      status: 'PENDINGCONTRACT',
      website: '',
      facebook: '',
      twitter: '',
      linkedin: '',
      instagram: ''
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Name',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'name'
        },
        {
          text: 'Address',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'visitingAddress'
        },
        {
          text: 'Status',
          value: 'state',
          visible: false,
          sortable: false
        },
        {
          text: 'Last Update',
          align: 'left',
          value: 'updatedAt',
          filterable: true,
          sortable: false
        }
      ]
    },
    authLevel() {
      return this.$store.getters.authLevel('agents')
    }
  },
  apollo: {
    agents: {
      query: AGENTS,
      variables() {
        return {
          filters: {
            search: this.search,
            page: this.options.page,
            pageSize: this.options.itemsPerPage
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200,
      result(result) {
        // console.log('data: ' + result.data.products.page)
        // console.log('key: ' + key)
        this.page = result?.data?.agents.page
        this.itemsPerPage = result?.data?.agents.pageSize
        this.itemCount = result?.data?.agents.total
      }
    },
    currencies: CURRENCY_LIST
  },
  watch: {
    search() {
      // Reset to first page on search
      this.options.page = 1
    }
  },
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    close() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = false
    },
    addAgent() {
      this.edit = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    save(item) {
      this.errorMsg = ''
      this.processing = true

      this.$apollo
        .mutate({
          mutation: AGENT_CREATE,
          variables: {
            input: item
          }
        })
        .then(result => {
          this.close()
          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Agent Added'
          })
          this.$router.push({
            name: 'AgentDetail',
            params: { agentID: result.data.createAgent }
          })
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    },
    navToDetail(item) {
      this.$router.push({
        name: 'AgentDetail',
        params: { agentID: item.id }
      })
    }
  }
}
</script>

<style lang="scss">
// .list-item {
//   // border: 1px solid transparent;
//   &:hover {
//     border-color: var(--v-primary-base);
//   }
// }

// .plain-table .v-input__slot {
//   background: transparent !important;
// }

// .plain-table.theme--light.v-data-table
//   tbody
//   tr:hover:not(.v-data-table__expanded__content) {
//   cursor: pointer !important;
// }
</style>
