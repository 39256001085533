<template>
  <div>
    <commercial-invoice-page
      v-for="(item, idx) in invoiceLines"
      :key="item.id"
      class="mb-9"
      :page="parseInt(idx + 1)"
      :page-count="pageCount"
      :page-quantities="pageQuantities"
      :page-totals="pageTotals"
      :sub-total="subTotal"
      :vat-total="vatTotal"
      :grand-total="grandTotal"
      :items-per-page="itemsPerPage"
      :invoice-lines="invoiceLines[idx]"
      :delivery-note="deliveryNote"
      :currency-symbol="currencySymbol"
    ></commercial-invoice-page>
    <div v-if="footer">
      <commercial-invoice-footer
        :page-count="pageCount"
        :page-quantities="pageQuantities"
        :page-totals="pageTotals"
        :sub-total="subTotal"
        :vat-total="vatTotal"
        :grand-total="grandTotal"
        :items-per-page="itemsPerPage"
        :delivery-note="deliveryNote"
        :currency-symbol="currencySymbol"
      ></commercial-invoice-footer>
    </div>
  </div>
</template>

<script>
import { getName } from 'country-list'
import countrySwitcher from 'country-code-switch'
import CommercialInvoicePage from './CommercialInvoicePage.vue'
import CommercialInvoiceFooter from './CommercialInvoiceFooter.vue'

export default {
  name: 'CommercialInvoice',
  components: { CommercialInvoicePage, CommercialInvoiceFooter },
  props: {
    deliveryNote: {
      type: Object,
      default: () => ({}),
      required: true
    },
    layout: {
      type: String,
      default: 'portrait'
    },
    generatePdf: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    itemsPerPage: 8,
    pageQuantities: {
      0: 0
    },
    pageTotals: {
      0: 0
    },
    currencySymbol: '€',
    subTotal: 0,
    vatPercentage: 0,
    footer: false
  }),
  computed: {
    pageCount() {
      return Math.ceil(this.deliveryNote?.lines?.length / 8)
    },
    invoiceLines() {
      var vm = this
      var lines = {}

      var counter = 1

      var page = 0
      lines[page] = []

      this.deliveryNote?.lines?.forEach((line, idx) => {
        lines[page].push(line)

        if (counter <= 8) {
          vm.pageQuantities[page] += line.quantity
          let price =
            (line.quantity * line.seasonProduct?.prices[0].wholesalePrice) / 100
          vm.pageTotals[page] += price
          vm.subTotal += price
        }

        if (
          idx + 1 === vm.deliveryNote.lines.length &&
          lines[page].length > 4
        ) {
          vm.footer = true
        }

        if (
          idx + 1 !== vm.deliveryNote.lines.length &&
          lines[page].length === vm.itemsPerPage
        ) {
          page++
          lines[page] = []
          vm.pageQuantities[page] = 0
          vm.pageTotals[page] = 0
          counter = 1
        } else {
          counter++
        }
      })

      return lines
    },
    vatTotal() {
      return this.subTotal * this.vatPercentage
    },
    grandTotal() {
      return this.subTotal + this.vatTotal
    }
  },
  watch: {
    generatePdf(val) {
      if (val) {
        this.download()
      }
    }
  },
  methods: {
    getFullCountryName(code) {
      return getName(code)
    },
    getIso3Code(code) {
      return countrySwitcher.ISO2toISO3(code)
    },
    linePrice(qty, cost) {
      return (cost * qty) / 100
    },
    download() {
      var vm = this
      var data = []

      for (var i = 0; i < this.pageCount; i++) {
        let lines = []

        this.invoiceLines[i].forEach(line => {
          let item = {
            barcode: line.barcode,
            name: line.seasonProduct?.name,
            size: line.productSize?.name,
            quantity: line.quantity,
            currency_symbol: vm.currencySymbol,
            amount: (
              line.seasonProduct?.prices[0]?.wholesalePrice / 100
            ).toFixed(2),
            vat: '0 %',
            vat_amount: 0,
            total: vm
              .linePrice(
                line.seasonProduct?.prices[0]?.wholesalePrice,
                line.quantity
              )
              .toFixed(2),
            hs_code: line.seasonProduct.htsCode,
            composition: line.seasonProduct?.textileContent,
            country_of_origin: vm.getIso3Code(line.countryOfOrigin)
          }

          lines.push(item)
        })

        let page = {
          currency_symbol: vm.currencySymbol,
          sub_total: vm.subTotal.toFixed(2),
          vat_total: vm.vatTotal.toFixed(2),
          grand_total: vm.grandTotal.toFixed(2),
          vat_percentage: vm.vatPercentage,
          invoice_number: vm.deliveryNote?.pickTicket?.pickTicketSerial,
          reference: vm.deliveryNote?.salesOrder?.orderNumber,
          po_number: vm.deliveryNote?.salesOrder?.customerPoNumber,
          delivery_terms: [],
          invoice_date: vm
            .$moment(vm.deliveryNote?.createdAt)
            .format('DD MMMM YYYY'),
          customer: {
            number: vm.deliveryNote?.salesOrder?.customer?.promeseID,
            company_name: vm.deliveryNote?.salesOrder?.customer?.companyName,
            eori_number: vm.deliveryNote?.salesOrder?.customer?.eoriNumber,
            dan_number: vm.deliveryNote?.salesOrder?.customer?.danNumber,
            vat_id: vm.deliveryNote?.salesOrder?.customer?.vatId
          },
          address: {
            line1: vm.deliveryNote?.salesOrder?.customer?.billingAddress?.line1,
            line2: vm.deliveryNote?.salesOrder?.customer?.billingAddress?.line2,
            city: vm.deliveryNote?.salesOrder?.customer?.billingAddress?.city,
            post_code:
              vm.deliveryNote?.salesOrder?.customer?.billingAddress?.postCode,
            country:
              vm.deliveryNote?.salesOrder?.customer?.billingAddress?.country
          },
          page_quantity: vm.pageQuantities[i],
          lines: lines,
          show_transport: false,
          transport: '0.00',
          show_footer: true,
          page_qty: vm.pageQuantities[i],
          page_total: vm.pageTotals[i].toFixed(2),
          summary: false

          // invoiceLines: this._.chain(this.invoiceLines)
          //   .rest(this.itemsPerPage)
          //   .first(this.itemsPerPage * i)
          //   .value()
        }

        if (i > 0) {
          page.transport = vm.pageTotals[i - 1].toFixed(2)
        }

        // Show transport
        if (i > 0) {
          page.show_transport = true
        }

        // Show footer
        if (vm.pageCount === i + 1) {
          page.show_footer = false
        }

        if (vm.pageCount === i + 1 && vm.invoiceLines[i].length > 4) {
          data.push(page)
          let summaryPage = {
            currency_symbol: vm.currencySymbol,
            sub_total: vm.subTotal.toFixed(2),
            vat_total: vm.vatTotal.toFixed(2),
            grand_total: vm.grandTotal.toFixed(2),
            vat_percentage: vm.vatPercentage,
            invoice_number: vm.deliveryNote?.pickTicket?.pickTicketSerial,
            reference: vm.deliveryNote?.salesOrder?.orderNumber,
            po_number: vm.deliveryNote?.salesOrder?.customerPoNumber,
            delivery_terms: [],
            invoice_date: vm
              .$moment(vm.deliveryNote?.createdAt)
              .format('DD MMMM YYYY'),
            customer: {
              number: vm.deliveryNote?.salesOrder?.customer?.promeseID,
              company_name: vm.deliveryNote?.salesOrder?.customer?.companyName,
              eori_number: vm.deliveryNote?.salesOrder?.customer?.eoriNumber,
              dan_number: vm.deliveryNote?.salesOrder?.customer?.danNumber,
              vat_id: vm.deliveryNote?.salesOrder?.customer?.vatId
            },
            address: {
              line1:
                vm.deliveryNote?.salesOrder?.customer?.billingAddress?.line1,
              line2:
                vm.deliveryNote?.salesOrder?.customer?.billingAddress?.line2,
              city: vm.deliveryNote?.salesOrder?.customer?.billingAddress?.city,
              post_code:
                vm.deliveryNote?.salesOrder?.customer?.billingAddress?.postCode,
              country:
                vm.deliveryNote?.salesOrder?.customer?.billingAddress?.country
            },
            pageQuantity: vm.pageQuantities[i],
            pageTotal: vm.pageTotals[i],
            summary: true,
            transport: vm.pageTotals[i - 1].toFixed(2)
          }
          data.push(summaryPage)
        } else if (vm.pageCount === i + 1 && vm.invoiceLines[i].length <= 4) {
          page.summary = true
          data.push(page)
        } else {
          data.push(page)
        }
      }

      this.$emit('download-pdf', data)
    }
  }
}
</script>

<style></style>
