<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="main-page-column pt-0 pr-4">
        <div v-if="!pdf" class="scroll-container pt-0">
          <div>
            <v-card
              v-if="deliveryNote && deliveryNote.salesOrder"
              flat
              class="mb-3"
            >
              <v-card-title class="mb-3 grey--text"
                >Order No.
                <span class="primary--text pl-2">
                  {{ deliveryNote.salesOrder.orderNumber }}</span
                ><v-spacer></v-spacer>
                <v-btn color="primary" small rounded @click="pdf = true"
                  >Commercial Invoice</v-btn
                >
              </v-card-title>
              <v-card-text>
                <v-row no-gutters>
                  <v-col cols="12" class="mb-3">
                    <v-chip
                      v-if="deliveryNote.state === 'SCHEDULED'"
                      x-small
                      color="success lighten-3 font-weight-medium"
                      text-color="success"
                      >{{ deliveryNote.state }}</v-chip
                    >
                    <v-chip
                      v-if="deliveryNote.state === 'SENT'"
                      x-small
                      color="info lighten-2 font-weight-medium"
                      text-color="info darken-1"
                      >{{ deliveryNote.state }}</v-chip
                    >
                    <v-chip
                      v-if="deliveryNote.state === 'CLOSED'"
                      x-small
                      color="warning lighten-1 font-weight-medium"
                      text-color="warning darken-3"
                      >{{ deliveryNote.state }}</v-chip
                    >
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label class="grey--text text--lighten-1">Store</label>
                      </v-col>
                      <v-col cols="12">
                        <span
                          class="font-weight-medium grey--text text--darken-2"
                          >{{
                            deliveryNote.salesOrder.customer.storeName
                          }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label class="grey--text text--lighten-1"
                          >Company</label
                        >
                      </v-col>
                      <v-col cols="12">
                        <span
                          class="font-weight-medium grey--text text--darken-2"
                          >{{
                            deliveryNote.salesOrder.customer.companyName
                          }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label class="grey--text text--lighten-1">Agent</label>
                      </v-col>
                      <v-col cols="12">
                        <span
                          v-if="deliveryNote.salesOrder.agent"
                          class="font-weight-medium grey--text text--darken-2"
                          >{{ deliveryNote.salesOrder.agent.name }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label class="grey--text text--lighten-1"
                          >Order Reference / Customer PO</label
                        >
                      </v-col>
                      <v-col cols="12">
                        <span
                          class="font-weight-medium grey--text text--darken-2"
                          >{{ deliveryNote.salesOrder.customerPoNumber }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label class="grey--text text--lighten-1"
                          >Delivery Start</label
                        >
                      </v-col>
                      <v-col cols="12">
                        <span
                          class="font-weight-medium grey--text text--darken-2"
                          >{{
                            deliveryNote.salesOrder.deliveryStartDate
                              | moment('DD MMM YY')
                          }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label class="grey--text text--lighten-1"
                          >Delivery End</label
                        >
                      </v-col>
                      <v-col cols="12">
                        <span
                          class="font-weight-medium grey--text text--darken-2"
                          >{{
                            deliveryNote.salesOrder.deliveryEndDate
                              | moment('DD MMM YY')
                          }}</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label class="grey--text text--lighten-1"
                          >Warehouse</label
                        >
                      </v-col>
                      <v-col cols="12">
                        <div class="text-truncate">
                          <span
                            v-if="deliveryNote.warehouse"
                            class="font-weight-medium grey--text text--darken-2"
                            >{{ deliveryNote.warehouse.name }}</span
                          ><span
                            v-if="deliveryNote.warehouse.provider"
                            class="pl-3"
                            >({{ deliveryNote.warehouse.provider.name }})</span
                          >
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0" cols="12" md="6">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <label class="grey--text text--lighten-1"
                          >Tracking</label
                        >
                      </v-col>
                      <v-col cols="12">
                        <div class="text-truncate">
                          <a :href="deliveryNote.trackAndTraceValue">{{
                            deliveryNote.trackAndTraceValue
                          }}</a>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card flat>
              <v-data-table
                :loading="$apollo.loading"
                :headers="headers"
                :items="orderBy(deliveryNote.lines, 'productSize.id')"
                :items-per-page="-1"
                :mobile-breakpoint="null"
                fixed-header
                hide-default-footer
              >
                <template v-slot:item.createdAt="{ item }">
                  <span v-if="item.createdAt" class="text-no-wrap">{{
                    item.createdAt | moment('from', 'now')
                  }}</span>
                </template>

                <template v-slot:item.state="{ item }">
                  <div>
                    <v-chip
                      v-if="item.state === 'SCHEDULED'"
                      x-small
                      color="success lighten-3 font-weight-medium"
                      text-color="success"
                      >{{ item.state }}</v-chip
                    >
                    <v-chip
                      v-if="item.state === 'SENT'"
                      x-small
                      color="info lighten-2 font-weight-medium"
                      text-color="info darken-1"
                      >{{ item.state }}</v-chip
                    >
                    <v-chip
                      v-if="item.state === 'CLOSED'"
                      x-small
                      color="warning lighten-1 font-weight-medium"
                      text-color="warning darken-3"
                      >{{ item.state }}</v-chip
                    >
                  </div>
                </template>

                <template v-slot:item.deliveryStartDate="{ item }">
                  <span
                    >{{ item.deliveryStartDate | moment('DD MMM YY') }}
                  </span>
                  <span v-if="item.deliveryStartDate && item.deliveryEndDate"
                    >&nbsp;-&nbsp;</span
                  >
                  <span>{{ item.deliveryEndDate | moment('DD MMM YY') }} </span>
                </template>
                <template v-slot:item.seasonProduct.styleNumber="{ item }">
                  <span
                    >{{
                      item.seasonProduct.styleNumber + item.productSize.name
                    }}
                  </span>
                </template>

                <template v-slot:no-data>
                  <message-box
                    icon="mdi-emoticon-neutral-outline"
                    title="No Delivery Notes"
                    :caption="'Try adjusting your search to get some results'"
                  >
                  </message-box>
                </template>
                <template v-slot:loading>
                  <v-skeleton-loader
                    v-for="item in 5"
                    :key="item"
                    type="list-item"
                    class="mx-auto"
                  ></v-skeleton-loader>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </div>

        <div v-if="pdf" class="px-5 mb-3">
          <v-toolbar rounded flat>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              small
              rounded
              :loading="generatePdf"
              @click="htmlToPdf"
              >Download</v-btn
            >
            <v-btn
              color="grey"
              :disabled="generatePdf"
              small
              rounded
              text
              @click="pdf = false"
              >Cancel</v-btn
            >
          </v-toolbar>
        </div>

        <v-row
          v-if="pdf"
          justify="center"
          style="height: 100%; overflow: hidden;"
          class="px-3"
        >
          <v-col
            cols="12"
            style="overflow-x: hidden;overflow-y: auto; height: calc(100% - 75px);"
            class="py-5"
          >
            <div ref="pdfPages">
              <commercial-invoice
                :delivery-note="deliveryNote"
                :generate-pdf="generatePdf"
                @download-pdf="downloadPdf"
              ></commercial-invoice>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue2Filters from 'vue2-filters'
import MessageBox from '@/components/core/MessageBox.vue'
import DELIVERY_NOTE from '@/graphql/DeliveryNote.gql'
import CommercialInvoice from '../components/core/CommercialInvoice.vue'
// import jsPDF from 'jspdf'

export default {
  name: 'DeliveryNoteDetails',
  components: { MessageBox, CommercialInvoice },
  mixins: [Vue2Filters.mixin],
  data: () => ({
    pdf: false,
    generatePdf: false,
    search: null,
    itemCount: 0,
    deliveryNote: {},
    removeDialog: false,
    editMode: false,
    // processing: false,
    errorMsg: '',
    headers: [
      // {
      //   text: '',
      //   align: 'left',
      //   sortable: false,
      //   filterable: true,
      //   value: 'seasonProduct.frontImage'
      // },
      {
        text: 'Product Name',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'seasonProduct.name'
      },
      {
        text: '',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'seasonProduct.seasonColor'
      },
      {
        text: 'Size',
        align: 'left',
        sortable: false,
        filterable: true,
        value: 'productSize.name'
      },
      {
        text: 'Quanitity',
        align: 'right',
        sortable: false,
        filterable: true,
        value: 'quantity'
      },
      {
        text: 'SKU',
        sortable: false,
        filterable: true,
        value: 'seasonProduct.styleNumber'
      },
      {
        text: 'Barcode',
        sortable: false,
        filterable: true,
        value: 'barcode'
      }
    ],
    defaultItem: {
      id: null,
      stockChange: 0
    },
    editedItem: {
      id: null,
      stockChange: 0
    }
  }),
  apollo: {
    deliveryNote: {
      query: DELIVERY_NOTE,
      variables() {
        return {
          id: this.$route.params.deliveryNoteID
        }
      }
    }
  },
  computed: {},
  methods: {
    openRemoveDialog() {
      this.errorMsg = ''
      this.processing = false
      this.removeDialog = true
    },
    htmlToPdf() {
      this.generatePdf = true
    },
    downloadPdf(data) {
      var timestamp = this.$moment().format('YYYYMMDD')
      fetch(process.env.https://api.erppaper.com/tools/pdf-gen + '/commercial-invoice', {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
          Accept: 'application/pdf',
          'Content-Type': 'application/json'
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Credentials': true
        }
      }).then(res => {
        return res
          .arrayBuffer()
          .then(res => {
            const blob = new Blob([res], { type: 'application/pdf' })
            // saveAs(blob, 'invoice.pdf')
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            // link.setAttribute('download', 'invoice.pdf')
            link.download = `invoice_${data[0].invoice_number}_${timestamp}.pdf`
            link.click()
            URL.revokeObjectURL(link.href)

            this.generatePdf = false
          })
          .catch(() => {
            this.$store.commit('showSnackbar', {
              active: true,
              message: 'Error downloading invoice'
            })
            this.generatePdf = false
          })
      })
      // this.generatePdf = false
      // this.processing = false
    }
  }
}
</script>

<style></style>
