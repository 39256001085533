<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
        <!-- <v-spacer> </v-spacer>
        <v-btn icon :disabled="processing" @click="close"
          ><v-icon>mdi-close</v-icon></v-btn
        > -->
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div v-if="lineSheetItem">
        <div class="scroll-container pt-0">
          <v-form
            ref="lineSheetItemForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col class="py-0">
                <v-row>
                  <v-col class="pa-3">
                    <div>
                      <v-row align="stretch" justify="center" class="mx-auto">
                        <v-col cols="12" class="pb-6">
                          <v-img
                            width="200"
                            style="border-radius: 4px"
                            :src="
                              item.product &&
                              item.product.frontImage &&
                              item.product.frontImage !== ''
                                ? item.product.frontImage
                                : '/DAILY_PAPER_LOGO.png'
                            "
                          ></v-img>
                        </v-col>

                        <v-col cols="12">
                          <v-img
                            width="200"
                            style="border-radius: 4px"
                            center
                            :src="
                              item.product &&
                              item.product.rearImage &&
                              item.product.rearImage !== ''
                                ? item.product.rearImage
                                : ''
                            "
                          ></v-img>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                  <v-col class="pa-3 pt-0 text-truncate">
                    <div class="text-caption text-truncate grey--text mt-3">
                      {{ item.product.styleNumber }}
                    </div>
                    <div class="text-caption text-truncate pt-2">
                      {{ item.product.seasonColor }}
                    </div>
                    <div class="text-caption text-truncate py-3">
                      {{ item.product.description }}
                    </div>
                    <div class="text-caption text-truncate mt-3">
                      <span class="grey--text">Wholesale: &nbsp;</span>
                      <span v-if="item.price && item.price.wholesalePrice">{{
                        (item.price.wholesalePrice / 100)
                          | currency(item.price.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}</span>
                    </div>
                    <div class="text-caption text-truncate">
                      <span class="grey--text">RRP: &nbsp;</span>
                      <span v-if="item.price && item.price.retailPrice">{{
                        (item.price.retailPrice / 100)
                          | currency(item.price.currency.symbol, 2, {
                            spaceBetweenAmountAndSymbol: true
                          })
                      }}</span>
                    </div>
                    <div class="mt-6">
                      <v-select
                        v-model="item.state"
                        :items="['ACTIVE', 'INACTIVE']"
                        label="Status"
                        outlined
                        dense
                        hide-details="auto"
                        :rules="requiredSelectRule"
                      >
                      </v-select>
                    </div>
                    <div class="my-6">
                      <v-btn
                        v-if="item"
                        text
                        small
                        color="info"
                        rounded
                        :to="{
                          name: 'SalesProductDetail',
                          params: { productID: item.product.id },
                          query: { season: item.product.season.id }
                        }"
                        >view product</v-btn
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LineSheetItemEditDialog',
  props: {
    lineSheet: {
      type: Object,
      required: true,
      default: () => ({})
    },
    lineSheetItem: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    item: null
  }),
  computed: {
    title() {
      return this.lineSheetItem?.product?.name
    },
    formattedPreBookStartDate() {
      return this.item.preBookStart
        ? this.$moment(this.item.preBookStart).format('LL')
        : ''
    },
    formattedPreBookEndDate() {
      return this.item.preBookEnd
        ? this.$moment(this.item.preBookEnd).format('LL')
        : ''
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.lineSheetItem)
    }
  },
  methods: {
    close() {
      this.$refs.lineSheetItemForm.reset()
      this.$emit('close-line-sheet-item-edit-dialog', false)

      // this.$nextTick(() => {
      // this.$refs.lineSheetForm.resetValidation()
      // })
    },
    save() {
      if (this.$refs.lineSheetItemForm.validate()) {
        var data = {
          id: this.item.id,
          state: this.item.state,
          position: this.item.position,
          categoryID: this.item.categoryID
        }

        this.$emit('save-line-sheet-item-edit-dialog', data)
        // this.$nextTick(() => {
        //   that.$refs.lineSheetItemForm.reset()
        // })
      }
    },
    remove() {
      this.$emit('remove-line-sheet-item-edit-dialog', this.item.id)
      // this.$nextTick(() => {
      //   that.$refs.lineSheetItemForm.reset()
      // })
    }
  }
}
</script>

<style></style>
