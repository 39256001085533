<template>
  <v-dialog
    v-model="dialog"
    width="600"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ title }}</span>
      </v-card-title>
      <div v-if="errorMsg !== ''" class="pa-3 pb-0">
        <v-alert type="error" class="mb-0">
          {{ errorMsg }}
        </v-alert>
      </div>
      <div>
        <div class="scroll-container py-0">
          <v-form
            ref="returnPolicyForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
          >
            <v-row v-if="item" id="scroll-target" justify="center">
              <v-col cols="12" class="py-0">
                <v-row>
                  <v-col cols="12">
                    <v-checkbox
                      v-model="item.allowed"
                      label="Allowed"
                      outlined
                      dense
                      hide-details="auto"
                      autocomplete="off"
                      :rules="requiredRule"
                    >
                    </v-checkbox>
                  </v-col>
                  <v-col v-if="item.allowed" cols="12">
                    <v-currency-field
                      v-model="item.amount"
                      :prefix="defaultCurrency.symbol"
                      hint="Maximum value of goods allowed to be returned"
                      persistent-hint
                      label="Amount"
                      autocomplete="off"
                      outlined
                      :rules="requiredRule"
                      hide-details="auto"
                      dense
                    ></v-currency-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-card-actions class="pa-5">
        <v-spacer></v-spacer>
        <v-btn
          text
          rounded
          color="grey lighten-1"
          :disabled="processing"
          @click="close"
          >Cancel</v-btn
        >
        <v-btn
          color="primary lighten-2"
          rounded
          :loading="processing"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ReturnPolicyEditDialog',
  props: {
    returnPolicy: {
      type: Object,
      required: true,
      default: () => ({})
    },
    currencies: {
      type: Array,
      required: true,
      default: () => []
    },
    editMode: {
      type: Boolean,
      default: false
    },
    dialog: {
      type: Boolean,
      default: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    errorMsg: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: true,
    requiredRule: [
      v => !!v || 'Field is required',
      v => !/^\s+$/.test(v) || 'Field is required'
    ],
    requiredSelectRule: [v => !!v || 'Field is required'],
    item: null
  }),
  computed: {
    title() {
      return this.editMode ? 'Edit Return Policy' : 'New Return Policy'
    },
    defaultCurrency() {
      return this._.find(this.currencies, item => item.defaultCurrency)
    }
  },
  watch: {
    dialog(val) {
      if (val) this.item = this._.cloneDeep(this.returnPolicy)
    }
  },
  methods: {
    close() {
      this.$refs.returnPolicyForm.reset()
      this.$emit('close-return-policy-edit-dialog', false)
    },
    save() {
      if (this.$refs.returnPolicyForm.validate()) {
        var data = {
          id: this.item.id,
          agreementID: this.item.agreementID,
          amount: this.item.amount,
          allowed: this.item.allowed
        }

        if (!this.editMode) {
          delete data.id
        }

        this.$emit('save-return-policy-edit-dialog', data)
      }
    }
  }
}
</script>

<style></style>
