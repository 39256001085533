<template>
  <div class="app-layout">
    <div class="page-wrap">
      <div class="main-page-column pr-4">
        <div class="scroll-container pt-0">
          <v-row>
            <v-dialog v-model="removeDialog" persistent max-width="350">
              <v-card>
                <v-card-title class="headline error--text mb-3"
                  >Remove Product</v-card-title
                >
                <v-card-text>
                  <p>
                    This will remove the item and can't be undone.
                  </p>
                </v-card-text>
                <v-card-actions>
                  <div class="flex-grow-1"></div>
                  <v-btn
                    color="grey darken-1"
                    text
                    @click="removeDialog = false"
                    >Cancel</v-btn
                  >
                  <v-btn color="error" text>Remove</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-col cols="12" class="pt-0">
              <v-sheet flat rounded class="px-6 py-3">
                <v-row v-if="allocation">
                  <v-col>
                    <div>
                      <span
                        class="grey--text text--darken-1 text-subtitle-1 font-weight-normal"
                        >Order #</span
                      >
                      {{ allocation.orderNumber }}
                    </div>
                    <div
                      v-if="allocation.customer"
                      class="grey--text text--darken-1 text-subtitle-1 font-weight-normal"
                    >
                      {{ allocation.customer.storeName }}
                    </div>
                  </v-col>
                  <v-col> </v-col>
                  <v-spacer></v-spacer>
                  <v-col>
                    <v-row justify="end" align="center">
                      <v-col>
                        {{ allocation.orderedQuantity }}

                        <span
                          class="grey--text text--darken-1 text-subtitle-1 font-weight-normal"
                          >Ordered</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row justify="end" align="center">
                      <v-col>
                        {{ allocation.fulfilledQuantity }}
                        <span
                          class="grey--text text--darken-1 text-subtitle-1 font-weight-normal"
                          >Fulfilled</span
                        >
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col>
                    <v-row justify="end">
                      <v-col class="info--text">
                        {{ allocation.unFulfilledQuantity }}
                        <span class="grey--text text-subtitle-1 pr-3"
                          >Unfulfilled
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row v-if="!editMode" justify="end" class="pt-4">
                      <v-btn
                        v-if="allocation.unFulfilledQuantity > 0"
                        color="info"
                        rounded
                        small
                        @click="newPickTicket"
                        >Create Pick Ticket</v-btn
                      >
                    </v-row>
                    <v-row v-else justify="end" class="pt-4">
                      <v-btn
                        color="grey lighten-1"
                        text
                        small
                        rounded
                        class="mr-2"
                        :disabled="processing"
                        @click="editMode = false"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="info"
                        rounded
                        small
                        :loading="processing"
                        :disabled="processing"
                        @click="save"
                        >Save</v-btn
                      >
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="!editMode">
                    <span>Delivery </span>
                    <span class="grey--text text--darken-1 text-subtitle-1">
                      {{ allocation.deliveryStartDate | moment('DD MMM YYYY') }}
                      -
                      {{ allocation.deliveryEndDate | moment('DD MMM YYYY') }}
                    </span>
                  </v-col>
                  <v-col v-else>
                    <v-row>
                      <v-col cols="12" md="4" lg="3">
                        <v-menu
                          v-model="startDate"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="formattedDeliveryStartDate"
                              autocomplete="off"
                              outlined
                              label="Delivery Start"
                              placeholder="Delivery Start"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              dense
                              hide-details="auto"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="deliveryStartDate"
                            no-title
                            hide-details
                            first-day-of-week="1"
                            @input="endDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="4" lg="3">
                        <v-menu
                          v-model="endDate"
                          :close-on-content-click="true"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="formattedDeliveryEndDate"
                              autocomplete="off"
                              outlined
                              label="Delivery End"
                              placeholder="Delivery End"
                              prepend-inner-icon="mdi-calendar-month-outline"
                              dense
                              hide-details="auto"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="deliveryEndDate"
                            no-title
                            hide-details
                            first-day-of-week="1"
                            :min="
                              $moment(deliveryStartDate) > $moment()
                                ? $moment(deliveryStartDate)
                                    .add(1, 'days')
                                    .format('YYYY-MM-DD')
                                : $moment()
                                    .add(2, 'days')
                                    .format('YYYY-MM-DD')
                            "
                            @input="endDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>

            <v-col v-if="allocationDiscrepancy" cols="12" class="py-0">
              <div class="py-3">
                <v-alert
                  type="warning"
                  text
                  outlined
                  border="left"
                  class="mb-0"
                >
                  Insufficient quantities available for full dispatch
                </v-alert>
              </div>
            </v-col>

            <v-col cols="12" class="py-0">
              <div v-if="errorMsg !== ''" class="py-3">
                <v-alert type="error" class="mb-0">
                  {{ errorMsg }}
                </v-alert>
              </div>

              <v-row v-for="line in allocation.lines" :key="line.id">
                <v-col cols="12">
                  <v-card flat class="card-bordered-alt">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="2" lg="1">
                          <v-row>
                            <v-img
                              v-if="line.product.frontImage"
                              :src="line.product.frontImage"
                              max-height="70px"
                              contain
                            ></v-img>
                            <v-img
                              v-else
                              src="/DAILY_PAPER_LOGO.png"
                              max-height="50px"
                              class="mt-6"
                              contain
                            ></v-img>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="10" lg="11">
                          <v-row>
                            <div class="text-body-2 text-truncate pl-3 mb-3">
                              {{ line.product.name }}
                            </div>
                          </v-row>
                          <v-row no-gutters>
                            <v-col
                              ><div class="grey--text text-caption">
                                {{ line.product.styleNumber }}
                              </div>
                              <div class="grey--text text-caption">
                                {{ line.product.seasonColor }}
                              </div></v-col
                            >
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          v-for="item in filteredList(line.items)"
                          :key="item.id"
                          cols="12"
                          sm="3"
                          md="2"
                          lg="1"
                          xl="1"
                        >
                          <v-row no-gutters>
                            <v-col cols="12" class="text-center mb-2">
                              {{ item.sizeName }}
                            </v-col>

                            <v-col cols="12">
                              <v-text-field
                                v-model="item.unFulfilledQuantity"
                                dense
                                outlined
                                label="Unfulfilled"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                v-model="item.availableQuantity"
                                dense
                                outlined
                                label="Available"
                                :readonly="
                                  item.unFulfilledQuantity >
                                    item.availableQuantity
                                "
                                :disabled="
                                  item.unFulfilledQuantity <=
                                    item.availableQuantity
                                "
                                :error="
                                  item.unFulfilledQuantity >
                                  item.availableQuantity
                                    ? true
                                    : false
                                "
                              ></v-text-field>
                            </v-col>
                            <v-col v-show="editMode" cols="12">
                              <v-text-field
                                :value="item.pickingQuantity"
                                type="number"
                                :max="
                                  item.availableQuantity >=
                                  item.unFulfilledQuantity
                                    ? item.unFulfilledQuantity
                                    : item.availableQuantity
                                "
                                min="0"
                                dense
                                outlined
                                :disabled="
                                  item.availableQuantity === 0 ||
                                    item.unFulfilledQuantity === 0 ||
                                    item.unFulfilledQuantity < 0
                                "
                                label="Pick Qty"
                                @blur="setMinMax($event, line, item)"
                                @input="setMinMax($event, line, item)"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="py-0">
                          <v-row>
                            <v-col cols="6" md="4" lg="2">
                              <div>
                                <span class="grey--text text-caption"
                                  >Ordered:</span
                                >
                                <span class="pl-3 font-weight-medium">{{
                                  line.orderedQuantity
                                }}</span>
                              </div></v-col
                            >
                            <v-col cols="6" md="4" lg="2">
                              <div>
                                <span class="grey--text text-caption"
                                  >Unfulfilled:</span
                                >
                                <span class="pl-3 font-weight-medium">{{
                                  line.unFulfilledQuantity
                                }}</span>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MessageBox from '@/components/core/MessageBox.vue'
import ALLOCATION from '@/graphql/Allocation.gql'
import CREATE_PICK_TICKET from '@/graphql/PickTicketCreate.gql'

export default {
  name: 'AllocationDetails',
  components: {},
  props: {
    seasons: {
      type: Object,
      default: () => {
        return { seasons: [] }
      }
    }
  },
  data: () => ({
    dialog: false,
    removeDialog: false,
    loading: false,
    errorMsg: '',
    processing: false,
    search: null,
    editMode: false,
    editedItem: {},
    allocation: { lines: [] },
    deliveryStartDate: null,
    deliveryEndDate: null,
    startDate: null,
    endDate: null
  }),
  apollo: {
    allocation: {
      query: ALLOCATION,
      variables() {
        return {
          id: this.$route.params.allocationID
        }
      }
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser
    },
    authLevel() {
      return this.$store.getters.authLevel('allocations')
    },
    allocationItem() {
      return this._.cloneDeep(this.allocation)
    },
    formattedDeliveryStartDate() {
      return this.deliveryStartDate
        ? this.$moment(this.deliveryStartDate).format('LL')
        : ''
    },
    formattedDeliveryEndDate() {
      return this.deliveryEndDate
        ? this.$moment(this.deliveryEndDate).format('LL')
        : ''
    },
    allocationDiscrepancy() {
      var discrepancy = false

      this.allocation?.lines?.forEach(line => {
        line.items.forEach(item => {
          if (item.unFulfilledQuantity > item.availableQuantity) discrepancy = true
        })
      })

      return discrepancy
    }
  },
  created() {},
  methods: {
    filteredList(list) {
      return this._.chain(list).filter(item => item.unFulfilledQuantity > 0).sortBy('productSizeID').value()
    },
    newPickTicket() {
      this.deliveryStartDate = this.allocation.deliveryStartDate
      this.deliveryEndDate = this.allocation.deliveryEndDate

      this.allocation.lines.forEach(line => {
        line.items.forEach(item => {
          var copyItem = JSON.parse(JSON.stringify(item))

          if (parseInt(item.unFulfilledQuantity) === 0) {
            item.pickingQuantity = 0
          } else if (
            parseInt(item.unFulfilledQuantity) > 0 &&
            parseInt(item.availableQuantity) >= item.unFulfilledQuantity
          ) {
            item.pickingQuantity = copyItem.unFulfilledQuantity
          } else if (parseInt(item.unFulfilledQuantity) < 0) {
            item.pickingQuantity = 0
          } else if (parseInt(item.availableQuantity) > 0) {
            item.pickingQuantity = copyItem.availableQuantity
          } else {
            item.pickingQuantity = 0
          }
        })
      })

      this.editMode = true
    },
    setMinMax(event, line, item) {
      item.pickingQuantity = parseInt(item.pickingQuantity)
      var maxQty =
        parseInt(item.availableQuantity) >= parseInt(item.unFulfilledQuantity)
          ? parseInt(item.unFulfilledQuantity)
          : parseInt(item.availableQuantity)

      var inputVal = event?.target
        ? parseInt(event.target.value)
        : parseInt(event)
      var lineItem = this._.find(this.allocation.lines, l => {
        return l.id === line.id
      })

      var val = this._.find(lineItem.items, it => {
        return it.id === item.id
      })

      if (
        (parseInt(event) && typeof parseInt(event) === 'number') ||
        (parseInt(event?.target?.value) &&
          typeof parseInt(event?.target?.value) === 'number')
      ) {
        if (
          parseInt(item.unFulfilledQuantity) > 0 &&
          parseInt(inputVal) > maxQty
        ) {
          val.pickingQuantity = maxQty.toString()
        } else if (parseInt(item.unFulfilledQuantity) < 0) {
          val.pickingQuantity = 0
        } else if (
          parseInt(inputVal) < 0 &&
          parseInt(inputVal) <= parseInt(item.unFulfilledQuantity)
        ) {
          val.pickingQuantity = 0
        } else if (parseInt(inputVal) < 0) {
          val.pickingQuantity = 0
        } else {
          val.pickingQuantity = inputVal.toString()
        }
      } else if (event?.target) {
        val.pickingQuantity = 0
      }

      this.$forceUpdate()
    },
    save() {
      this.processing = true

      var lines = []

      this.allocation.lines.forEach(line => {
        line.items.forEach(item => {
          if (parseInt(item.pickingQuantity) > 0) {
            var inLine = {
              allocationLineID: line.id,
              allocationLineItemID: item.id,
              salesOrderLineID: line.salesOrderLineID,
              salesOrderLineItemID: item.salesOrderLineItemID,
              productID: line.product.id,
              seasonProductID: line.product.seasonProductID,
              productSizeID: item.productSize.id,
              warehouseID: item.warehouseID,
              barcode: item.barcode,
              quantity: parseInt(item.pickingQuantity)
            }

            lines.push(inLine)
          }
        })
      })

      var pickTicket = {
        salesOrderID: this.allocation.salesOrderID,
        allocationID: this.allocation.id,
        warehouseID: this.allocation.warehouse?.id,
        warehouseProviderID: this.allocation.warehouse?.provider?.id,
        deliveryStartDate: this.deliveryStartDate
          ? this.$moment(this.deliveryStartDate).utc()
          : this.allocation.deliveryStartDate,
        deliveryEndDate: this.deliveryEndDate
          ? this.$moment(this.deliveryEndDate).utc()
          : this.allocation.deliveryEndDate,
        lines: lines
      }

      this.$apollo
        .mutate({
          mutation: CREATE_PICK_TICKET,
          variables: {
            input: pickTicket
          }
        })
        .then(() => {
          this.editMode = false
          this.processing = false

          this.errorMsg = ''

          this.$store.commit('showSnackbar', {
            active: true,
            message: 'Pick Ticket Created'
          })

          this.$router.back()
        })
        .catch(() => {
          this.errorMsg = 'Something went wrong.'
          this.processing = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-bordered-alt {
  border: 2px solid rgb(229, 237, 244);
}
</style>
