var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"subtitle-1"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":"Search by product name...","single-line":"","hide-details":"","clearable":"","outlined":"","dense":"","autocomplete":"off"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-select',{attrs:{"background-color":'#fff',"items":_vm.seasons.seasons,"prepend-inner-icon":'mdi-filter-variant',"placeholder":"Season","hide-details":"","clearable":"","outlined":"","dense":"","autocomplete":"off","item-value":"id","item-text":"longName","disabled":_vm.$apollo.queries.seasons.loading ||
                  !_vm.seasons ||
                  _vm.seasons.seasons.length === 0},model:{value:(_vm.seasonID),callback:function ($$v) {_vm.seasonID=$$v},expression:"seasonID"}})],1)],1),_c('v-spacer'),(
            !_vm.$apollo.loading &&
              _vm.authLevel &&
              _vm.authLevel === 2 &&
              (_vm.productCustomers || _vm.productCustomers.length > 0) &&
              (!_vm.selected || _vm.selected.length === 0)
          )?_c('v-btn',{staticClass:"mr-3",attrs:{"rounded":"","small":"","color":"info","disabled":_vm.processing},on:{"click":function($event){return _vm.updateAll(true)}}},[_vm._v("Show All")]):_vm._e(),(
            !_vm.$apollo.loading &&
              _vm.authLevel &&
              _vm.authLevel === 2 &&
              (_vm.productCustomers || _vm.productCustomers.length > 0) &&
              (!_vm.selected || _vm.selected.length === 0)
          )?_c('v-btn',{attrs:{"rounded":"","small":"","color":"info","disabled":_vm.processing},on:{"click":function($event){return _vm.updateAll(false)}}},[_vm._v("Hide All")]):_vm._e(),(
            !_vm.$apollo.loading &&
              _vm.authLevel &&
              _vm.authLevel === 2 &&
              _vm.selected &&
              _vm.selected.length > 0
          )?_c('v-btn',{staticClass:"mr-3",attrs:{"rounded":"","small":"","color":"info","disabled":_vm.processing},on:{"click":function($event){return _vm.updateBulk(true)}}},[_vm._v("Show")]):_vm._e(),(
            !_vm.$apollo.loading &&
              _vm.authLevel &&
              _vm.authLevel === 2 &&
              _vm.selected &&
              _vm.selected.length > 0
          )?_c('v-btn',{attrs:{"rounded":"","small":"","color":"info","disabled":_vm.processing},on:{"click":function($event){return _vm.updateBulk(false)}}},[_vm._v("Hide")]):_vm._e(),(
            !_vm.$apollo.loading &&
              _vm.authLevel &&
              _vm.authLevel === 2 &&
              _vm.productCustomers
          )?_c('v-btn',{staticClass:"ml-2",attrs:{"rounded":"","small":"","color":"primary","loading":_vm.processing},on:{"click":_vm.populate}},[_vm._v("Repopulate")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.productCustomers.items,"search":_vm.search,"options":_vm.options,"footer-props":{ 'items-per-page-options': [15, 30, 50, 100] },"server-items-length":_vm.itemCount,"sort-by":"customer.storeName","fixed-header":"","show-select":"","hide-default-header":!_vm.productCustomers || _vm.productCustomers.length === 0},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.product",fn:function(data){return [_c('div',{staticClass:"text-truncate font-weight-normal"},[_c('div',{staticClass:"pr-3 mr-3",staticStyle:{"float":"left"}},[_c('v-img',{staticStyle:{"border-radius":"4px"},attrs:{"width":"40","max-height":"40","src":data.item.product.frontImage &&
                  data.item.product.frontImage !== ''
                    ? data.item.frontImage
                    : '/DAILY_PAPER_LOGO.png',"lazy-src":"/DAILY_PAPER_LOGO.png"}})],1),_c('div',[_c('span',[_vm._v(_vm._s(data.item.product.name))])])])]}},{key:"item.customer.companyName",fn:function(ref){
                    var item = ref.item;
return [(
              item &&
                item.customer.companyName &&
                item.customer.storeName &&
                item.customer.storeName != item.customer.companyName
            )?_c('div',[_vm._v(" "+_vm._s(item.customer.companyName)+" ")]):_vm._e()]}},{key:"item.active",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"color":"info"},on:{"click":function($event){return _vm.update(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"loading",fn:function(){return _vm._l((5),function(item){return _c('v-skeleton-loader',{key:item,staticClass:"mx-auto",attrs:{"type":"list-item"}})})},proxy:true}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }