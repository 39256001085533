<template>
  <v-row>
    <v-col cols="12" class="page-header pt-0 px-1" style="height: 75px;">
      <v-toolbar color="transparent" flat class="mb-3 b-radius">
        <line-sheet-subscriber-edit-dialog
          :dialog="dialog"
          :subscriber="editedItem"
          :line-sheet="lineSheet"
          :customers="customers"
          :edit-mode="editMode"
          :multiple="multiple"
          :processing="processing"
          :error-msg="errorMsg"
          @close-subscriber-edit-dialog="close"
          @save-subscriber-edit-dialog="save"
        ></line-sheet-subscriber-edit-dialog>
        <v-dialog
          v-model="removeDialog"
          :fullscreen="$vuetify.breakpoint.smAndDown"
          persistent
          max-width="450"
        >
          <v-card>
            <v-card-title class="headline error--text mb-3"
              >Remove Customer Subscription</v-card-title
            >
            <v-card-text>
              <p>
                This will remove the line sheet subscription for this customer.
              </p>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1"></div>
              <v-btn color="grey darken-1" text @click="close">Cancel</v-btn>
              <v-btn
                color="error"
                text
                :loading="processing"
                @click="removeSubscription"
                >Remove</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-row no-gutters>
          <v-col>
            <v-row no-gutters>
              <v-btn color="primary" rounded @click="addCustomer">
                add customer
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                v-if="selected && selected.length > 0"
                rounded
                color="info"
                small
                @click="editMultiple"
                >edit</v-btn
              >
              <v-btn
                v-if="selected && selected.length > 0"
                rounded
                color="grey"
                class="ml-3"
                small
                text
                @click="openRemoveMultipleDialog"
                >remove</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-toolbar>
    </v-col>
    <v-col cols="12" class="main-page-column pt-0 px-0">
      <div class="scroll-container pt-0 px-3">
        <v-card flat class="mb-3">
          <!-- <v-card-title>
            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search..."
              single-line
              hide-details
              clearable
              autocomplete="off"
              :disabled="
                !$apollo.loading &&
                  (!lineSheetSubscriberList ||
                    lineSheetSubscriberList.length === 0)
              "
            ></v-text-field>
          </v-card-title> -->
          <v-data-table
            v-model="selected"
            selectable-key="id"
            show-select
            :headers="headers"
            :loading="$apollo.loading"
            :items="lineSheetSubscriberList.items"
            :items-per-page="15"
            :mobile-breakpoint="null"
            :footer-props="{'items-per-page-options':[15, 30, 50, 100]}"
            :hide-default-header="!lineSheetSubscriberList || itemCount === 0"
            @page-count="pageCount = $event"
          >
            <template v-slot:item.updatedAt="{ item }">
              {{ item.updatedAt | moment('from') }}
            </template>
            <!-- <template v-slot:item.companyName="{ item }">
                <div class="text-truncate">{{ item.companyName }}</div>
              </template> -->
            <template v-slot:item.customer="{ item }">
              <div class="py-2">
                <div
                  class="text-truncate text-subtitle-1 font-weight-regular info--text font-weight-medium"
                >
                  {{ item.customer.storeName }}
                </div>
                <div
                  v-if="
                    item &&
                      item.customer.companyName &&
                      item.customer.storeName &&
                      item.customer.storeName != item.customer.companyName
                  "
                  class="text-truncate text-caption font-weight-light"
                >
                  {{ item.customer.companyName }}
                </div>
              </div>
            </template>
            <!-- <template v-slot:item.agent="{ item }">
              <div v-if="item.customer.agent" class="text-truncate">
                {{ item.customer.agent.name }}
              </div>
            </template> -->
            <template v-slot:item.visible="{ item }">
              <div v-if="item.visible" class="text-truncate">
                <v-icon class="info--text">mdi-check</v-icon>
              </div>
            </template>
            <template v-slot:item.prebook="{ item }">
              <div v-if="item.prebook" class="text-truncate">
                <v-icon class="info--text">mdi-check</v-icon>
              </div>
            </template>
            <template v-slot:item.controls="{ item }">
              <v-btn
                text
                rounded
                small
                color="info"
                class="text-lowercase"
                :disabled="multiple"
                @click="editSubscription(item)"
                >edit</v-btn
              >
              <v-btn
                text
                rounded
                small
                color="grey"
                class="text-lowercase"
                :disabled="multiple"
                @click="openRemoveDialog(item.id)"
                >remove</v-btn
              >
            </template>
            <template v-slot:no-data>Nothing here yet...</template>
          </v-data-table>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CUSTOMER_LIST from '@/graphql/SalesCustomers.gql'
import LINE_SHEET_SUBSCRIBER_LIST from '@/graphql/LineSheetSubscriberList.gql'
import LINE_SHEET_SUBSCRIBER_CREATE from '@/graphql/LineSheetSubscriberCreate.gql'
import LINE_SHEET_SUBSCRIBER_UPDATE from '@/graphql/LineSheetSubscriberUpdate.gql'
import LINE_SHEET_SUBSCRIBER_REMOVE from '@/graphql/LineSheetSubscriberRemove.gql'
import LineSheetSubscriberEditDialog from '@/modules/sales/components/core/LineSheetSubscriberEditDialog.vue'

export default {
  name: 'LineSheetSubscriberList',
  components: { LineSheetSubscriberEditDialog },
  props: {
    lineSheet: { type: Object, default: () => {} },
    currentUser: { type: Object, default: () => {} },
    authLevel: { type: Number, default: () => 0 }
  },
  data: () => ({
    dialog: false,
    removeDialog: false,
    removeableID: null,
    editMode: false,
    multiple: false,
    processing: false,
    errorMsg: '',
    search: '',
    agentSearch: '',
    selected: [],
    itemCount: 0,
    lineSheetSubscriberList: [],
    customers: { items: [] },
    defaultItem: {
      id: null,
      customerID: null,
      visible: false,
      prebook: false
    },
    editedItem: {
      id: null,
      customerID: null,
      visible: false,
      prebook: false
    }
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Customer',
          align: 'left',
          sortable: false,
          filterable: true,
          value: 'customer'
        },
        // {
        //   text: 'Agent',
        //   align: 'left',
        //   sortable: false,
        //   filterable: true,
        //   value: 'agent'
        // },
        {
          text: 'Visible',
          align: 'center',
          sortable: false,
          filterable: true,
          value: 'visible'
        },
        // {
        //   text: 'Prebook',
        //   align: 'center',
        //   value: 'prebook',
        //   visible: false,
        //   sortable: false
        // },
        {
          text: 'Last Update',
          align: 'left',
          value: 'updatedAt',
          filterable: false,
          sortable: false
        },
        {
          text: '',
          align: 'right',
          value: 'controls',
          filterable: false,
          sortable: false
        }
      ]
    }
  },
  apollo: {
    lineSheetSubscriberList: {
      query: LINE_SHEET_SUBSCRIBER_LIST,
      variables() {
        return {
          filters: {
            lineSheetID: this.$route.params.lineSheetID,
            page: 1,
            pageSize: 100000
          }
        }
      },
      // fetchPolicy: 'no-cache',
      // debounce: 200,
      result(result) {
        // console.log('data: ' + result.data.products.page)
        // console.log('key: ' + key)
        this.page = result?.data?.lineSheetSubscriberList.page
        this.itemsPerPage = result?.data?.lineSheetSubscriberList.pageSize
        this.itemCount = result?.data?.lineSheetSubscriberList.total
      }
    },
    customers: {
      query: CUSTOMER_LIST,
      variables() {
        return {
          filters: {
            page: 1,
            pageSize: 10000
          }
        }
      },
      fetchPolicy: 'no-cache',
      debounce: 200
    }
  },
  methods: {
    close() {
      this.editMode = false
      this.multiple = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.removeableID = null
      this.dialog = false
      this.removeDialog = false
      this.processing = false
    },
    addCustomer() {
      this.editMode = false
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    editSubscription(item) {
      this.editMode = true
      this.editedItem = this._.cloneDeep(item)
      this.dialog = true
    },
    editMultiple() {
      this.editMode = true
      this.multiple = true
      this.editedItem = this._.cloneDeep(this.defaultItem)
      this.dialog = true
    },
    save(item) {
      var vm = this
      this.errorMsg = ''
      this.processing = true

      var lineSheetID = item.lineSheetID
      var visible = item.visible
      var prebook = item.prebook

      var editData = []

      if (!this.multiple) {
        editData.push(item)
      } else {
        vm.selected.forEach(element => {
          let item = {
            id: element.id,
            customerID: element.customer.id,
            lineSheetID: element.lineSheet.id,
            visible: visible,
            prebook: prebook
          }

          editData.push(item)
        })
      }

      if (this.editMode) {
        editData.forEach((subItem, idx) => {
          this.$apollo // edit
            .mutate({
              mutation: LINE_SHEET_SUBSCRIBER_UPDATE,
              variables: {
                input: subItem
              }
            })
            .then(() => {
              if (idx + 1 === editData.length) {
                this.$apollo.queries.lineSheetSubscriberList.refetch()
                this.close()
                this.selected = []
                this.$store.commit('showSnackbar', {
                  active: true,
                  message: 'Subscriber Updated'
                })
              }
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        })
      } else {
        // create new

        item.customerID.forEach((element, idx) => {
          var data = {
            customerID: element,
            lineSheetID: lineSheetID,
            visible: visible,
            prebook: prebook
          }

          this.$apollo
            .mutate({
              mutation: LINE_SHEET_SUBSCRIBER_CREATE,
              variables: {
                input: data
              }
            })
            .then(() => {
              if (idx + 1 === item.customerID.length) {
                this.$apollo.queries.lineSheetSubscriberList.refetch()
                this.close()
                this.selected = []
                this.$store.commit('showSnackbar', {
                  active: true,
                  message: 'Subscriber Added'
                })
              }
            })
            .catch(() => {
              this.errorMsg = 'Something went wrong.'
              this.processing = false
            })
        })
      }
    },
    openRemoveDialog(id) {
      this.removeableID = id
      this.removeDialog = true
    },
    openRemoveMultipleDialog() {
      this.multiple = true
      this.removeDialog = true
    },
    removeSubscription() {
      var vm = this

      var ids = []

      if (this.multiple) {
        vm.selected.forEach(item => {
          ids.push(item.id)
        })
      } else {
        ids.push(vm.removeableID)
      }

      ids.forEach((element, idx) => {
        // create new
        this.$apollo
          .mutate({
            mutation: LINE_SHEET_SUBSCRIBER_REMOVE,
            variables: {
              id: element
            }
          })
          .then(() => {
            if (idx + 1 === ids.length) {
              this.$apollo.queries.lineSheetSubscriberList.refetch()
              this.close()
              this.selected = []
              this.$store.commit('showSnackbar', {
                active: true,
                message: 'Subscriber Removed'
              })
            }
          })
          .catch(() => {
            this.errorMsg = 'Something went wrong.'
            this.processing = false
          })
      })
    }
  }
}
</script>

<style lang="scss">
// .list-item {
//   // border: 1px solid transparent;
//   &:hover {
//     border-color: var(--v-primary-base);
//   }
// }

// .plain-table .v-input__slot {
//   background: transparent !important;
// }

// .plain-table.theme--light.v-data-table
//   tbody
//   tr:hover:not(.v-data-table__expanded__content) {
//   cursor: pointer !important;
// }
</style>
